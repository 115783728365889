import moment from 'moment';
import 'moment-timezone';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const TimerAndDate = () => {
  const intervalIdRef = useRef(null);
  const [seconds, setSeconds] = useState()
  const [isRefreshApi, setIsRefreshApi] = useState(true)
  const formattedTime = useMemo(() => {
    if (seconds) {
      const timeFormat = seconds.format('h:mm:ss A ');
      const dateFormat = seconds.format('ddd, MMM D, YYYY');
      return { currentTime: timeFormat, currentDate: dateFormat }
    }
  }, [seconds]);

  const isRefreshApiValue = useMemo(() => isRefreshApi, [isRefreshApi])

  const handleVisibilityChange = useCallback(
    () => {
      if (document.visibilityState === "visible") {
        setIsRefreshApi(true)
      } else if (document.hidden) {
        setIsRefreshApi(false)
      }
    },
    [],
  )
  document.addEventListener('visibilitychange', handleVisibilityChange);

  useEffect(() => {
    let intervalId
    const updateCurrentISTDateTimes = async () => {
      // fetch('https://worldtimeapi.org/api/timezone/Asia/Kolkata')
      //   .then((res) => {
      //     return res.json();
      //   })
      //   .then((data) => {
      //     let dateTime = data.datetime.substring(0, data.datetime.lastIndexOf('+'))
      //     dateTime = moment.utc(dateTime).utcOffset("00:00").format('YYYYMMDD HHmmss ZZ')
      //     let currentTimestamp
      //     if (data?.datetime) {
      //       intervalId = setInterval(() => {
      //         let inputDatetime = currentTimestamp ? moment.utc(currentTimestamp, 'YYYYMMDD HHmmss') : moment.utc(dateTime, 'YYYYMMDD HHmmss');
      //         currentTimestamp = inputDatetime.add(1, 'second');
      //         setSeconds(inputDatetime)
      //       }, 1000);
      //       return () => clearInterval(intervalId);
      //     }
      //   });
      try {
        const response = await fetch('https://penguinexch.com:2023/api/v1/common/TimeInfo');
        const res = await response.json()
        if (res?.datetime) {
          let dateTime = res.datetime.substring(0, res.datetime.lastIndexOf('+'))
          dateTime = moment.utc(dateTime).utcOffset("00:00").format('YYYYMMDD HHmmss ZZ')
          let currentTimestamp
          intervalIdRef.current = setInterval(() => {
            let inputDatetime = currentTimestamp ? moment.utc(currentTimestamp, 'YYYYMMDD HHmmss') : moment.utc(dateTime, 'YYYYMMDD HHmmss');
            currentTimestamp = inputDatetime.add(1, 'second');
            setSeconds(inputDatetime)
          }, 1000);
          // clearInterval(intervalIdRef.current)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (isRefreshApiValue) {
      updateCurrentISTDateTimes()
    } else {
      if (isRefreshApiValue && intervalId) {
        clearInterval(intervalIdRef.current)
      }
    }
    return () => {
      // if (intervalId) {
        clearInterval(intervalIdRef.current)
      // }
    };
  }, [isRefreshApiValue]);

  return (
    <li className='timing_wrap  d-xl-block'>
      {formattedTime?.currentTime && <h4 className='text-white m-0'>{formattedTime.currentTime} {formattedTime.currentTime && `IST`}</h4>}
      {formattedTime?.currentDate && <h5 className='text-white m-0'>{formattedTime.currentDate}</h5>}
    </li>
  )
}