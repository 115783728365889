const configAppConst = {
  APIBaseURL: process.env.REACT_APP_API_URL,
  allTablePageSize: 10,
  appTabs: [
    "home",
    "marketwatch",
    "order-book",
    "trade-book",
    "position-report",
    "change-password",
    "settings",
    "add",
    "account",
    "brokerage",
  ],
  home: "Home",
  marketWatch: "MarketWatch",
  orderBook: "Order Book",
  tradeBook: "Trade Book",
  positionReport: "Position Report",
  bannedBlockedScrips: "Banned/Blocked Scrips",
  maxQuantityDetails: "Max Quantity Details",
  master: "Master",
  account: "Account",
  brokerage: "Brokerage",
  partnership: "Partnership",
  valan: "Valan",
  transaction: "Transaction",
  tradeEntry: "Trade Entry",
  tradeUtility: "Trade Utility",
  tradeInspection: "Trade Inspection",
  billCreation: "Bill Creation",
  uploadBhavCopy: "BhavCopy",
  bhavCopyData: "Bhav Copy Data",
  finance: "Finance",
  inspect: "Inspect",
  cashEntry: "Cash Entry",
  bankEntry: "Bank Entry",
  jVEntry: "JV Entry",
  reports: "Reports",
  tradeReport: "Trade Report",
  ledgerReport: "Ledger Report",
  trialBalance: "Trial Balance",
  orderTradeEditDeleteLog: "Order/Trade Edit/Delete Log",
  orderTradeRejectionLog: "Order/Trade Rejection Log",
  sameIp: "Same Ip",
  TopGainersAndLosers: "Top Gainers And Losers",
  shortTrading: "Short Trading",
  topGainers: "Top Gainers",
  topLosers: "Top Losers",
  settings: "Settings",
  quantitySetting: "Quantity Setting",
  scriptManagement: "Script Management",
  callPutsetting: "CE/PE Setting",
  bannedScripts: "Banned Scripts",
  blockScript: "Block Scripts",
  transferSetting: "Transfer Setting",
  expiryValidation: "Expiry Validation",
  scriptMaxValue: "Script Max Value",
  clientSegmentSetting: "Client Segment Setting",
  clientOrderLimit: "Client Order Limit",
  scriptMinQtySetting: "Script Max Qty Setting",
  optionStrikeValidation: "Option Strike Validation",
  changePassword: "Change Password",
  authorityUsers: "Authority Users",
  systemUsers: "System Users",
  clientMap: "Client Map",
  systemUserMap: "System User Map",
  notificationRights: "Notification Rights",
  authorityRights: "Authority Rights",
  authorityLimits: "Authority Limits",
  rules: "Rules",
  privacyPolicy: "Privacy Policy",
  cPanel: "CPanel",
  logout: "Logout",
  pendingOrders: "Pending Orders",
  completedOrders: "Completed Orders",
  findTrade: "Find Trade",
  export: "Export",
  getPosition: "Get Position",
  closePosition: "Close Position",
  addAccount: "Add Account",
  totalRecords: "Total Records",
  add: "Add",
  delete: "Delete",
  update: "Update",
  filter: "Filter",
  save: "Save",
  backUp: "Back Up",
  edit: "Edit",
  refresh: "Refresh",
  close: "Close",
  cancel: "Cancel",
  search: "Search",
  //admin home
  dashboard: "Dashboard",
  summary: "Summary",
  summaryBillWithTrialBalance:"Summary Bill With Trial Balance",
  m2MAlert: "M2M Alert",
  users: "Users",
  rejectionLog: "Rejection Log",
  runningOrdersTitle: "Running Orders",
  tradesTitle: "Trades",
  executedOrdersTitle: "Executed Orders",

  //footer
  copyRight: "CZ Team",

  //login
  loginAccountTitle: "Login",
  //error messages
  passwordError: "*Password is a required",
  segmentError: "*Segment is a required",
  accountTypeError: "*Account Type is a required",
  scriptError: "*Script is a required",
  valanNameError: "*Valan Name is a required",
  expirtBeforError: "*Befor Expiry is a required",
  newExpiryError: "*New Expiry is a required",

  accountNameError: "*Account Name is a required",
  accountCodeError: "*Account Code is a required",
  expiryDateError: "*Expiry Date is a required",
  joinDateError: "*Join Date is a required",
};
export const userPartnerEnum = ["User", "Partner", "Sub Broker"];
export default configAppConst;
export const SuperAdminOptionList = [
  {
    value: 2,
    label: "Admin",
  },
  {
    value: 3,
    label: "Super Master",
  },
  {
    value: 4,
    label: "Master",
  },
  /* {
      value: 5,
      label: "Broker",
    }, */
  {
    value: 6,
    label: "Sub Broker",
  },
  {
    value: 7,
    label: "Partner",
  },
  {
    value: 8,
    label: "User",
  },
  {
    value: 9,
    label: "Cashbook",
  },
];
export const AdminOptionList = [
  {
    value: 3,
    label: "Super Master",
  },
  {
    value: 6,
    label: "Sub Broker",
  },
  {
    value: 7,
    label: "Partner",
  },
  {
    value: 9,
    label: "Cashbook",
  },
];
export const SuperMasterOptionList = [
  {
    value: 4,
    label: "Master",
  },
  {
    value: 6,
    label: "Sub Broker",
  },
  {
    value: 7,
    label: "Partner",
  },
  {
    value: 9,
    label: "Cashbook",
  },
];
export const MasterOptionList = [
  {
    value: 6,
    label: "Sub Broker",
  },
  {
    value: 7,
    label: "Partner",
  },
  {
    value: 8,
    label: "User",
  },
  {
    value: 10,
    label: "System User",
  },
];
