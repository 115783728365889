import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { showMessage } from "./common.slice";
import { generatePDF, exportToExcel } from "utils/reportGenerator";
import { getSessionData } from "utils/common";

const initialState = {
  sortOrderBookArr: [],
  orderBookList: [],
  orderBookPendingLoading: false,
  currentPageOrderBook: 1,
  searchTextOrderBook: "",
  pageSizeOrderBook: 10,
  totalRecordOrderBook: 0,
  startApiCallOrderBook: true,
  selectedSegmentInOrderBook: "",
  orderBookDeleteId: "",
  deleteOrderBookObj: {},
  isOrderBookUpdated: false,
  singleOrderBookEditData: {},
  orderEditData: {},
  isCompletedOrderType: "pending",
  isOrderEditConfirmDialog: false,
  orderBookFilterValue: {},
  isOrderBookGet: false,
  selectedOrderDetail: {},
};
const calumnData = [
  {
    content: "Buy",
    colSpan: 5,
    styles: { halign: "center", fillColor: [22, 160, 133] },
  },
  {
    content: "Sell",
    colSpan: 5,
    styles: { halign: "center", fillColor: [22, 160, 133] },
  },
];
const orderBookTableColumn = [
  { dataKey: "clientName", title: "Client Name" },
  { dataKey: "rate", title: "rate" },
  { dataKey: "orderType", title: "Order Type" },
  { dataKey: "orderTypeName", title: "Order TypeName" },
  { dataKey: "lot", title: "Lot" },
  { dataKey: "qty", title: "Qty" },
  { dataKey: "rate", title: "Rate" },
  { dataKey: "script", title: "Script" },
  { dataKey: "userIp", title: "User Ip" },
  { dataKey: "addTime", title: "Add Time" },
  { dataKey: "tradeDateTime", title: "Trade Time" },
];

export const addEditOrderBook = createAsyncThunk(
  "orderbook/add_edit",
  (data, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      let stateData = getState();
      if (stateData.marketWatch.liveDataItem) {
        let liveData = stateData.marketWatch.liveDataItem;
        let { userId } = getSessionData();
        data.createdBy = userId;
        data.userId = userId;
        data.id = 0;
        data.expiryDate = data.expiry.label;
        data.segmentId = data.marketType.value;
        data.symbol = data.symbol.value;
        data.script =
          data.symbol + " " + moment(data.expiry.label).format("DDMMMYYYY");

        // data.script = "GOLD22FEBFUT";

        // data.callPut =
        //   data.callPut && data.callPut.value == "call" ? 1 : 2;
        // data.strike = data.strike && Number(data.strike.value);

        data.averageTradePrice = !liveData.averageTradePrice
          ? 0
          : liveData.averageTradePrice;
        data.buyPrice = !liveData.buyPrice ? 0 : liveData.buyPrice;
        data.buyQty = !liveData.buyQty ? 0 : liveData.buyQty;
        data.close = !liveData.close ? 0 : liveData.close;
        data.displayName =
          liveData.dispname &&
            liveData.dispname !== null &&
            liveData.dispname !== undefined
            ? liveData.dispname
            : "";
        data.exchange = !liveData.exchange ? 0 : liveData.exchange;
        data.expiry = !liveData.expiry ? 0 : liveData.expiry;
        data.high = !liveData.high ? 0 : liveData.high;
        data.instrumentIdentifier = !liveData.instrumentIdentifier
          ? 0
          : liveData.instrumentIdentifier;
        data.lastTradedPrice = !liveData.lastTradedPrice
          ? 0
          : liveData.lastTradedPrice;
        data.lastTradedQty = !liveData.lastTradedQty
          ? 0
          : liveData.lastTradedQty;
        data.lastTradedTime = !liveData.lastTradedTime
          ? 0
          : liveData.lastTradedTime;
        data.lotsize = !liveData.lotsize ? 0 : liveData.lotsize;
        data.low = !liveData.low ? 0 : liveData.low;
        data.multiplier = !liveData.multiplier ? 0 : liveData.multiplier;
        data.open = !liveData.open ? 0 : liveData.open;
        data.openInterest = !liveData.openInterest ? 0 : liveData.openInterest;
        data.preOpen = !liveData.preOpen ? 0 : liveData.preOpen;
        data.sellPrice = !liveData.sellPrice ? 0 : liveData.sellPrice;
        data.sellQty = !liveData.sellQty ? 0 : liveData.sellQty;
        data.serverTime = !liveData.serverTime ? 0 : liveData.serverTime;
        data.totalQtyTraded = !liveData.totalQtyTraded
          ? 0
          : liveData.totalQtyTraded;
        // data.displayName = !liveData.displayName
        //   ? ""
        //   : liveData.displayName;
        data.value = !liveData["value"] ? 0 : liveData["value"];
        data = _.omit(data, ["strike", "callPut", "marketType", "expiry"]);
        axios
          .post("market-watch/save-order", data)
          .then((item) => {
            resolve(item);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  }
);

export const editOrder = createAsyncThunk(
  "marketwatch/order/edit",
  (data, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      let { userIpFinal } = getSessionData();
      let newFinalObject = {};
      let { orderEditData, newData, oneQtySize } = data;
      data.createdBy = orderEditData.userId;
      newFinalObject.userId = orderEditData.userId;
      newFinalObject.id = orderEditData.id;
      newFinalObject.deviceType = 1;
      newFinalObject.segmentId = orderEditData.segmentId;
      newFinalObject.script = orderEditData.script;
      newFinalObject.displayName = orderEditData.displayName;
      newFinalObject.userIp = userIpFinal;
      newFinalObject.orderType = orderEditData.orderType;
      newFinalObject.lot = newData.lot;
      newFinalObject.qty =
        orderEditData.segmentId !== 1 ? oneQtySize * newData.lot : newData.qty;
      newFinalObject.rate = newData.price;
      newFinalObject.executionType = orderEditData.executionType;
      newFinalObject.symbol = data.orderEditData.symbol;

      axios
        .post("order/save-order", newFinalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const deleteOrder = createAsyncThunk(
  "marketwatch/order/delete",
  ({ id }, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      let finalId = [];
      if (Array.isArray(id)) {
        id.forEach((item, index) => finalId.push(item.id));
      } else {
        finalId.push(id);
      }
      axios
        .delete(`order/delete-order`, { data: finalId })
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getOrderBookList = createAsyncThunk(
  "orderbook/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { isCompleted, searchText, pageNo, pageSize, appendData } = data;
      let { userId, roles } = getSessionData();
      let newFinalObject = {};
      newFinalObject.userId = data.userId ? data.userId : userId;
      newFinalObject.pageNo = pageNo;
      newFinalObject.pageSize = pageSize;
      newFinalObject.isCompleted = isCompleted == "pending" ? false : true;
      newFinalObject.segmentId =
        data.segment && data.segment !== undefined ? data.segment.value : null;
      newFinalObject.clientId =
        data.client && data.client !== undefined
          ? data.client.value
          : roles[0] == "User"
            ? userId
            : null;
      newFinalObject.symbol =
        data.symbol && Object.keys(data.symbol).length > 0
          ? data.symbol.value
          : null;
      newFinalObject.searchText = searchText ? searchText : null;
      axios
        .post(`order/get-order-book`, newFinalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            setTimeout(() => {
              resolve({ data: data.data, appendData });
            }, 1000);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

let bgGray = { fillColor: [132, 132, 132], textColor: [255, 255, 255] };

export const exportOrderBook = createAsyncThunk(
  "order/exportorder",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { isCompleted, searchText, pageNo, pageSize, appendData, type } =
        data;
      let { userId, roles } = getSessionData();
      let newFinalObject = {};
      newFinalObject.userId = userId;
      newFinalObject.pageNo = pageNo;
      newFinalObject.pageSize = pageSize;
      newFinalObject.isCompleted = isCompleted == "pending" ? false : true;
      newFinalObject.segmentId =
        data.segment && data.segment !== undefined ? data.segment.value : null;
      newFinalObject.clientId =
        data.client && data.client !== undefined
          ? data.client.value
          : roles[0] == "User"
            ? userId
            : null;
      newFinalObject.symbol =
        data.symbol && Object.keys(data.symbol).length > 0
          ? data.symbol.value
          : null;
      newFinalObject.searchText = searchText ? searchText : null;
      axios
        .post(`order/get-order-book`, newFinalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            if (type == "excel" && data.data.records.length > 0) {
              let finalData =
                data.data &&
                data.data.records.map((item) =>
                  _.omit(item, [
                    "id",
                    "userId",
                    "createdRole",
                    "deviceType",
                    "accountType",
                    "accountTypeName",
                    "orderType",
                    "executionType",
                    "segmentId",
                    "isCompleted",
                  ])
                );
              exportToExcel({
                apiData: finalData,
                fileName: "Tradebook",
              });
            } else if (type == "pdf" && data.data.records.length > 0) {
              let exportData = {
                title: "Order Book",
                FileName: "Order Book",
                exportColumn: orderBookTableColumn,
                exportColumn1: calumnData,
                arrayName: data.data.records,
                theme: "grid",
                headStyles: {
                  bgGray,
                },
                date: moment().format("DD-MM-YYYY"),
                reportType: "OrderBook",
              };
              generatePDF(exportData);
            }
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const executeOrder = createAsyncThunk(
  "order/exicute",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { id, displayName, segmentId } = data;
      // axios
      //   .post(
      //     `trade/convert-to-trade-using-order?orderId=${id}&SegmentId=${segmentId}&DisplayName=${displayName}`
      //   )
      /*  let { id, displayName, segmentId } = data;
      axios
        .post(
          `trade/convert-to-trade-using-order?orderId=${id}&SegmentId=${segmentId}&DisplayName=${displayName}`
        ) */
      axios
        .post(`trade/convert-to-trade-using-order`, null, {
          params: {
            orderId: id,
            DisplayName: displayName,
            SegmentId: segmentId,
          },
        })
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const orderBookSlice = createSlice({
  name: "orderbook",
  initialState,
  reducers: {
    setSortOrderBook: (state, action) => {
      state.sortOrderBookArr = action.payload;
    },
    setSearchTextOrderBook: (state, action) => {
      state.searchTextOrderBook = action.payload;
    },
    setStartApiCallOrderBook: (state, action) => {
      state.startApiCallOrderBook = action.payload;
    },
    setSingleOrderBookEditData: (state, action) => {
      state.singleOrderBookEditData = action.payload;
    },
    setStartPageOrderBook: (state, action) => {
      state.currentPageOrderBook = action.payload;
    },
    setSelectedOrderDetail: (state, action) => {
      state.selectedOrderDetail = action.payload;
    },
    setOrderBookList: (state, action) => {
      state.orderBookList = action.payload;
    },
    setOrderBookEditData: (state, action) => {
      state.orderEditData = action.payload;
    },
    setIsCompletedOrderType: (state, action) => {
      state.isCompletedOrderType = action.payload;
    },
    setIsOrderBookUpdated: (state, action) => {
      state.isOrderBookUpdated = action.payload;
    },
    setIsOrderEditConfirmDialog: (state, action) => {
      state.isOrderEditConfirmDialog = action.payload;
    },
    setOrderBookFilterValue: (state, action) => {
      state.orderBookFilterValue = action.payload;
    },
    setIsOrderBookGet: (state, action) => {
      state.isOrderBookGet = action.payload;
    },
  },
  extraReducers: {
    [getOrderBookList.pending]: (state, action) => {
      state.orderBookPendingLoading = true;
      // state.orderBookList = [];
      state.startApiCallOrderBook = false;
    },
    [getOrderBookList.rejected]: (state, action) => {
      // state.orderBookList = [];
      state.orderBookPendingLoading = false;
      state.startApiCallOrderBook = false;
    },
    [getOrderBookList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.orderBookList = [
          ...state.orderBookList,
          ...action.payload.data.records,
        ];
      } else {
        state.orderBookList = action.payload.data.records;
      }
      state.totalRecordOrderBook = action.payload.data.totalRecords;
      state.startApiCallOrderBook = false;
      state.orderBookPendingLoading = false;
    },
    [executeOrder.pending]: (state, action) => {
      state.isOrderBookUpdated = false;
    },
    [executeOrder.rejected]: (state, action) => {
      state.isOrderBookUpdated = false;
    },
    [executeOrder.fulfilled]: (state, action) => {
      state.isOrderBookUpdated = true;
    },
    [deleteOrder.pending]: (state, action) => {
      state.isOrderBookUpdated = false;
    },
    [deleteOrder.rejected]: (state, action) => {
      state.isOrderBookUpdated = false;
    },
    [deleteOrder.fulfilled]: (state, action) => {
      state.isOrderBookUpdated = true;
    },
    [editOrder.pending]: (state, action) => {
      state.isOrderBookUpdated = false;
    },
    [editOrder.rejected]: (state, action) => {
      state.isOrderBookUpdated = false;
    },
    [editOrder.fulfilled]: (state, action) => {
      state.isOrderBookUpdated = true;
      state.isOrderEditConfirmDialog = false;
    },
  },
});

export const {
  setSortOrderBook,
  setSearchTextOrderBook,
  setStartApiCallOrderBook,
  setSingleOrderBookEditData,
  setStartPageOrderBook,
  setSelectedOrderDetail,
  setOrderBookList,
  setOrderBookEditData,
  setIsCompletedOrderType,
  setIsOrderBookUpdated,
  setIsOrderEditConfirmDialog,
  setOrderBookFilterValue,
  setIsOrderBookGet,
} = orderBookSlice.actions;

export default orderBookSlice.reducer;
