import React, { useEffect, Suspense, useCallback } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUserAuthenticated, setUserRole, setIsMasterRole, logOut, setIsLoading, setUserDevice } from "store/reducers/auth.slice";
import axios from "axios";
import history from "./configs/history";
import { Spinner } from "reactstrap";
import io from 'socket.io-client';
import ProtectedRoute from "configs/ProtectedRoute.config";
import UnProtectedRoute from "configs/UnProtectedRoute.config";
import { getSessionDataOnlyRead, checkIsEmpty } from "utils/common";
import { getDeviceOnlyRead } from "utils/common";
import { getMarketWatchList } from "store/reducers/marketWatch.slice";
import { setSymbolNameString } from "store/reducers/marketWatch.slice";
import MobileUserProfile from "components/Mobile/MobileNewUserProfile";
import MobileOrderBook from "components/Mobile/MobileNewOrderBook"
import MobileTradeBook from "components/Mobile/MobileNewTradeBook"
import MobilePositionReport from "components/Mobile/MobileNewPositionReport"
import MarketWatchMain from "components/Mobile/MarketWatchMain"
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import { setIsOrderBookGet } from "store/reducers/orderbook.slice";
import { setIsTradeBookGet } from "store/reducers/tradeBook.slice";
import {
  setIsPositionGet,
  setIsPositionGet2,
  setPositionReportFilterValue,
  setPositionReportFilterValue2
} from "store/reducers/positionReport.slice";
import { setIsQuantityGet } from "store/reducers/settingsSlice/setting.slice";
import { setIsSettingOptionStrikeGet } from "store/reducers/settingsSlice/settingOptionStrikeValidation.slice";
import { setIsSettingSymbolMaxValueGet } from "store/reducers/settingsSlice/settingSymbolMaxValue.slice";
import { setIsSettingClientSymbolGet } from "store/reducers/settingsSlice/settingClientSegment.slice";
import { setIsSettingClientOrderLimitGet } from "store/reducers/settingsSlice/settingClientOrderLimit.slice";
import { setIsFilterRejectionLogData, setIsFilterDataUpdateLog } from "store/reducers/report.slice";
import { setHomeDashboardList } from "store/reducers/home.slice";
import { setRulesAccepted } from "store/reducers/auth.slice";
import { setIsShowChangePassword } from "store/reducers/auth.slice";
const PageNotFound = React.lazy(() => import("./PageNotFound"));
const Home = React.lazy(() => import("views/home/Index"));
const MobileAddMarketWatchScript = React.lazy(() =>
  import("components/Mobile/MobileAddMarketWatchScript")
);
const MobileConfirmOrder = React.lazy(() =>
  import("components/Mobile/MobileConfirmOrder")
);
const MobileChangePassword = React.lazy(() =>
  import("components/Mobile/MobileNewChangePassword")
);
const MobileRejectionLogs = React.lazy(() =>
  import("components/Mobile/MobileRejectionLogs")
);
const MobileTradeEditDelete = React.lazy(() =>
  import("components/Mobile/MobileTradeEditDelete")
);
const MobileQtySetting = React.lazy(() =>
  import("components/Mobile/MobileQtySetting")
);
const MobileInnerQtySetting = React.lazy(() =>
  import("components/Mobile/MobileInnerQtySetting")
);
const MobileBannedBlockedScript = React.lazy(() =>
  import("components/Mobile/MobileNewBannedBlockedScript")
);
const MobileAddScript = React.lazy(() =>
  import("components/Mobile/MobileAddScript")
);
const MobileAddScriptViewAll = React.lazy(() =>
  import("components/Mobile/MobileAddScriptViewAll")
);
const MobileRules = React.lazy(() => import("components/Mobile/MobileNewRules"));
const MobileOrderDetail = React.lazy(() =>
  import("components/Mobile/MobileOrderDetail")
);
const MobilePositionDetail = React.lazy(() =>
  import("components/Mobile/MobilePositionDetail")
);
const MobileClosePosition = React.lazy(() =>
  import("components/Mobile/MobileClosePosition")
);
const MobileTradeReport = React.lazy(() =>
  import("components/Mobile/MobileNewTradeReport")
);
const MobileLedgerReport = React.lazy(() =>
  import("components/Mobile/MobileNewLedgerReport")
);
const Login = React.lazy(() => import("views/auth/Login"));
const CLogin = React.lazy(() => import("views/auth/CLogin"));
const Register = React.lazy(() => import("views/auth/Register"));
const TermsAndCondition = React.lazy(() =>
  import("views/auth/TermsAndCondition")
);
const MarketWatch = React.lazy(() => import("views/MarketWatch"));
const OrderBook = React.lazy(() => import("views/OrderBook"));
const PositionReport = React.lazy(() => import("views/PositionReport"));
const PositionReport2 = React.lazy(() => import("views/PositionReport2"));
const TradeBook = React.lazy(() => import("views/TradeBook"));
const ChangePassword = React.lazy(() => import("views/profile/ChangePassword"));
const Settings = React.lazy(() => import("views/profile/Settings"));
const BankDetails = React.lazy(() => import("views/master/BankDetails"));
const MasterAccountAdd = React.lazy(() =>
  import("views/master/MasterAccountAdd")
);
const MasterAccountEdit = React.lazy(() =>
  import("views/master/MasterAccountEdit")
);
const MasterAccount = React.lazy(() => import("views/master/MasterAccount"));
const MasterInstantAccount = React.lazy(() => import("views/master/MasterInstantAccount"));
const MasterBrokerageAdd = React.lazy(() =>
  import("views/master/MasterBrokerageAdd")
);
const MasterBrokerageEdit = React.lazy(() =>
  import("views/master/MasterBrokerageEdit")
);
const MasterBrokerage = React.lazy(() =>
  import("views/master/MasterBrokerage")
);
const MasterPartnership = React.lazy(() =>
  import("views/master/MasterPartnership")
);
const MasterPartnershipAdd = React.lazy(() =>
  import("views/master/MasterPartnershipAdd")
);
const MasterPartnershipEdit = React.lazy(() =>
  import("views/master/MasterPartnershipEdit")
);
const TradeEntry = React.lazy(() => import("views/transaction/TradeEntry"));
const TradeEntry2 = React.lazy(() => import("views/transaction/TradeEntry2"));
const TradeUtility = React.lazy(() => import("views/transaction/TradeUtility"));
const TradeInspection = React.lazy(() => import("views/transaction/TradeInspection"));
const SameIpPage = React.lazy(() => import("views/transaction/SameIpPage"));
const TopGainersAndLosers = React.lazy(() => import("views/transaction/TopGainersAndLosers"));
const BillCreation = React.lazy(() => import("views/transaction/BillCreation"));
const UploadBhavCopy = React.lazy(() =>
  import("views/transaction/UploadBhavCopy")
);
const UploadBhavCopyData = React.lazy(() =>
  import("views/transaction/UploadBhavCopyData")
);
const FinanceCashEntry = React.lazy(() =>
  import("views/finance/FinanceCashEntry")
);
const FinanceBankEntry = React.lazy(() =>
  import("views/finance/FinanceBankEntry")
);
const FinanceJVEntry = React.lazy(() => import("views/finance/FinanceJVEntry"))
const PayIn = React.lazy(() => import("views/finance/PayIn"))
const PayOut = React.lazy(() => import("views/finance/PayOut"));
const ReportTrade = React.lazy(() => import("views/report/ReportTrade"));
const ReportLedger = React.lazy(() => import("views/report/ReportLedger"));
const ReportUpdateLog = React.lazy(() =>
  import("views/report/ReportUpdateLog")
);
const ReportRejectionLog = React.lazy(() =>
  import("views/report/ReportRejectionLog")
);
const SettingQty = React.lazy(() => import("views/settings/SettingQty"));
const SettingOffQuote = React.lazy(() => import("views/settings/SettingOffQuote"));
const CallPutSetting = React.lazy(() =>
  import("views/settings/CallPutSetting")
);
const SettingBlockScript = React.lazy(() =>
  import("views/settings/SettingBlockScript")
);
const SettingScript = React.lazy(() => import("views/settings/SettingScript"));
const SettingTransfer = React.lazy(() =>
  import("views/settings/SettingTransfer")
);
const SettingExpiry = React.lazy(() => import("views/settings/SettingExpiry"));
const SettingScriptMax = React.lazy(() =>
  import("views/settings/SettingScriptMax")
);
const SettingSegment = React.lazy(() =>
  import("views/settings/SettingSegment")
);
const SettingClientOrder = React.lazy(() =>
  import("views/settings/SettingClientOrder")
);
const BannedBlockedScripts = React.lazy(() =>
  import("views/BannedBlockedScripts")
);
const MaxQuantityDetails = React.lazy(() => import("views/MaxQuantityDetails"));
const MasterValan = React.lazy(() => import("views/master/MasterValan"));
const MasterValanAdd = React.lazy(() => import("views/master/MasterValanAdd"));
const MasterValanEdit = React.lazy(() =>
  import("views/master/MasterValanEdit")
);
const Rules = React.lazy(() => import("views/profile/Rules"));
const PrivacyPolicy = React.lazy(() => import("views/profile/PrivacyPolicy"));
const SettingScriptMaxQty = React.lazy(() =>
  import("views/settings/SettingScriptMaxQty")
);
const SettingOptionStrikeValidation = React.lazy(() =>
  import("views/settings/SettingOptionStrikeValidation")
);
const SettingCPanel = React.lazy(() => import("views/settings/SettingCPanel"));
const ClientMap = React.lazy(() => import("views/settingSuperAdmin/ClientMap"));
const SystemUserMap = React.lazy(() =>
  import("views/settingSuperAdmin/SystemUserMap")
);
const AuthorityRights = React.lazy(() =>
  import("views/settingSuperAdmin/AuthorityRights")
);
const AdminSystemUsers = React.lazy(() =>
  import("views/settingSuperAdmin/AdminSystemUsers")
);
const AdminSystemUsersAdd = React.lazy(() =>
  import("views/settingSuperAdmin/AdminSystemUsersAdd")
);
const AuthorityLimits = React.lazy(() =>
  import("views/settingSuperAdmin/AuthorityLimits")
);
const FrontPage = React.lazy(() =>
  import("./components/FrontPage/FrontPage")
);
const TrialBalance = React.lazy(() => import("views/report/TrialBalance"));
// export const sock = new WebSocket("ws://194.233.95.57:6266/ws");
// export const socket = io.connect('http://101.53.150.103:3000');
// export const socket = io.connect('http://4.240.113.255:2025/');
export let sock
export let socket;
const firebaseConfig = {
  apiKey: "AIzaSyDnU_kpnlVNXFFF9D1JpvPBjS4OwDXo80I",
  authDomain: "golden-money-5b3f7.firebaseapp.com",
  databaseURL: "https://golden-money-5b3f7-default-rtdb.firebaseio.com",
  projectId: "golden-money-5b3f7",
  storageBucket: "golden-money-5b3f7.appspot.com",
  messagingSenderId: "1052396250571",
  appId: "1:1052396250571:web:67bb2ff6ed87b43685824e",
  measurementId: "G-VL2MM4Q6LJ"
};

firebase.initializeApp(firebaseConfig)

function App() {
  const { userIp, isUserAuthenticated, isLoading } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 123) {
        event.preventDefault();
      }else if(event.ctrlKey && event.shiftKey && event.keyCode === 73){
        event.preventDefault();
      }
    }, [],
  )
  document.addEventListener('keydown', handleKeyDown);
  document.addEventListener("contextmenu", (event) => event.preventDefault());
  const sessionData = sessionStorage.getItem("TradeSession");
  const authorityData = JSON.parse(
    sessionStorage.getItem("TradeAuthoritySession")
  );
  if (!checkIsEmpty(sessionData)) {
    sessionStorage.setItem("TradeSession", sessionData);
  }
  if (!checkIsEmpty(authorityData)) {
    sessionStorage.setItem(
      "TradeAuthoritySession",
      JSON.stringify(authorityData)
    );
  }
  const handleLogOut = useCallback(
    async () => {
      await dispatch(setIsOrderBookGet(false));
      await dispatch(setIsTradeBookGet(false));
      await dispatch(setIsPositionGet(false));
      await dispatch(setIsPositionGet2(false));
      await dispatch(setPositionReportFilterValue({}));
      await dispatch(setPositionReportFilterValue2({}));
      await dispatch(setIsQuantityGet(false));
      await dispatch(setIsSettingOptionStrikeGet(false));
      await dispatch(setIsSettingSymbolMaxValueGet(false));
      await dispatch(setIsSettingClientSymbolGet(false));
      await dispatch(setIsSettingClientOrderLimitGet(false));
      await dispatch(setIsFilterRejectionLogData(false));
      await dispatch(setIsFilterDataUpdateLog(false));
      await dispatch(setHomeDashboardList([]));
      await dispatch(logOut({ userIp }));
      await dispatch(setUserDevice(""));
      await dispatch(setRulesAccepted(true))
      await dispatch(setIsShowChangePassword(false))
      window.location.reload();
    },
    [dispatch, userIp],
  )
  axios.interceptors.response.use(
    response => response, // Return the response if no error
    error => {
        if (error.response && error.response.status === 401) {
            sessionStorage.removeItem("TradeSession");
            sessionStorage.removeItem("DeviceType");
            sessionStorage.removeItem("TradeAuthoritySession");
            return Promise.reject(error);
        }
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    sessionStorage.setItem("AuthUrl", window.btoa(JSON.stringify({ baseUrl: '', socketUrl: '' })));
    const getSocketURLFromFirebase = async () => {
      try {
        dispatch(setIsLoading(true))
        const tasksRef = firebase.database().ref('AppInfo');
        tasksRef.on('value', (snapshot) => {
          const tasksData = snapshot.val();
          axios.defaults.baseURL = tasksData.base_url;
          socket = io.connect(tasksData.socketio_online)
          const authUrlObj = sessionStorage.getItem("AuthUrl");
          const authURL = authUrlObj ? JSON.parse(window.atob(authUrlObj)) : null
          if (authURL?.baseUrl === '' && authURL?.socketUrl === '') {
            const authUrlObj = {
              baseUrl: tasksData?.base_url ? tasksData.base_url : '',
              socketUrl: tasksData?.socketio_online ? tasksData.socketio_online : ''
            }
            sessionStorage.setItem("AuthUrl", window.btoa(JSON.stringify(authUrlObj)));
          } else {
            if (authURL?.baseUrl === tasksData?.base_url || authURL?.socketUrl === tasksData?.socketio_online) {
              sessionStorage.removeItem("AuthUrl");
              handleLogOut()
            }
          }
          dispatch(setIsLoading(false))
        });
      } catch (error) {
        dispatch(setIsLoading(false))
        console.error("Error fetching socket URL:", error);
      }
    };
    getSocketURLFromFirebase()
  }, [dispatch])

  useEffect(() => {
    if (!isLoading) {
      socket.on('connect', () => {
        console.log('socket connect.');
      });
    }
    // socket.on('disconnect', function (data) {
    //   console.log('Disconnect:');
    //   // subscribe('reconnect')
    // });
    // return () => {
    //   socket.disconnect(); // Disconnect the socket when the component unmounts
    // };
  }, [isLoading])

  // When user comes back to Trade app window, check for socket and if closed reconnect
  /* const reconnectSocketIfClosedOnTabActive = useCallback(() => {
    if (!sock || (sock && (sock.readyState === 2 || sock.readyState === 3))) {
      sock = new WebSocket("ws://35.88.35.149:2023/ws");
      dispatch(setSymbolNameString(""));
      dispatch(getMarketWatchList({ isRefresh: true }));
    }
  }, [sock]); */

  /*   window.addEventListener("focus", reconnectSocketIfClosedOnTabActive);
    document.addEventListener(
      "visibilitychange",
      reconnectSocketIfClosedOnTabActive
    ); */

  useEffect(() => {
    let sessionData = getSessionDataOnlyRead();
    const deiceType = getDeviceOnlyRead();
    if (deiceType) {
      dispatch(setUserDevice("Mobile"));
    }
    if (!checkIsEmpty(sessionData)) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${sessionData.tokens.accessToken}`;
      dispatch(setIsMasterRole(sessionData.isMasterRole));
      dispatch(setUserAuthenticated(true));
      dispatch(setUserRole(sessionData.roles[0]));
      if (sessionData?.roles?.[0] === 'User' && sessionData?.isShowChangePassword) {
        dispatch(setIsShowChangePassword(true))
      }
      if (sessionData?.roles?.[0] === 'User' && !sessionData?.rulesAccepted) {
        dispatch(setRulesAccepted(false))
      }
    }
  }, [dispatch, sessionData, isUserAuthenticated]);

  return (
    <>
      {isLoading && <div className="spinner-box">
        <Spinner className="ml-2" color="primary" />
      </div>}
      <Router history={history}>
        <Suspense
          fallback={
            <div className="spinner-box">
              <Spinner color="primary" />
            </div>
          }
        >
          <Switch>
            <ProtectedRoute exact path="/front_page" component={FrontPage} />
            <ProtectedRoute exact path="/" component={Home} />
            <UnProtectedRoute exact path="/admin" component={Login} />
            <UnProtectedRoute exact path="/login" component={CLogin} />
            <UnProtectedRoute exact path="/app/login" component={CLogin} />
            <UnProtectedRoute
              exact
              path="/auth/register"
              component={Register}
            />
            <UnProtectedRoute exact path="/tc" component={TermsAndCondition} />
            <ProtectedRoute exact path="/app" component={MarketWatchMain} />
            <ProtectedRoute
              exact
              path="/app/add-marketwatch-script"
              component={MobileAddMarketWatchScript}
            />
            <ProtectedRoute
              exact
              path="/app/confirm-order"
              component={MobileConfirmOrder}
            />
            <ProtectedRoute
              exact
              path="/app/order-detail"
              component={MobileOrderDetail}
            />
            <ProtectedRoute
              exact
              path="/app/banned-block-script"
              component={MobileBannedBlockedScript}
            />
            <ProtectedRoute
              exact
              path="/app/add-script"
              component={MobileAddScript}
            />
            <ProtectedRoute
              exact
              path="/app/add-script/script"
              component={MobileAddScriptViewAll}
            />
            <ProtectedRoute exact path="/app/rules" component={MobileRules} />
            <ProtectedRoute
              exact
              path="/app/order-book"
              component={MobileOrderBook}
            />
            <ProtectedRoute
              exact
              path="/app/trade-book"
              component={MobileTradeBook}
            />
            <ProtectedRoute
              exact
              path="/app/position-detail"
              component={MobilePositionDetail}
            />
            <ProtectedRoute
              exact
              path="/app/close-position"
              component={MobileClosePosition}
            />
            <ProtectedRoute
              exact
              path="/app/position-report"
              component={MobilePositionReport}
            />
            <ProtectedRoute
              exact
              path="/app/trade-report"
              component={MobileTradeReport}
            />
            <ProtectedRoute
              exact
              path="/app/ledger-report"
              component={MobileLedgerReport}
            />
            <ProtectedRoute
              exact
              path="/app/profile"
              component={MobileUserProfile}
            />
            <ProtectedRoute
              exact
              path="/app/change-password"
              component={MobileChangePassword}
            />
            <ProtectedRoute
              exact
              path="/app/rejection-logs"
              component={MobileRejectionLogs}
            />
            <ProtectedRoute
              exact
              path="/app/trade-edit-delete-logs"
              component={MobileTradeEditDelete}
            />
            <ProtectedRoute
              exact
              path="/app/qty-setting"
              component={MobileQtySetting}
            />
            <ProtectedRoute
              exact
              path="/app/qty-setting/qty-inner-setting"
              component={MobileInnerQtySetting}
            />
            <ProtectedRoute path="/marketwatch" component={MarketWatch} />
            <ProtectedRoute exact path="/order-book" component={OrderBook} />
            <ProtectedRoute
              exact
              path="/position-report"
              component={PositionReport}
            />
            <ProtectedRoute
              exact
              path="/position-report2"
              component={PositionReport2}
            />
            <ProtectedRoute exact path="/trade-book" component={TradeBook} />
            <ProtectedRoute
              exact
              path="/profile/change-password"
              component={ChangePassword}
            />
            <ProtectedRoute
              exact
              path="/profile/settings"
              component={Settings}
            />
            <ProtectedRoute
              exact
              path="/master/account"
              component={MasterAccount}
            />
            <ProtectedRoute
              exact
              path="/master/account/instant-account"
              component={MasterInstantAccount}
            />
            <ProtectedRoute
              exact
              path="/master/account/add"
              component={MasterAccountAdd}
            />
            <ProtectedRoute
              exact
              path="/master/account/edit"
              component={MasterAccountEdit}
            />
            <ProtectedRoute
              exact
              path="/master/brokerage"
              component={MasterBrokerage}
            />
            <ProtectedRoute
              exact
              path="/master/brokerage/add"
              component={MasterBrokerageAdd}
            />
            <ProtectedRoute
              exact
              path="/master/brokerage/edit"
              component={MasterBrokerageEdit}
            />
            <ProtectedRoute
              exact
              path="/master/partnership"
              component={MasterPartnership}
            />
            <ProtectedRoute
              exact
              path="/master/partnership/add"
              component={MasterPartnershipAdd}
            />
            <ProtectedRoute
              exact
              path="/master/partnership/edit"
              component={MasterPartnershipEdit}
            />
            <ProtectedRoute
              exact
              path="/transaction/trade-entry"
              component={TradeEntry2}
            />
            <ProtectedRoute
              exact
              path="/transaction/trade-utility"
              component={TradeUtility}
            />
            <ProtectedRoute
              exact
              path="/transaction/bill-creation"
              component={BillCreation}
            />
            <ProtectedRoute
              exact
              path="/transaction/upload-bhavCopy"
              component={UploadBhavCopy}
            />
            <ProtectedRoute
              exact
              path="/transaction/upload-bhavCopy-data"
              component={UploadBhavCopyData}
            />
            <ProtectedRoute
              exact
              path="/finance/cash-entry"
              component={FinanceCashEntry}
            />
            <ProtectedRoute
              exact
              path="/finance/bank-entry"
              component={FinanceBankEntry}
            />
            <ProtectedRoute
              exact
              path="/finance/jv-entry"
              component={FinanceJVEntry}
            />
            <ProtectedRoute
              exact
              path="/finance/bank-details"
              component={BankDetails}
            />
            <ProtectedRoute
              exact
              path="/finance/pay-in"
              component={PayIn}
            />
            <ProtectedRoute
              exact
              path="/finance/pay-out"
              component={PayOut}
            />
            <ProtectedRoute
              exact
              path="/finance/bank-details"
              component={BankDetails}
            />
            <ProtectedRoute
              exact
              path="/reports/trade-report"
              component={ReportTrade}
            />
            <ProtectedRoute
              exact
              path="/reports/ledger-report"
              component={ReportLedger}
            />
            <ProtectedRoute
              exact
              path="/reports/log-report"
              component={ReportUpdateLog}
            />
            <ProtectedRoute
              exact
              path="/reports/rejection-log"
              component={ReportRejectionLog}
            />
            <ProtectedRoute
              exact
              path="/settings/quantity"
              component={SettingQty}
            />
            <ProtectedRoute
              exact
              path="/settings/script-management"
              component={SettingOffQuote}
            />
            <ProtectedRoute
              exact
              path="/settings/call-put-setting"
              component={CallPutSetting}
            />
            <ProtectedRoute
              exact
              path="/settings/banned-scripts"
              component={SettingBlockScript}
            />
            <ProtectedRoute
              exact
              path="/settings/block-scripts"
              component={SettingScript}
            />
            <ProtectedRoute
              exact
              path="/settings/transfer"
              component={SettingTransfer}
            />
            <ProtectedRoute
              exact
              path="/settings/expiry-validation"
              component={SettingExpiry}
            />
            <ProtectedRoute
              exact
              path="/settings/script-max-value"
              component={SettingScriptMax}
            />
            <ProtectedRoute
              exact
              path="/settings/client-segment"
              component={SettingSegment}
            />
            <ProtectedRoute
              exact
              path="/settings/client-order-limit"
              component={SettingClientOrder}
            />
            <ProtectedRoute
              exact
              path="/banned-blocked-scripts"
              component={BannedBlockedScripts}
            />
            <ProtectedRoute
              exact
              path="/max-quantity-details"
              component={MaxQuantityDetails}
            />
            <ProtectedRoute
              exact
              path="/master/valan"
              component={MasterValan}
            />
            <ProtectedRoute
              exact
              path="/master/valan/add"
              component={MasterValanAdd}
            />
            <ProtectedRoute
              exact
              path="/master/valan/edit"
              component={MasterValanEdit}
            />
            <ProtectedRoute exact path="/profile/rules" component={Rules} />
            <Route
              exact
              path="/profile/privacy-policy"
              component={PrivacyPolicy}
            />
            <ProtectedRoute
              exact
              path="/settings/script-max-qty-setting"
              component={SettingScriptMaxQty}
            />
            <ProtectedRoute
              exact
              path="/settings/option-strike-validation"
              component={SettingOptionStrikeValidation}
            />
            <ProtectedRoute
              exact
              path="/inspect/trade-inspection"
              component={TradeInspection}
            />
            <ProtectedRoute
              exact
              path="/inspect/same-ip"
              component={SameIpPage}
            />
            <ProtectedRoute
              exact
              path="/inspect/top-gainers-and-losers"
              component={TopGainersAndLosers}
            />
            <ProtectedRoute
              exact
              path="/settings/cPanel"
              component={SettingCPanel}
            />
            <ProtectedRoute
              exact
              path="/admin/client-map"
              component={ClientMap}
            />
            <ProtectedRoute
              exact
              path="/admin/system-user-map"
              component={SystemUserMap}
            />
            <ProtectedRoute
              exact
              path="/admin/authority-rights"
              component={AuthorityRights}
            />
            <ProtectedRoute
              exact
              path="/admin/system-users"
              component={AdminSystemUsers}
            />
            <ProtectedRoute
              exact
              path="/admin/system-users/add"
              component={AdminSystemUsersAdd}
            />
            <ProtectedRoute
              exact
              path="/admin/authority-limits"
              component={AuthorityLimits}
            />
            <ProtectedRoute
              exact
              path="/admin/trial-balance"
              component={TrialBalance}
            />
            <Route component={PageNotFound} />
            {/* {UnAuthRoutes.map((RouteObj, index) => (
              <UnProtectedRoute
                key={index}
                path={RouteObj.path}
                exact
                component={RouteObj.component}
              />
            ))}
            <ProtectedRoute component={ComponentWithNavbar} /> */}
          </Switch>
          {/* </div> */}
        </Suspense>
      </Router>
      {/* </div> */}
    </>
  );
}

export default App;
