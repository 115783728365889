import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import { showMessage } from "./common.slice";
import { getSessionData } from "utils/common";

const initialState = {
  bankDetails: {
    bankName: "",
    bankHolderName: "",
    bankAccountNumber: "",
    bankIFSC: "",
  },
  upiDetails: {
    bankUPI: "",
    bankUPIImage: "",
  },
  bankId: "",
  bankloading: false,
};

export const updateBankDetails = createAsyncThunk(
  "bankDetails/bankDetails/update",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.id = data.bankId;
      newFinalObject.bankName = data.bankName;
      newFinalObject.bankHolderName = data.bankHolderName;
      newFinalObject.bankAccountNumber = data.bankAccountNumber;
      newFinalObject.bankUPIImage = data.bankUPIImage;
      newFinalObject.bankUPI = data.bankUPI;
      newFinalObject.bankIFSC = data.bankIFSC;
      newFinalObject.userId = userId;
      newFinalObject.CreatedBy = userId;
      axios
        .post("finance/bank-detail/save-bank-detail", newFinalObject)
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(showMessage({ message: "Details saved successfully !" }));
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          console.error(
            "Error saving bank details:",
            error.response ? error.response.data : error.message
          );
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getBankDetails = createAsyncThunk(
  "bankDetails/bankDetails/get",
  () => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(`finance/bank-detail/get-bank-details/${userId}/1/1000`)
        .then(({ data }) => {
          if (data.status === 200 && data?.data?.records?.length > 0) {
            resolve(data?.data?.records[0]);
          } else {
            resolve({
              ...initialState.bankDetails,
              ...initialState.upiDetails,
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const bankSlice = createSlice({
  name: "bankDetails",
  initialState,
  reducers: {},
  extraReducers: {
    [updateBankDetails.pending]: (state, action) => {
      state.bankloading = true;
    },
    [updateBankDetails.rejected]: (state, action) => {
      state.bankloading = false;
    },
    [updateBankDetails.fulfilled]: (state, action) => {
      state.bankloading = false;
    },

    //get bank details
    [getBankDetails.pending]: (state, action) => {
      state.bankloading = true;
    },
    [getBankDetails.rejected]: (state, action) => {
      state.bankloading = false;
    },
    [getBankDetails.fulfilled]: (state, action) => {
      state.bankDetails = {
        bankName: action.payload.bankName || "",
        bankHolderName: action.payload.bankHolderName || "",
        bankAccountNumber: action.payload.bankAccountNumber || "",
        bankIFSC: action.payload.bankIFSC || "",
      };
      state.upiDetails = {
        bankUPI: action.payload.bankUPI || "",
        bankUPIImage: action.payload.bankUPIImage || "",
      };
      state.bankId = action.payload.id || 0;
      state.bankloading = false;
    },
  },
});

export const {} = bankSlice.actions;

export default bankSlice.reducer;
