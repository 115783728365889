import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {Search} from "./home.slice";
import _ from "lodash";
import {showMessage} from "./common.slice";
import moment from "moment";
import { getSessionData } from "utils/common";

const initialState = {
	valansList: [],
	searchValansList: [],
	valansLoading: false,
	sortValansArr: [],
	valanSearchText: "",
	currentPageValans: 1,
	pageSizeValans: 10,
	totalRecordValans: 0,
	startApiCallValansList: true,
	isValansUpdated: false,
	valanViewEditDeleteData: {},
	valanDeleteId: "",
};

export const addValan = createAsyncThunk(
	"master/valan/add",
	(data, {dispatch}) => {
		return new Promise((resolve, reject) => {
			let {userId} = getSessionData();
			let newFinalObject = {};
			newFinalObject.id = data.id;
			newFinalObject.name = data.name;
			newFinalObject.createdBy = userId;
			newFinalObject.startDate = moment(data.startDate).format();
			newFinalObject.endDate = moment(data.endDate).format();
			newFinalObject.isDailyBilling =
				data.isDailyBilling == "No" ? false : true;
			newFinalObject.status = data.status == "Open" ? 1 : 0;
			newFinalObject.segments = data.segments.map((item) => {
				return {segmentId: item.value};
			});
			axios
				.post("master/valan/save-valan", newFinalObject)
				.then(({data}) => {
					if (data.status == 200) {
						dispatch(showMessage({message: data.message, varient: "success"}));
						resolve(data);
					} else {
						dispatch(showMessage({message: data.message}));
						reject(data);
					}
				})
				.catch((error) => {
					if (error.response) {
						dispatch(showMessage({message: error.response.data.message}));
					} else if (error.request) {
						dispatch(showMessage({message: error.request.message}));
					} else {
						dispatch(showMessage({message: error.message}));
					}
					reject(error);
				});
		});
	}
);

export const editValan = createAsyncThunk(
	"master/valan/edit",
	(data, {dispatch}) => {
		return new Promise((resolve, reject) => {
			let {userId} = getSessionData();
			const clientBrokerage = {};
			clientBrokerage.id = 0;
			clientBrokerage.rate = data.cRate;
			clientBrokerage.minimumRate = data.sbMinimumRate;
			clientBrokerage.intradayRate = data.cIntradayRate;
			clientBrokerage.isHigherSideOnly = data.cIsHigherSideOnly;
			clientBrokerage.minimumPercentage = data.cMinimumPercentage;
			clientBrokerage.isMinPercentageOnDelivery =
				data.cIsMinPercentageOnDelivery;
			const subBrokerBrokerage = {};
			subBrokerBrokerage.id = 0;
			subBrokerBrokerage.rate = data.sbRate;
			subBrokerBrokerage.minimumRate = data.sbMinimumRate;
			subBrokerBrokerage.intradayRate = data.sbIntradayRate;
			subBrokerBrokerage.isHigherSideOnly = data.sbIsHigherSideOnly;
			subBrokerBrokerage.minimumPercentage = data.sbMinimumPercentage;
			subBrokerBrokerage.isMinPercentageOnDelivery =
				data.sbIsMinPercentageOnDelivery;
			// segmentId
			axios
				.post("master/brokerage/update-brokerage", {
					createdBy: userId,
					id: data.id,
					userId: data.userId.value,
					brokerageType: data.brokerageType == "%" ? 1 : 2,
					SegmentId: data.segmentId.value,
					script: data.isScriptWise ? data.script.value : "",
					isScriptWise: data.isScriptWise,
					clientBrokerage,
					subBrokerBrokerage,
				})
				.then(({data}) => {
					if (data.status == 200) {
						dispatch(showMessage({message: data.message, varient: "success"}));
						resolve(data);
					} else {
						dispatch(showMessage({message: data.message}));
						reject(data);
					}
				})
				.catch((error) => {
					if (error.response) {
						dispatch(showMessage({message: error.response.data.message}));
					} else if (error.request) {
						dispatch(showMessage({message: error.request.message}));
					} else {
						dispatch(showMessage({message: error.message}));
					}
					reject(error);
				});
		});
	}
);

export const deleteValan = createAsyncThunk(
	"master/valan/delete",
	(data, {dispatch}) => {
		return new Promise((resolve, reject) => {
			//user info
			let {id} = data;
			axios
				.delete(`master/valan/delete-valan/${id}`)
				.then(({data}) => {
					if (data.status == 200) {
						dispatch(showMessage({message: data.message, varient: "success"}));
						resolve(data);
					} else {
						dispatch(showMessage({message: data.message}));
						reject(data);
					}
				})
				.catch((error) => {
					if (error.response) {
						dispatch(showMessage({message: error.response.data.message}));
					} else if (error.request) {
						dispatch(showMessage({message: error.request.message}));
					} else {
						dispatch(showMessage({message: error.message}));
					}
					reject(error);
				});
		});
	}
);

export const getValansList = createAsyncThunk(
	"master/valans/get",
	(dataProps) => {
		return new Promise((resolve, reject) => {
			axios
				.get(`master/valan/get-valans`)
				.then(({data}) => {
					let newDataArray = data.data;
					if (dataProps !== undefined && dataProps) {
						newDataArray =
							Object.keys(data.data).length > 0 &&
							Object.values(data.data).map((item, i) => {
								return {label: item.name, value: item.id};
							});
					}
					resolve(newDataArray);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
);

export const masterSlice = createSlice({
	name: "masterValans",
	initialState,
	reducers: {
		setSearchTextValans: (state, action) => {
			state.searchTextMasterBrokerages = action.payload;
		},
		setSearchValan: (state, action) => {
			state.valansList = state.searchValansList;
			let newArray = Search(action.payload, state.valansList);
			state.valansList = newArray;
		},
		setValanSearchText: (state, action) => {
			state.valanSearchText = action.payload;
		},
		setStartPageValans: (state, action) => {
			state.currentPageBrokerages = action.payload;
		},
		setStartApiCallValansList: (state, action) => {
			state.startApiCallValansList = action.payload;
		},
		setTotalRecordValans: (state, action) => {
			state.totalRecordValans = action.payload;
		},
		setIsValansUpdated: (state, action) => {
			state.isValansUpdated = action.payload;
		},
		setValansViewData: (state, action) => {
			state.valanViewEditDeleteData = action.payload;
		},
		setSortMasterValans: (state, action) => {
			state.sortValansArr = action.payload;
		},
		setValansList: (state, action) => {
			state.valansList = action.payload;
		},
		setValansDeleteId: (state, action) => {
			state.valanDeleteId = action.payload;
		},
	},
	extraReducers: {
		[addValan.pending]: (state, action) => {
			state.valansLoading = true;
		},
		[addValan.rejected]: (state, action) => {
			state.valansLoading = false;
		},
		[addValan.fulfilled]: (state, action) => {
			state.isValansUpdated = true;
			state.valansLoading = false;
		},
		//valan list
		[getValansList.pending]: (state, action) => {
			state.startApiCallValansList = false;
			state.valansLoading = true;
		},
		[getValansList.rejected]: (state, action) => {
			state.valansList = [];
			state.searchValansList = [];
			state.startApiCallValansList = false;
			state.valansLoading = false;
		},
		[getValansList.fulfilled]: (state, action) => {
			state.startApiCallValansList = false;
			state.valansList = action.payload;
			state.searchValansList = action.payload;
			//   state.totalRecordValans = action.payload.data.totalRecords;
			//   if(action.payload.appendData){
			//     state.valanList = [...state.valanList, ...action.payload];
			//   }else{
			//     state.valanList = action.payload;
			//   }
			state.valansLoading = false;
		},

		//edit valan
		[editValan.pending]: (state, action) => {
			state.isValansUpdated = false;
		},
		[editValan.rejected]: (state, action) => {
			state.isValansUpdated = false;
		},
		[editValan.fulfilled]: (state, action) => {
			state.isValansUpdated = true;
		},
		//delete valan
		[deleteValan.pending]: (state, action) => {
			state.isValansUpdated = false;
		},
		[deleteValan.rejected]: (state, action) => {
			state.isValansUpdated = false;
		},
		[deleteValan.fulfilled]: (state, action) => {
			state.isValansUpdated = true;
		},
	},
});

export const {
	setSortMasterValans,
	setStartPageValans,
	setIsValansUpdated,
	setValansViewData,
	setStartApiCallValansList,
	setValansList,
	setValanSearchText,
	setSearchValan,
	setValansDeleteId,
} = masterSlice.actions;

export default masterSlice.reducer;
