import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import { getSessionDataOnlyRead } from "utils/common";
import UpDownArrow from '../../assets/img/icons/up_down.svg'
import { useDispatch, useSelector } from "react-redux";
import { setIsOrderBookGet } from "store/reducers/orderbook.slice";
import { setIsTradeBookGet } from "store/reducers/tradeBook.slice";
import { setIsPositionGet, setPositionReportFilterValue } from "store/reducers/positionReport.slice";
import { setIsQuantityGet } from "store/reducers/settingsSlice/setting.slice";
import { setIsSettingOptionStrikeGet } from "store/reducers/settingsSlice/settingOptionStrikeValidation.slice";
import { setIsSettingSymbolMaxValueGet } from "store/reducers/settingsSlice/settingSymbolMaxValue.slice";
import { setIsSettingClientSymbolGet } from "store/reducers/settingsSlice/settingClientSegment.slice";
import { setIsSettingClientOrderLimitGet } from "store/reducers/settingsSlice/settingClientOrderLimit.slice";
import { setHomeDashboardList } from "store/reducers/home.slice";
import { setIsFilterRejectionLogData, setIsFilterDataUpdateLog } from "store/reducers/report.slice";
import { logOut, setUserDevice } from "store/reducers/auth.slice";
import "../../assets/scss/mobile/newStyleMobile.scss";
import { setRulesAccepted } from "store/reducers/auth.slice";
import { setIsShowChangePassword } from "store/reducers/auth.slice";

export default function MobileNewUserProfile() {
  const dispatch = useDispatch()
  const { userIp } = useSelector(({ auth }) => auth);
  const [switchUser, setSwitchUser] = useState(false);
  let sessionData = getSessionDataOnlyRead();
  const handleClose = () => setSwitchUser(false);
  const handleShow = () => setSwitchUser(true);

  const handleLogOut = useCallback(
    async () => {
      await dispatch(setIsOrderBookGet(false));
      await dispatch(setIsTradeBookGet(false));
      await dispatch(setIsPositionGet(false));
      await dispatch(setPositionReportFilterValue({}));
      await dispatch(setIsQuantityGet(false));
      await dispatch(setIsSettingOptionStrikeGet(false));
      await dispatch(setIsSettingSymbolMaxValueGet(false));
      await dispatch(setIsSettingClientSymbolGet(false));
      await dispatch(setIsSettingClientOrderLimitGet(false));
      await dispatch(setIsFilterRejectionLogData(false));
      await dispatch(setIsFilterDataUpdateLog(false));
      await dispatch(setHomeDashboardList([]));
      await dispatch(logOut({ userIp }));
      await dispatch(setUserDevice(""));
      await dispatch(setRulesAccepted(true))
      await dispatch(setIsShowChangePassword(false))
      // window.location.reload();
    },
    [dispatch, userIp],
  )
  return (
    <div className="muser_profile_wrap">
      <header>
        <div className="userprofile_header">
          <h2 className="m-0">Profile</h2>
        </div>
      </header>
      <main>
        <div className="userprofile_main_wrap">
          <div className="userprofile_top">
            <div className="userprofile_img">
              <span className="userprofile_icon">
                <i className="fa-solid fa-circle-user"></i>
              </span>
            </div>
            <div className="userprofile_name">
              <h6 className="text_mediam fw_bold m-0">{sessionData?.userName}</h6>
              <small className="user_name_small">{sessionData?.userCode}</small>
            </div>
          </div>
          <div className="userprofile_detail">
            <ul>
              <li>
                <Link to="/app/change-password" className="blue_box_wrap">
                  <div className="detail_left">
                    <div className="profile_icon_wrap">
                      <span className="material-symbols-outlined profile_icon">
                        lock_open
                      </span>
                    </div>
                    Change Password
                  </div>
                  <span className="material-symbols-outlined arrow_icon">
                    navigate_next
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/app/rejection-logs" className="blue_box_wrap">
                  <div className="detail_left">
                    <div className="profile_icon_wrap">
                      <span className="material-symbols-outlined profile_icon">
                        history
                      </span>
                    </div>
                    Rejection Logs
                  </div>
                  <span className="material-symbols-outlined arrow_icon">
                    navigate_next
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/app/trade-edit-delete-logs"
                  className="blue_box_wrap"
                >
                  <div className="detail_left">
                    <div className="profile_icon_wrap">
                      <span className="material-symbols-outlined profile_icon">
                        history
                      </span>
                    </div>
                    Trade edit/Delete Logs
                  </div>
                  <span className="material-symbols-outlined arrow_icon">
                    navigate_next
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/app/banned-block-script" className="blue_box_wrap">
                  <div className="detail_left">
                    <div className="profile_icon_wrap">
                      <img src={UpDownArrow} alt="UpDownArrow" />
                    </div>
                    Banned/Blocked Scripts
                  </div>
                  <span className="material-symbols-outlined arrow_icon">
                    navigate_next
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/app/qty-setting" className="blue_box_wrap">
                  <div className="detail_left">
                    <div className="profile_icon_wrap">
                      <span className="material-symbols-outlined profile_icon">
                        question_mark
                      </span>
                    </div>
                    Qty Setting
                  </div>
                  <span className="material-symbols-outlined arrow_icon">
                    navigate_next
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/app/rules" className="blue_box_wrap">
                  <div className="detail_left">
                    <div className="profile_icon_wrap">
                      <span className="material-symbols-outlined profile_icon">
                        filter_list
                      </span>
                    </div>
                    Rules
                  </div>
                  <span className="material-symbols-outlined arrow_icon">
                    navigate_next
                  </span>
                </Link>
              </li>
              <li>
                <span
                  onClick={handleShow}
                  className="blue_box_wrap"
                >
                  <div className="detail_left">
                    <div className="profile_icon_wrap">
                      <span className="material-symbols-outlined profile_icon">
                        power_settings_new
                      </span>
                    </div>
                    Logout
                  </div>
                  <span className="material-symbols-outlined arrow_icon">
                    navigate_next
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </main>
      <Modal
        className="modal-dialog-centered custom_model"
        centered
        style={{ zIndex: 999999 }}
        isOpen={switchUser}
      >
        <div className="model_content_wrap log_out_text">
          <p className="log_out_text">
            Are you sure want to logout ?
          </p>
          <div className="footer_btn_wrap">
            <ul className="d-flex justify-content-end">
              <li>
                <button type="button" className="trans_btn mr-2" onClick={handleClose}>
                  CANCEL
                </button>
              </li>
              <li>
                <button type="button" className="trans_btn" onClick={() => handleLogOut()}>
                  LOGOUT
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
}
