import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { generatePDF, exportToExcel } from "utils/reportGenerator";
import { getSessionData } from "utils/common";
import _ from "lodash";

const initialState = {
  sortTradeBookArr: [],
  tradeBookList: undefined,
  tradeBookLoading: false,
  tradeSameIpLoading: false,
  tradeSameIpSearchText: '',
  tradeSameIpList: [],
  topGainersAndLosersData: [],
  topGainersAndLosersLoading: false,
  tradeBookSearchText: "",
  currentPageTradeBook: 1,
  pageSizeTradeBook: 10,
  totalRecordTradeBook: 0,
  totalRecordTradeSameIp: 0,
  startApiCallTradeBook: true,
  isTradeBookUpdated: false,
  filterStartDate: new Date(),
  filterEndDate: new Date(),
  sameIpFilterStartDate: new Date(),
  sameIpFilterEndDate: new Date(),
  tradeBookFilterValue: {
    startDate: new Date(),
    endDate: new Date(),
  },
  tradeSameIpFilterValue: {
    startDate: new Date(),
    endDate: new Date(),
  },
  isTradeBookGet: false,
  isTradeSameIdGet: false,
};

export const getTradeBookList = createAsyncThunk(
  "trade_book/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId, roles } = getSessionData();
      let {
        searchText,
        pageNo,
        pageSize,
        appendData,
        buyOrSell,
        client,
        segment,
        subBrokerId,
        symbol,
        endDate,
        startDate,
        partnerId,
        maintenanceType,
        authorityId,
      } = data;
      let finalObject = {};
      finalObject.userId = data.userId ? data.userId : userId;
      finalObject.pageNo = pageNo;
      finalObject.pageSize = pageSize;
      finalObject.segmentId =
        segment && Object.keys(segment).length > 0 ? segment.value : null;
      finalObject.subBrokerId =
        subBrokerId && Object.keys(subBrokerId).length > 0
          ? subBrokerId.value
          : null;
      finalObject.partnerId =
        partnerId && Object.keys(partnerId).length > 0 ? partnerId.value : null;
      finalObject.authorityId =
        authorityId && Object.keys(authorityId).length > 0
          ? authorityId.value
          : null;
      finalObject.clientId =
        client && Object.keys(client).length > 0
          ? client.value
          : roles[0] == "User"
            ? userId
            : null;
      finalObject.orderType =
        buyOrSell && Object.keys(buyOrSell).length > 0 ? buyOrSell.value : null;
      finalObject.startDate = moment(startDate).format("YYYY-MM-DD");
      finalObject.endDate = moment(endDate).format("YYYY-MM-DD");
      finalObject.symbol =
        symbol && Object.keys(symbol).length > 0 ? symbol.value : null;
      finalObject.searchText = searchText ? searchText : "";
      finalObject.excludeSB = data.isExcludeSB ? true : false;
      finalObject.tradeOption = maintenanceType ? maintenanceType.label : null;
      axios
        .post(`trade/get-trade-book`, finalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getTopGainersAndLosers = createAsyncThunk(
  "user/get-top-gainers-and-losers",
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      const { clientId, userTop, gainersorLosers } = props
      const userIdNew = clientId?.value ? clientId.value : userId
      axios
        .get(`reports/get-top-gainers-and-losers/${userIdNew}/${userTop}/${gainersorLosers}`)
        .then(({ data }) => {
          if (data.status === 200) {
            resolve({ data: data.data });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getTradeSameIpList = createAsyncThunk(
  "trade_book/get-trade-sameip-list",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let {
        searchText,
        pageNo,
        pageSize,
        client,
        segment,
        symbol,
        endDate,
        startDate,
        authority,
      } = data;
      let finalObject = {};
      finalObject.userId = data.userId ? data.userId : userId;
      finalObject.pageNo = pageNo;
      finalObject.pageSize = pageSize;
      finalObject.clientId =
        client && Object.keys(client).length > 0
          ? client.value
          : null;
      finalObject.segmentId =
        segment && Object.keys(segment).length > 0 ? segment.value : null;
      finalObject.authorityId =
        authority && Object.keys(authority).length > 0
          ? authority.value
          : null;
      finalObject.symbol =
        symbol && Object.keys(symbol).length > 0 ? symbol.value : null;
      finalObject.startDate = startDate
      finalObject.endDate = endDate
      finalObject.searchText = searchText ? searchText : "";
      axios
        .post(`trade/get-trades-for-same-ip`, finalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data?.data });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const exportTradeSameIpList = createAsyncThunk(
  "trade_book/export-trade-sameip-list",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let {
        searchText,
        pageNo,
        pageSize,
        client,
        segment,
        symbol,
        endDate,
        startDate,
        authority,
        isSubmit
      } = data;
      let finalObject = {};
      finalObject.userId = data.userId ? data.userId : userId;
      finalObject.pageNo = pageNo;
      finalObject.pageSize = pageSize;
      finalObject.clientId =
        client && Object.keys(client).length > 0
          ? client.value
          : null;
      finalObject.segmentId =
        segment && Object.keys(segment).length > 0 ? segment.value : null;
      finalObject.authorityId =
        authority && Object.keys(authority).length > 0
          ? authority.value
          : null;
      finalObject.symbol =
        symbol && Object.keys(symbol).length > 0 ? symbol.value : null;
      finalObject.startDate = startDate
      finalObject.endDate = endDate
      finalObject.searchText = searchText ? searchText : "";
      axios
        .post(`trade/get-trades-for-same-ip`, finalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            if (isSubmit == "PDF" && data.data.records.length > 0) {
              let aligh = { halign: "right", fontStyle: "bold" };
              let bgGray = { fillColor: [132, 132, 132], textColor: [255, 255, 255] };
              const calumnData = [
                {
                  title: "Trade No",
                  dataKey: "tradeNumber",
                },
                {
                  title: "Device",
                  dataKey: "deviceTypeName",
                  styles: aligh,
                },
                {
                  title: "O/T",
                  dataKey: "tradeTypeName",
                  styles: aligh,
                },
                {
                  title: "Trade Date",
                  dataKey: "tradeDate",
                  styles: aligh,
                },
                {
                  title: "Client",
                  dataKey: "client",
                  styles: aligh,
                },
                {
                  title: "Authority",
                  dataKey: "subBroker",
                  styles: aligh,
                },
                {
                  title: "Script",
                  dataKey: "script",
                  styles: aligh,
                },
                {
                  title: "Type",
                  dataKey: "orderTypeName",
                  styles: aligh,
                },
                {
                  title: "Lot",
                  dataKey: "lot",
                  styles: aligh,
                },
                {
                  title: "Qty",
                  dataKey: "quantity",
                  styles: aligh,
                },
                {
                  title: "BID/ASK",
                  dataKey: "biD_ASK",
                  styles: aligh,
                },
                {
                  title: "Rate",
                  dataKey: "rate",
                  styles: aligh,
                },
                {
                  title: "Trade Type",
                  dataKey: "tradeOptionName",
                  styles: aligh,
                },
                {
                  title: "User Ip",
                  dataKey: "userIp",
                  styles: aligh,
                },
                {
                  title: "Add Time",
                  dataKey: "addTime",
                  styles: aligh,
                },
              ];
              let exportData = {
                title: "Same Ip Trade",
                FileName: "Same Ip Trade",
                exportColumn: calumnData,
                arrayName: data.data.records,
                theme: "grid",
                headStyles: {
                  bgGray,
                },
                date: moment().format("DD-MM-YYYY"),
                reportType: "SameIpTrade",
              };
              generatePDF(exportData);
            }
            else if (isSubmit == "EXCEL" && data.data.records.length > 0) {
              let finalData =
                data.data &&
                data.data.records.map(
                  (item) => {
                    return {
                      order_no: item.id,
                      trade_no: item.tradeNumber,
                      segment: item.segmentName,
                      device_type: item.deviceTypeName,
                      client_code: item.client.split("-")[0],
                      client_name: item.client.split("-")[1],
                      authority: item.subBroker,
                      script_name: item.script,
                      ex_date: moment(item.expiryDate).format("DD-MMM-YYYY"),
                      buysell: item.orderTypeName,
                      lot: item.lot,
                      qty: item.quantity,
                      bid_ask: item.biD_ASK,
                      rate: item.rate,
                      trade_date: moment(item.tradeDate).format("DD-MMM-YYYY"),
                      trade_time: item.tradeTime,
                      userIp: item.userIp,
                      trade_type_name: item.tradeTypeName,
                      trade_type: item.tradeOptionName,
                    };
                  }
                );
              exportToExcel({
                apiData: finalData,
                fileName: "SameIpTrade",
              });
              resolve({ data: data.data });
            }
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const exportTradeBook = createAsyncThunk(
  "exportPDf",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId, roles } = getSessionData();
      let {
        searchText,
        pageNo,
        pageSize,
        appendData,
        buyOrSell,
        client,
        segment,
        subBrokerId,
        symbol,
        endDate,
        startDate,
      } = data;
      let finalObject = {};
      finalObject.userId = userId;
      finalObject.pageNo = pageNo;
      finalObject.pageSize = pageSize;
      finalObject.segmentId =
        segment && Object.keys(segment).length > 0 ? segment.value : null;
      finalObject.subBrokerId =
        subBrokerId && Object.keys(subBrokerId).length > 0
          ? subBrokerId.value
          : null;
      finalObject.clientId =
        client && Object.keys(client).length > 0
          ? client.value
          : roles[0] == "User"
            ? userId
            : null;
      finalObject.orderType =
        buyOrSell && Object.keys(buyOrSell).length > 0 ? buyOrSell.value : null;
      finalObject.startDate = moment(startDate).format("YYYY-MM-DD");
      finalObject.endDate = moment(endDate).format("YYYY-MM-DD");
      finalObject.symbol =
        symbol && Object.keys(symbol).length > 0 ? symbol.value : null;
      finalObject.searchText = searchText ? searchText : "";

      axios
        .post(`trade/get-trade-book`, finalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            let finalData =
              data.data &&
              data.data.records.map(
                (item) => {
                  return {
                    order_no: item.id,
                    trade_no: item.tradeNumber,
                    segment: item.segmentName,
                    device_type: item.deviceTypeName,
                    client_code: item.client.split("-")[0],
                    client_name: item.client.split("-")[1],
                    authority: item.subBroker,
                    script_name: item.script,
                    ex_date: moment(item.expiryDate).format("DD-MMM-YYYY"),
                    buysell: item.orderTypeName,
                    lot: item.lot,
                    qty: item.quantity,
                    rate: item.rate,
                    net_rate: item.netRate,
                    trade_date: moment(item.tradeDate).format("DD-MMM-YYYY"),
                    trade_time: item.tradeTime,
                    userIp: item.userIp,
                    trade_type_name: item.tradeTypeName,
                    trade_type: item.tradeOptionName,
                    user: item.accountTypeName
                  };
                }
              );
            exportToExcel({
              apiData: finalData,
              fileName: "Tradebook",
            });
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const tradeBookSlice = createSlice({
  name: "tradeBook",
  initialState,
  reducers: {
    setSortTradeBook: (state, action) => {
      state.sortTradeBookArr = action.payload;
    },
    setTradeBookSearchText: (state, action) => {
      state.tradeBookSearchText = action.payload;
    },
    setTradeSameIpSearchText: (state, action) => {
      state.tradeSameIpSearchText = action.payload;
    },
    setStartPageTradeBook: (state, action) => {
      state.currentPageTradeBook = action.payload;
    },
    setFilterStartDate: (state, action) => {
      state.filterStartDate = action.payload;
    },
    setSameIpFilterStartDate: (state, action) => {
      state.sameIpFilterStartDate = action.payload;
    },
    setSameIpFilterEndDate: (state, action) => {
      state.sameIpFilterEndDate = action.payload;
    },
    setTotalRecordTradeSameIp: (state, action) => {
      state.totalRecordTradeSameIp = action.payload;
    },
    setFilterEndDate: (state, action) => {
      state.filterEndDate = action.payload;
    },
    setTradeBookList: (state, action) => {
      state.tradeBookList = action.payload;
    },
    setTradeBookFilterValue: (state, action) => {
      state.tradeBookFilterValue = action.payload;
    },
    setTradeSameIpFilterValue: (state, action) => {
      state.tradeSameIpFilterValue = action.payload;
    },
    setTradeSameIpList: (state, action) => {
      state.tradeSameIpList = action.payload;
    },
    setTopGainersAndLosersData: (state, action) => {
      state.topGainersAndLosersData = action.payload;
    },
    setTotalRecordTradeBook: (state, action) => {
      state.totalRecordTradeBook = action.payload;
    },
    setIsTradeBookGet: (state, action) => {
      state.isTradeBookGet = action.payload;
    },
    setIsTradeSameIdGet: (state, action) => {
      state.isTradeSameIdGet = action.payload;
    },
  },
  extraReducers: {
    [getTradeBookList.pending]: (state, action) => {
      state.tradeBookLoading = true;
    },
    [getTradeBookList.rejected]: (state, action) => {
      state.tradeBookList = [];
      state.tradeBookLoading = false;
    },
    [getTradeBookList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        let tradeBookListData = [
          ...state.tradeBookList || [],
          ...action.payload.data.records,
        ];
        state.tradeBookList = tradeBookListData.map(item => { return { ...item, isSelect: false } })
      } else {
        let tradeBookListData = action.payload.data.records;
        state.tradeBookList = tradeBookListData.map(item => { return { ...item, isSelect: false } });
      }
      state.totalRecordTradeBook = action.payload.data.totalRecords;
      state.startApiCallTradeBook = false;
      state.tradeBookLoading = false;
    },
    [getTopGainersAndLosers.pending]: (state, action) => {
      state.topGainersAndLosersLoading = true;
    },
    [getTopGainersAndLosers.rejected]: (state, action) => {
      state.topGainersAndLosersData = [];
      state.topGainersAndLosersLoading = false;
    },
    [getTopGainersAndLosers.fulfilled]: (state, action) => {
      state.topGainersAndLosersData = action.payload.data || []
      state.topGainersAndLosersLoading = false
    },
    [getTradeSameIpList.pending]: (state, action) => {
      state.tradeSameIpLoading = true;
    },
    [getTradeSameIpList.rejected]: (state, action) => {
      state.tradeSameIpList = [];
      state.tradeSameIpLoading = false;
    },
    [getTradeSameIpList.fulfilled]: (state, action) => {
      state.tradeSameIpList = action.payload.data?.records || []
      state.totalRecordTradeSameIp = action.payload.data?.totalRecords || 0
      state.tradeSameIpLoading = false
    },
  },
});

export const {
  setSortOrderBook,
  setTradeBookSearchText,
  setStartPageTradeBook,
  setFilterStartDate,
  setFilterEndDate,
  setTradeBookList,
  setTradeBookFilterValue,
  setTradeSameIpFilterValue,
  setIsTradeSameIdGet,
  setTradeSameIpSearchText,
  setSameIpFilterEndDate,
  setSameIpFilterStartDate,
  setTotalRecordTradeBook,
  setTotalRecordTradeSameIp,
  setTopGainersAndLosersData,
  setIsTradeBookGet,
  setTradeSameIpList,
} = tradeBookSlice.actions;

export default tradeBookSlice.reducer;
