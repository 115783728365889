import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showMessage } from "../common.slice";
import { getSessionData } from "utils/common";

const initialState = {
  cPanelSettingList: [],
  isCPanelSettingUpdated: false,
  cPanelLoading: false,
  startApiCallCPanelSettingList: false,
  cPanelGeneralLoading: false,
  startApiCallCPanelGeneralSettingList: false,
  cPanelGeneralSettingList: [],
  isCPanelGeneralSettingUpdated: false,
  cPanelNotificationLoading: false,
  cPanelNotificationSettingList: [],
  isCPanelNotificationUpdated: false,
  cPanelNotificationEditDeleteData: "",
  totalRecordNotification: 0,
  currentPageNotification: 1,
  selectedIdInNotification: {},
  selectedNotificationDate: new Date(),
  notificationFilterValue: {},
  cPanelNotificationIdList: {},
};

export const getCPanelSettingList = createAsyncThunk(
  "/setting/CPanel/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`settings/cpanel/get-segment-wise-setting-cpanel`)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getCPanelGeneralSettingList = createAsyncThunk(
  "/setting/CPanel/general/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`settings/cpanel/get-general-setting-cpanel`)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getCPanelNotificationSettingList = createAsyncThunk(
  "/setting/CPanel/notification/get",
  (data, { dispatch }) => {
    let { pageNo, pageSize, searchText, appendData } = data;
    return new Promise((resolve, reject) => {
      axios
        .get(
          `settings/cpanel/get-notification-setting-cpanel/${pageNo}/${pageSize}${searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then((resdata) => {
          if (resdata.data.status == 200) {
            resolve({ data: resdata.data.data, appendData });
          } else {
            reject(resdata);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getCPanelNotificationSettingId = createAsyncThunk(
  "/setting/CPanel/notification-id/get",
  (data, { dispatch }) => {
    let { id } = data;
    return new Promise((resolve, reject) => {
      axios
        .get(`settings/cpanel/get-notification-setting-cpanel-by-id/${id}`)
        .then((resdata) => {
          if (resdata.data.status == 200) {
            resolve({ data: resdata.data.data });
          } else {
            reject(resdata);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const addCPanelNotification = createAsyncThunk(
  "/setting/cpanel-notification/add",
  (paramData, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.id = paramData.id;
      newFinalObject.createdBy = userId;
      newFinalObject.notificationDate = paramData.date;
      newFinalObject.notificationText = paramData.text;
      axios
        .post(
          `settings/cpanel/save-notification-setting-cPanel`,
          newFinalObject
        )
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const deleteCPanelNotification = createAsyncThunk(
  "/setting/cpanel-notification/delete",
  (data, { dispatch }) => {
    let { id } = data;
    return new Promise((resolve, reject) => {
      axios
        .delete(`settings/cpanel/delete-notification-setting-cPanel/${id}`)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const editCPanelSetting = createAsyncThunk(
  "/setting/block-scripts/add",
  (paramData, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.id = paramData.id;
      newFinalObject.createdBy = userId;
      newFinalObject.lotQtyReq = paramData.lotQtyReq;
      newFinalObject.isActive = paramData.isActive;
      newFinalObject.startTime = paramData.startTime;
      newFinalObject.endTime = paramData.endTime;
      newFinalObject.scriptPlusEX = paramData.scriptPlusEX;
      newFinalObject.isOrdersAllow = paramData.isOrdersAllow;
      axios
        .post(
          `settings/cpanel/save-segment-wise-setting-cPanel`,
          newFinalObject
        )
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const editCPanelGeneralSetting = createAsyncThunk(
  "/setting/genral-cpanel/add",
  (paramData, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.id = paramData.id;
      newFinalObject.createdBy = userId;
      newFinalObject.srNo = paramData.srNo;
      newFinalObject.field = paramData.field;
      newFinalObject.value = paramData.value;
      newFinalObject.possibleValue = paramData.possibleValue;
      axios
        .post(`settings/cpanel/save-general-setting-cPanel`, newFinalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const settingCpanelSlice = createSlice({
  name: "setting/CpanelSlice",
  initialState,
  reducers: {
    setIsCPanelSettingUpdated: (state, action) => {
      state.isCPanelSettingUpdated = action.payload;
    },
    setStartApiCallCPanelSettingList: (state, action) => {
      state.startApiCallCPanelSettingList = action.payload;
    },
    setCPanelSettingList: (state, action) => {
      state.cPanelSettingList = action.payload;
    },
    setIsCPanelNotificationUpdated: (state, action) => {
      state.isCPanelNotificationUpdated = action.payload;
    },
    setCPanelNotificationEditDeleteData: (state, action) => {
      state.cPanelNotificationEditDeleteData = action.payload;
    },
    setStartPageNotification: (state, action) => {
      state.currentPageNotification = action.payload;
    },
    setCPanelNotificationSettingList: (state, action) => {
      state.cPanelNotificationSettingList = action.payload;
    },
    setSelectedIdInNotification: (state, action) => {
      state.selectedIdInNotification = action.payload;
    },
    setSelectedNotificationDate: (state, action) => {
      state.selectedNotificationDate = action.payload;
    },
    setNotificationFilterValue: (state, action) => {
      state.notificationFilterValue = action.payload;
    },
  },
  extraReducers: {
    [editCPanelSetting.pending]: (state, action) => {
      state.isCPanelSettingUpdated = false;
    },
    [editCPanelSetting.rejected]: (state, action) => {
      state.isCPanelSettingUpdated = false;
    },
    [editCPanelSetting.fulfilled]: (state, action) => {
      state.isCPanelSettingUpdated = true;
    },

    [getCPanelSettingList.pending]: (state, action) => {
      state.cPanelLoading = true;
      state.startApiCallCPanelSettingList = false;
    },
    [getCPanelSettingList.rejected]: (state, action) => {
      state.cPanelSettingList = [];
      state.cPanelLoading = false;
      state.startApiCallCPanelSettingList = false;
    },
    [getCPanelSettingList.fulfilled]: (state, action) => {
      state.cPanelSettingList = action.payload.data;
      state.cPanelLoading = false;
      state.startApiCallCPanelSettingList = false;
    },
    [getCPanelGeneralSettingList.pending]: (state, action) => {
      state.cPanelGeneralLoading = true;
      state.startApiCallCPanelGeneralSettingList = false;
    },
    [getCPanelGeneralSettingList.rejected]: (state, action) => {
      state.cPanelGeneralSettingList = [];
      state.cPanelGeneralLoading = false;
      state.startApiCallCPanelGeneralSettingList = false;
    },
    [getCPanelGeneralSettingList.fulfilled]: (state, action) => {
      state.cPanelGeneralSettingList = action.payload.data;
      state.cPanelGeneralLoading = false;
      state.startApiCallCPanelGeneralSettingList = false;
    },
    [editCPanelGeneralSetting.pending]: (state, action) => {
      state.isCPanelGeneralSettingUpdated = false;
    },
    [editCPanelGeneralSetting.rejected]: (state, action) => {
      state.isCPanelGeneralSettingUpdated = false;
    },
    [editCPanelGeneralSetting.fulfilled]: (state, action) => {
      state.isCPanelGeneralSettingUpdated = true;
    },
    [getCPanelNotificationSettingList.pending]: (state, action) => {
      state.cPanelNotificationSettingList = [];
      state.cPanelNotificationLoading = true;
    },
    [getCPanelNotificationSettingList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.cPanelNotificationSettingList = [
          ...state.cPanelNotificationSettingList,
          action.payload.data.item2,
        ];
      } else {
        state.cPanelNotificationSettingList = action.payload.data.item2;
      }
      state.totalRecordNotification = action.payload.data.item1;
      state.cPanelNotificationLoading = false;
      // state.cPanelNotificationSettingList = action.payload.data;
    },
    [getCPanelNotificationSettingList.rejected]: (state, action) => {
      state.cPanelNotificationSettingList = [];
      state.cPanelNotificationLoading = false;
    },
    [getCPanelNotificationSettingId.pending]: (state, action) => {
      state.cPanelNotificationIdList = {};
    },
    [getCPanelNotificationSettingId.rejected]: (state, action) => {
      state.cPanelNotificationIdList = {};
    },
    [getCPanelNotificationSettingId.fulfilled]: (state, action) => {
      state.cPanelNotificationIdList = action.payload.data;
    },
    [addCPanelNotification.pending]: (state, action) => {
      state.isCPanelNotificationUpdated = false;
    },
    [addCPanelNotification.rejected]: (state, action) => {
      state.isCPanelNotificationUpdated = false;
    },
    [addCPanelNotification.fulfilled]: (state, action) => {
      state.isCPanelNotificationUpdated = true;
    },
    [deleteCPanelNotification.pending]: (state, action) => {
      state.isCPanelNotificationUpdated = false;
    },
    [deleteCPanelNotification.fulfilled]: (state, action) => {
      state.isCPanelNotificationUpdated = true;
    },
    [deleteCPanelNotification.rejected]: (state, action) => {
      state.isCPanelNotificationUpdated = false;
    },
  },
});

export const {
  setCPanelSettingList,
  setIsCPanelSettingUpdated,
  setStartApiCallCPanelSettingList,
  setIsCPanelNotificationUpdated,
  setCPanelNotificationEditDeleteData,
  setStartPageNotification,
  setCPanelNotificationSettingList,
  setSelectedIdInNotification,
  setSelectedNotificationDate,
  setNotificationFilterValue,
} = settingCpanelSlice.actions;

export default settingCpanelSlice.reducer;
