import _ from "lodash";
export const getSessionData = () => {
  const sessionData = sessionStorage.getItem("TradeSession");
  if (sessionData) {
    return JSON.parse(window.atob(sessionData));
  } else {
    window.location.reload();
  }
};
export const getSessionDataOnlyRead = () => {
  const sessionData = sessionStorage.getItem("TradeSession");
  if (sessionData) {
    return JSON.parse(window.atob(sessionData));
  } else {
    return null;
  }
};
export const getDeviceOnlyRead = () => {
  const sessionData = sessionStorage.getItem("DeviceType");
  if (sessionData) {
    return JSON.parse(window.atob(sessionData));
  } else {
    return null;
  }
};

export const getAuthorityData = () => {
  let authorityData = sessionStorage.getItem("TradeAuthoritySession");
  if (authorityData) {
    return JSON.parse(authorityData);
  } else {
    return null;
  }
};

export const inWords = (num) => {
  let a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  let b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  if ((num = num.toString()).length > 9) return "";
  let n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
      (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
      "only "
      : "";
  return str;
};

export const checkIsEmpty = (data) => {
  return _.isEmpty(data);
};

export const symbolWithBind = (data) => {
  if (!checkIsEmpty(data)) {
    return [{ label: "ALL", value: "ALL" }, ...data];
  } else {
    return [];
  }
};

/**
 * @desc get query param by name
 */
export const getUrlParam = (query, name) => {
  let queryParams = new URLSearchParams();
  if (query) queryParams = new URLSearchParams(query);
  return queryParams.get(name);
};

/**
 * @desc get device type - mobile/ tablet/ browser
 */
export const getDeviceType = () => {
  if (
    /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) return "mobile";
  else if (/iPad|iPod/i.test(navigator.userAgent)) return "tablet";
  else return "browser";
};

/**
 * @desc get rounded number as per desired digit
 */
export function mathRound(number, digit = 3) {
  try {
    if (Number(number) < 1) digit = 3;
    if (number) return Number(_.round(Number(number), digit));
  } catch (e) { }
  return Number(Number(0).toFixed(digit));
}

export const getIstTime = () => {
  var currentTime = new Date();
  var currentOffset = currentTime.getTimezoneOffset();

  var ISTOffset = 330; // IST offset UTC +5:30
  var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);

  // ISTTime now represents the time in IST coordinates
  return ISTTime;
};