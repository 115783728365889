import React, { useRef, useEffect, useMemo } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import videoBg from '../assets/img/video-bg.mp4'

import { useHistory } from "react-router-dom";
import { setIsLogout } from "store/reducers/auth.slice";

const UnProtectedRoute = ({ component: Component, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const { isUserAuthenticated, isLogOut, isUserLogin, rulesAccepted, isShowChangePassword, userRole, device } = useSelector(({ auth }) => auth);
  useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  const loggedInUrl = useMemo(() => {
    return sessionStorage.getItem("loggedInUrl");
  }, []);
  const mainContent = useRef(null);

  useEffect(() => {
    if (isLogOut && isUserLogin) {
      if (loggedInUrl) history.push(loggedInUrl);
      else history.push("/admin");
      dispatch(setIsLogout(false))
    }
  }, [isLogOut]);
  return (
    <>
      <Route
        {...props}
        render={(props) => {
          if (!isUserAuthenticated) {
            return (
              props.location.pathname === '/profile/privacy-policy' ? <Component {...props} /> :
                <div className={window.location.pathname === "/app/admin" ? "login_wrapper app_login_wrapper" : "login_wrapper"} ref={mainContent}>
                  <video src={videoBg} width="100%" height="100%" controls={false} autoPlay={true} muted={true} loop={true}>
                  </video>
                  <Component {...props} />
                </div>

            );
          } else {
            if (isLogOut) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: "/",
                    },
                  }}
                />
              )
            } else if (isShowChangePassword && userRole === 'User') {
              return (
                <Redirect
                  to={{
                    pathname: "/profile/change-password",
                    state: {
                      from: "/profile/change-password",
                    },
                  }}
                />
              )
            } else if (!rulesAccepted && userRole === 'User') {
              return (
                <Redirect
                  to={{
                    pathname: "/profile/rules",
                    state: {
                      from: "/profile/rules",
                    },
                  }}
                />
              )
            } else {
              return (
                <Redirect
                  to={{
                    pathname:
                      props.location &&
                        props.location.state &&
                        props.location.state.from &&
                        props.location.state.from.pathname
                        ? props.location.state.from.pathname
                        : "/",
                    search:
                      props.location &&
                        props.location.state &&
                        props.location.state.from &&
                        props.location.state.from.search
                        ? props.location.state.from.search
                        : "",
                    state: {
                      from:
                        props.location &&
                          props.location.state &&
                          props.location.state.from &&
                          props.location.state.from.pathname
                          ? props.location.state.from.pathname
                          : "/",
                      /* props.location.state.from.state
                      ? props.location.state.from.state.from
                      : "/", */
                    },
                  }}
                />
              )
            }
          }
        }}
      />
    </>
  );
};

export default UnProtectedRoute;
