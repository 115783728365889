import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  Nav,
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Button,
} from "reactstrap";
import configAppConst from "appConfigurations";
import { Scrollbars } from "react-custom-scrollbars";
import { setIsNavbarHidden, getNotificationList } from "store/reducers/common.slice";
import { checkIsEmpty } from "utils/common";
import { setIsOrderBookGet } from "store/reducers/orderbook.slice";
import { setIsTradeBookGet } from "store/reducers/tradeBook.slice";
import {
  setIsPositionGet,
  setIsPositionGet2,
  setPositionReportFilterValue,
  setPositionReportFilterValue2
} from "store/reducers/positionReport.slice";
import { setIsQuantityGet } from "store/reducers/settingsSlice/setting.slice";
import { setIsSettingOptionStrikeGet } from "store/reducers/settingsSlice/settingOptionStrikeValidation.slice";
import { setIsSettingSymbolMaxValueGet } from "store/reducers/settingsSlice/settingSymbolMaxValue.slice";
import { setIsSettingClientSymbolGet } from "store/reducers/settingsSlice/settingClientSegment.slice";
import { setIsSettingClientOrderLimitGet } from "store/reducers/settingsSlice/settingClientOrderLimit.slice";
import { setHomeDashboardList } from "store/reducers/home.slice";
import { partnerAuthority } from "configs/routesConfig";
import { userAuthority } from "configs/routesConfig";
import { getSessionDataOnlyRead } from "utils/common";
import Favicon from "../../assets/img/favicon.png";
import { setIsFilterRejectionLogData, setIsReportGet, setIsFilterDataUpdateLog, setOTDReportLogList } from "store/reducers/report.slice";
import { subBrokerAuthority } from "configs/routesConfig";
import { socket } from "App";
import { setActiveUser } from "store/reducers/adminSlice/admin.slice";
import { setRulesAccepted, setIsShowChangePassword, logOut, setUserDevice } from "store/reducers/auth.slice";
import { TimerAndDate } from "./TimerAndDatePage";
import { instantChangeActiveStatus } from "store/reducers/master.slice";

const NavTab = ({ userRole }) => {
  const location = useHistory();
  let originalPath = location.location.pathname;
  const locationPath = originalPath.split("/");
  const dispatch = useDispatch();
  const { rightsSessionList } = useSelector(
    ({ common }) => common
  );
  const [state, setState] = useState({
    tabs: [
      {
        name: locationPath.length > 2 ? locationPath[2] : locationPath[1],
        largePath: locationPath.length > 2 ? originalPath : null,
      },
    ],
    currentTab: {
      name: locationPath.length > 2 ? locationPath[2] : locationPath[1],
      largePath: locationPath.length > 2 ? originalPath : null,
    },
  });

  const handleDeleteTab = (e, tabToDelete) => {
    e.preventDefault();
    e.stopPropagation();
    const { tabs, currentTab } = state;
    if (currentTab.largePath === "/reports/log-report") {
      dispatch(setOTDReportLogList([]));
      dispatch(setIsFilterDataUpdateLog(false));
    } else if (currentTab.largePath === "/reports/rejection-log") {
      dispatch(setIsFilterRejectionLogData(false));
    } else if (currentTab.largePath === "/order-book") {
      dispatch(setIsOrderBookGet(false));
    } else if (currentTab.largePath === "/trade-book") {
      dispatch(setIsTradeBookGet(false));
    } else if (currentTab.largePath === "/position-report") {
      dispatch(setIsPositionGet(false));
      userRole !== 'User' && dispatch(setIsPositionGet2(false));
    } else if (currentTab.largePath === "/position-report2") {
      dispatch(setIsPositionGet(false));
      userRole !== 'User' && dispatch(setIsPositionGet2(false));
    } else if (currentTab.largePath === "/reports/trade-report") {
      dispatch(setIsReportGet(false));
    }
    if (tabs.length <= 1) {
      if (currentTab.name !== "home") {
        setState({
          tabs: [{ name: "", largePath: null }],
          currentTab: { name: "", largePath: null },
        });
        location.push("/");
      }
    } else {
      const tabToDeleteIndex = tabs.findIndex(
        (tab) => tab.name === tabToDelete.name
      );
      const updatedTabs = tabs.filter((tab, index) => {
        return index !== tabToDeleteIndex;
      });
      const previousTab =
        tabs[tabToDeleteIndex - 1] || tabs[tabToDeleteIndex + 1] || {};
      if (currentTab.name == tabToDelete.name) {
        setState({
          tabs: updatedTabs,
          currentTab: previousTab,
        });
        if (previousTab.largePath !== null) {
          location.push(previousTab.largePath);
        } else {
          location.push(`/${previousTab.name}`);
        }
      } else {
        setState({
          ...state,
          tabs: updatedTabs,
        });
        if (currentTab.largePath !== null) {
          location.push(currentTab.largePath);
        } else {
          location.push(`/${currentTab.name}`);
        }
      }
    }
  };
  const createTabs = () => {
    const { tabs, currentTab } = state;
    let tabView = [];
    let allUserAuthority = [...userAuthority];
    allUserAuthority = allUserAuthority.map((item) => item.replace("/", ""));
    let allPartnerAuthority = [...partnerAuthority];
    allPartnerAuthority = allPartnerAuthority.map((item) =>
      item.replace("/", "")
    );
    let allSubBrockerAuthority = [...subBrokerAuthority];
    allSubBrockerAuthority = allSubBrockerAuthority.map((item) =>
      item.replace("/", "")
    );
    if (userRole === "User") {
      tabs.map((item) => {
        let obj = allUserAuthority.includes(item.name);
        if (obj) {
          tabView.push(item);
        }
      });
    } else if (userRole === "Partner") {
      tabs.map((item) => {
        let obj = allPartnerAuthority.includes(item.name);
        if (obj) {
          tabView.push(item);
        }
      });
    } else if (userRole === "Sub Broker") {
      tabs.map((item) => {
        let obj = allSubBrockerAuthority.includes(item.name);
        if (obj) {
          tabView.push(item);
        }
      });
    } else {
      tabs.map((item) => {
        let obj = rightsSessionList.find((item2) =>
          item2.routeName.includes(item.name)
        );
        if ((item.largePath === '/settings/script-management' || item.largePath === '/transaction/upload-bhavCopy-data')
          && userRole === "Super Admin") {
          tabView.push(item);
        }
        if ((item.name === 'position-report2') && userRole !== 'User') {
          tabView.push(item);
        }
        if (obj) {
          let isVisible = obj.isViewAllow;
          if (isVisible) {
            tabView.push(item);
          }
        }
      });
    }
    const allTabs =
      !checkIsEmpty(tabView) &&
      tabView.map((tab, i) => {
        return (
          <NavItem key={i}>
            <NavLink
              className={`${classnames({
                active:
                  currentTab.name === ""
                    ? currentTab.name == tab.name
                    : currentTab.name == tab.name,
              })} ${currentTab.name == tab.name ? "" : "text-dark "}`}
              onClick={(e) => handleSelectTab(e, tab)}
            >
              <span className="d-inline-flex align-items-center">
                <span className="text-capitalize">
                  {!checkIsEmpty(tab) && tab.name === ""
                    ? "Home"
                    : tab.name && String(tab.name).replaceAll("-", " ")}
                </span>
                <span
                  className="ml-2 position-relative d-block"
                  onClick={(e) => handleDeleteTab(e, tab)}
                >
                  <i className="material-icons-outlined align-middle spinreverse">
                    close
                  </i>
                </span>
              </span>
            </NavLink>
          </NavItem>
        );
      });
    return (
      <Nav pills className={"gy-2 nav-scrollable"}>
        {allTabs}
      </Nav>
    );
  };

  const handleSelectTab = (e, tab) => {
    e.preventDefault();
    e.stopPropagation();

    setState({
      ...state,
      currentTab: tab,
    });
    if (tab.largePath !== null) {
      location.push(tab.largePath);
    } else {
      location.push(`/${tab.name}`);
    }
  };

  const handleAddTab = () => {
    let { tabs } = state;
    const newTabObject = {
      name: locationPath.length > 2 ? locationPath[2] : locationPath[1],
      content: `This is Tab ${tabs.length + 1}`,
      largePath: locationPath.length > 2 ? originalPath : null,
    };
    if (tabs[0].name == "login") {
      setState({
        tabs: [newTabObject],
        currentTab: newTabObject,
      });
    } else {
      if (state.tabs.length <= 4) {
        setState({
          tabs: [...tabs, newTabObject],
          currentTab: newTabObject,
        });
      } else {
        tabs.splice(0, 1);
        setState({
          tabs: [...tabs, newTabObject],
          currentTab: newTabObject,
        });
      }
    }
  };

  useEffect(() => {
    if (
      state.tabs.filter(
        (item) =>
          item.name ==
          (item.largePath !== null ? locationPath[2] : locationPath[1])
      ).length <= 0
    ) {
      handleAddTab();
    } else {
      let selectBySidebare = {
        name: locationPath.length > 2 ? locationPath[2] : locationPath[1],
        largePath: locationPath.length > 2 ? originalPath : null,
      };
      setState({ ...state, currentTab: selectBySidebare });
    }
    dispatch(setIsNavbarHidden(true));
  }, [originalPath]);
  return <>{createTabs()}</>;
};

const AdminNavbar = (props) => {
  const { userIp, userRole, isMasterRole } = useSelector(({ auth }) => auth);
  const { activeUser } = useSelector(({ admin }) => admin);
  const dispatch = useDispatch();
  const [channelType, setChannelType] = useState("");
  let storeUserName = getSessionDataOnlyRead();
  const { isNavbarHidden, notificationList } = useSelector(
    ({ common }) => common
  );
  const noOfActiveUser = useMemo(() => activeUser, [activeUser])
  const location = useLocation();
  useEffect(() => {
    dispatch(getNotificationList());
    if (userRole === "Super Admin") {
      socket.on('login_user', (data) => {
        dispatch(setActiveUser(data))
      });
    }
  }, [])

  const handleLogOut = async () => {
    await dispatch(setIsOrderBookGet(false));
    await dispatch(setIsTradeBookGet(false));
    await dispatch(setIsPositionGet(false));
    await dispatch(setIsPositionGet2(false));
    await dispatch(setPositionReportFilterValue({}));
    await dispatch(setPositionReportFilterValue2({}));
    await dispatch(setIsQuantityGet(false));
    await dispatch(setIsSettingOptionStrikeGet(false));
    await dispatch(setIsSettingSymbolMaxValueGet(false));
    await dispatch(setIsSettingClientSymbolGet(false));
    await dispatch(setIsSettingClientOrderLimitGet(false));
    await dispatch(setIsFilterRejectionLogData(false));
    await dispatch(setIsFilterDataUpdateLog(false));
    await dispatch(setHomeDashboardList([]));
    await dispatch(logOut({ userIp }));
    await dispatch(setUserDevice(""));
    await dispatch(setRulesAccepted(true))
    await dispatch(setIsShowChangePassword(false))
  };
  const getActiveUser = useCallback(
    () => {
      return noOfActiveUser
    },
    [noOfActiveUser]
  )
  return (
    <div
      className={`container-fluid pt-3 pb-2 shadow top-header ${isNavbarHidden ? "nav-close" : ""
        }`}
    >
      <div className="row g-md-4 flex-lg-nowrap align-items-center mb-2">
        <div className="col-6">
          <div className="left_header d-flex align-items-center">
            <div className="navbar-light pr-3">
              <button
                className="navbar-toggler d-none d-md-block p-0 ml-auto"
                type="button"
                onClick={() => dispatch(setIsNavbarHidden(!isNavbarHidden))}
              >
                <i className="material-icons-outlined">menu</i>
              </button>
            </div>
            <Scrollbars
              className="w-100 page_title_wrap"
              autoHeight
              autoHide
              style={{ width: "1200px" }}
            >
              <NavTab userRole={userRole && userRole} />
            </Scrollbars>
          </div>
        </div>
        <div className="col-6 d-none d-md-block">
          <Nav className="align-items-center justify-content-end header_nav">
            <UncontrolledDropdown>
              {userRole === "Super Admin" && <h3 className="text-white mr-2 mb-0 d-none d-lg-block">Active User: {getActiveUser()}</h3>}
            </UncontrolledDropdown>
            {userRole !== "User" && <UncontrolledDropdown nav className="mb-0 mt-0">
              <Button className="btn-danger mr-2 contact_upline_btn" onClick={async () => {
                await dispatch(instantChangeActiveStatus())
                await handleLogOut()
                window.open("about:blank", "_self");
                window.close();
              }}>
                Contact Upline
              </Button>
            </UncontrolledDropdown>}
            <TimerAndDate />
            <UncontrolledDropdown nav className="mb-0 mt-0 tv_dropdown_wrapper">
              <DropdownToggle
                nav
                className="nav-link-icon position-relative tv_dropdown"
              >
                <span className="material-symbols-outlined">desktop_windows</span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => setChannelType("english")}>
                  English Channel
                </DropdownItem>
                <DropdownItem onClick={() => setChannelType("hindi")}>
                  Hindi Channel
                </DropdownItem>
                <DropdownItem onClick={() => setChannelType("gujarati")}>
                  Gujarati Channel
                </DropdownItem>
              </DropdownMenu>
              <div
                className={channelType ? "tv_video_box show" : "tv_video_box"}
              >
                <div
                  className="video_close_icon"
                  onClick={() => setChannelType("")}
                >
                  <span className="material-symbols-outlined">close</span>
                </div>
                {channelType === "english" && (
                  <iframe
                    id="vidgyor_iframe"
                    frameborder="0"
                    src="https://www.youtube.com/embed/P857H4ej-MQ"
                    mozallowfullscreen=""
                    webkitallowfullscreen=""
                    scrolling="no"
                    height="150px"
                    width="300px"
                    allowFullScreen
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                )}
                {channelType === "hindi" && (
                  <iframe
                    id="vidgyor_iframe"
                    frameborder="0"
                    src="https://www.youtube.com/embed/TD0A7fHAxKw"
                    mozallowfullscreen=""
                    webkitallowfullscreen=""
                    scrolling="no"
                    height="150px"
                    width="300px"
                    allowFullScreen
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                )}
                {channelType === "gujarati" && (
                  <iframe
                    id="vidgyor_iframe"
                    frameborder="0"
                    src="https://www.youtube.com/embed/LziO-1JrvQo"
                    mozallowfullscreen=""
                    webkitallowfullscreen=""
                    scrolling="no"
                    height="150px"
                    width="300px"
                    allowFullScreen
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                )}
              </div>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <img
                src={Favicon}
                style={{ height: "38px", width: "38px" }}
                alt="favicon"
                className="header_golden_icon"
              />
            </UncontrolledDropdown>
            <UncontrolledDropdown nav className="mb-0 mt-0">
              <DropdownToggle
                nav
                className="nav-link-icon position-relative pr-0 user_dropdown"
              >
                <p className="m-0 font-weight-bold border-bottom-2">
                  {storeUserName?.userName}
                  <span>{userRole}</span>
                </p>
                <i className="material-icons-outlined">account_circle</i>
                {!isMasterRole && <span className="view_only_text">View Only</span>}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {isMasterRole && (
                  <DropdownItem
                    to="/profile/change-password"
                    active={location.pathname === "/profile/change-password"}
                    tag={Link}
                  >
                    <span>{configAppConst.changePassword}</span>
                  </DropdownItem>
                )}
                {isMasterRole &&
                  (userRole === "Super Admin" ||
                    userRole === "Admin" ||
                    userRole === "Super Master" ||
                    userRole === "Master") && (
                    <>
                      <DropdownItem
                        to="/admin/authority-rights"
                        active={location.pathname === "/admin/authority-rights"}
                        tag={Link}
                      >
                        <span>{configAppConst.authorityRights}</span>
                      </DropdownItem>
                      {userRole === "Super Admin" && (
                        <>
                          <DropdownItem
                            to="/admin/authority-limits"
                            active={
                              location.pathname === "/admin/authority-limits"
                            }
                            tag={Link}
                          >
                            <span>{configAppConst.authorityLimits}</span>
                          </DropdownItem>
                          <DropdownItem
                            to="/admin/client-map"
                            active={location.pathname === "/admin/client-map"}
                            tag={Link}
                          >
                            <span>{configAppConst.clientMap}</span>
                          </DropdownItem>
                        </>
                      )}
                    </>
                  )}
                {isMasterRole && userRole === "Master" && (
                  <DropdownItem
                    to="/admin/system-user-map"
                    active={location.pathname === "/admin/system-user-map"}
                    tag={Link}
                  >
                    <span>{configAppConst.systemUserMap}</span>
                  </DropdownItem>
                )}
                <DropdownItem
                  to="/profile/rules"
                  active={location.pathname === "/profile/rules"}
                  tag={Link}
                >
                  <span>{configAppConst.rules}</span>
                </DropdownItem>
                <DropdownItem
                  target="_blank"
                  to="/profile/privacy-policy"
                  active={location.pathname === "/profile/privacy-policy"}
                  tag={Link}
                >
                  <span>{configAppConst.privacyPolicy}</span>
                </DropdownItem>
                {(userRole === "Super Admin" || userRole === "Admin" || userRole === "Super Master" || userRole === "Master") && (
                  <DropdownItem
                    to="/profile/settings"
                    active={location.pathname === "/profile/settings"}
                    tag={Link}
                  >
                    <span>{configAppConst.settings}</span>
                  </DropdownItem>
                )}
                <DropdownItem onClick={() => handleLogOut()}>
                  <span>{configAppConst.logout}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </div>
      </div>
      <div className="marquee_wrapper">
        <marquee width="100%" direction="left">
          <h3 className="mb-0 text-white">
            {notificationList?.length > 0 &&
              notificationList?.map((item, index) => {
                if (++index === notificationList.length) {
                  return <span key={index} >{item.notificationText}</span>;
                } else if (notificationList.length > 1) {
                  return <span key={index} >{item.notificationText} || </span>;
                }
                return (
                  <span
                    key={index}
                    style={{
                      color: index % 2 === 1 ? "#0000ff" : "red",
                    }}
                  >
                    {item.notificationText}
                  </span>
                );
              })}
            {(notificationList === undefined ||
              notificationList?.length === 0) && (
                <h3 className="mb-0 text-white">
                  No real money involved in this ID (App). This is a Virtual Trading Application which has all the features to trade. This application is used for exchanging views on markets for individual students for training purpose only.
                </h3>
              )}
          </h3>
        </marquee>
      </div>
    </div>
  );
};

export default React.memo(AdminNavbar);
