import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { getSessionDataOnlyRead } from 'utils/common';

export default function MobileNewFooter() {
  const location = useLocation()
  let sessionData = getSessionDataOnlyRead();
  return (
    <div>
      <div className='mfooter_wrap'>
        <ul>
          <li>
            <Link to="/app" className={location.pathname === "/app" ? 'active' : ''}>
              <span className="material-symbols-outlined">
                bar_chart
              </span>
              Watchlist
            </Link>
          </li>
          <li>
            <Link to="/app/order-book" className={location.pathname === "/app/order-book" ? 'active' : ''}>
              <span className="material-symbols-outlined">
                history
              </span>
              Order
            </Link>
          </li>
          <li>
            <Link to="/app/trade-book" className={location.pathname === "/app/trade-book" ? 'active' : ''}>
              <span className="material-symbols-outlined">
                work
              </span>
              Trades
            </Link>
          </li>
          <li>
            <Link to="/app/position-report" className={location.pathname === "/app/position-report" ? 'active' : ''}>
              <span className="material-symbols-outlined">
                analytics
              </span>
              Positions
            </Link>
          </li>
          <li>
            <Link to="/app/profile" className={location.pathname === "/app/profile" ? 'active' : ''}>
              <span className="material-symbols-outlined">
                person
              </span>
              {sessionData?.userName}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
