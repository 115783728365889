import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showMessage } from "./common.slice";
import { getSessionData } from "utils/common";

const initialState = {
  isPartnerShipUpdated: false,
  masterPartnershipList: [],
  totalRecordPartnership: 0,
  masterPartnershipLoading: false,
  startApiCallPartnershipList: true,
  currentPagePartnership: 1,
  pageSizePartnership: 10,
  searchTextMasterPartnership: "",
  partnershipViewData: {},
  partnershipListByUserLoading: false,
  partnershipListByUser: [],
  selectedPartnerAccountInPartnershipAdd: {},
  singlePartnershipEditDeleteData: {},
  sortMasterPartnershipAdd: {},
  partnersOfUserList: {},
  userOfPartnerList: {},
};

export const addPartnership = createAsyncThunk(
  "/master/partnership/add",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      const clientBrokerage = {};
      clientBrokerage.id = 0;
      clientBrokerage.brokeragePartnershipRate = data.brokeragePartnershipRate
        ? data.brokeragePartnershipRate
        : 0;
      clientBrokerage.sharingPartnershipRate = data.sharingPartnershipRate
        ? data.sharingPartnershipRate
        : 0;

      axios
        .post("/master/partnership/add-partnership", {
          createdBy: userId,
          id: 0,
          partnerId: data.partner.value,
          userId: data.partnerId.value,
          brokerageType: data.partnershipType == "%" ? 1 : 2,
          SegmentId: data.segment.value,
          script: data.isScriptWise ? data.script.value : null,
          isScriptWise: data.isScriptWise,
          clientBrokerage,
        })
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const editPartnership = createAsyncThunk(
  "/master/partnership/edit",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      const clientBrokerage = {};
      clientBrokerage.id = 0;
      clientBrokerage.sharingPartnershipRate = data.sharingPartnershipRate
        ? data.sharingPartnershipRate
        : 0;
      clientBrokerage.brokeragePartnershipRate = data.brokeragePartnershipRate
        ? data.brokeragePartnershipRate
        : 0;
      axios
        .post("/master/partnership/update-partnership", {
          createdBy: userId,
          id: data.id,
          partnerId: data.partner.value,
          userId: data.partnerId.value,
          brokerageType: data.partnershipType == "%" ? 1 : 2,
          SegmentId: data.segment.value,
          script: data.isScriptWise ? data.script.value : null,
          isScriptWise: data.isScriptWise,
          clientBrokerage,
        })
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getMasterPartnershipList = createAsyncThunk(
  "/partnership",
  ({ searchText, pageNo, pageSize, appendData }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(
          `/master/partnership/get-partnerships/${userId}/${pageNo}/${pageSize}${
            searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getPartnershipByUser = createAsyncThunk(
  "/partnership/get/userId",
  ({ id }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`master/partnership/get-partnerships-by-user/${id}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getPartnersOfUser = createAsyncThunk(
  "/partnershipby/user",
  (id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`common/get-partner-by-userid/${id}`)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve(data.data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          dispatch(showMessage({ message: error.response.data.message }));
          reject(error);
        });
    });
  }
);
export const getUserOfPartner = createAsyncThunk(
  "/userof/partner",
  ({ id }, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`common/get-users-by-partnerid/${id}`)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve(data.data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          dispatch(showMessage({ message: error.response.data.message }));
          reject(error);
        });
    });
  }
);
export const deletePartnership = createAsyncThunk(
  "/partnership/delete",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      //user info
      let { id } = data;
      axios
        .delete(`master/partnership/delete-partnership/${id}`)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const partnershipSlice = createSlice({
  name: "partnership/slice",
  initialState,
  reducers: {
    setStartApiCallPartnershipList: (state, action) => {
      state.startApiCallPartnershipList = action.payload;
    },
    setStartPagePartnership: (state, action) => {
      state.currentPagePartnership = action.payload;
    },
    setSearchTextMasterPartnership: (state, action) => {
      state.searchTextMasterPartnership = action.payload;
    },
    setPartnershipViewData: (state, action) => {
      state.partnershipViewData = action.payload;
    },
    setSelectedPartnerAccountInPartnershipAdd: (state, action) => {
      state.selectedPartnerAccountInPartnershipAdd = action.payload;
    },
    setIsPartnershipUpdated: (state, action) => {
      state.isPartnerShipUpdated = action.payload;
    },
    setSinglePartnershipEditDeleteData: (state, action) => {
      state.singlePartnershipEditDeleteData = action.payload;
    },
    setSortMasterPartnershipAdd: (state, action) => {
      state.sortMasterPartnershipAdd = action.payload;
    },
    setPartnershipByUser: (state, action) => {
      state.partnershipListByUser = action.payload;
    },
  },
  extraReducers: {
    [addPartnership.pending]: (state, action) => {
      state.masterPartnershipLoading = true;
    },
    [addPartnership.rejected]: (state, action) => {
      state.masterPartnershipLoading = false;
    },
    [addPartnership.fulfilled]: (state, action) => {
      state.isPartnerShipUpdated = true;
    },
    [getMasterPartnershipList.pending]: (state, action) => {
      state.masterPartnershipLoading = true;
      state.startApiCallPartnershipList = false;
    },
    [getMasterPartnershipList.rejected]: (state, action) => {
      state.masterPartnershipList = [];
      state.masterPartnershipLoading = false;
      state.startApiCallPartnershipList = false;
    },
    [getMasterPartnershipList.fulfilled]: (state, action) => {
      state.startApiCallPartnershipList = false;
      state.totalRecordPartnership = action.payload.data.totalRecords;
      if (action.payload.appendData) {
        state.masterPartnershipList = [
          ...state.masterPartnershipList,
          ...action.payload.data.records,
        ];
      } else {
        state.masterPartnershipList = action.payload.data.records;
      }
      state.masterPartnershipLoading = false;
    },
    [getPartnershipByUser.pending]: (state, action) => {
      state.partnershipListByUserLoading = true;
      state.partnershipListByUser = [];
    },
    [getPartnershipByUser.rejected]: (state, action) => {
      state.partnershipListByUser = [];
      state.partnershipListByUserLoading = false;
    },
    [getPartnershipByUser.fulfilled]: (state, action) => {
      state.partnershipListByUser = action.payload;
      state.partnershipListByUserLoading = false;
    },
    [deletePartnership.pending]: (state, action) => {
      state.isPartnerShipUpdated = false;
    },
    [deletePartnership.rejected]: (state, action) => {
      state.isPartnerShipUpdated = false;
    },
    [deletePartnership.fulfilled]: (state, action) => {
      state.isPartnerShipUpdated = true;
    },
    [editPartnership.pending]: (state, action) => {
      state.masterPartnershipLoading = true;
    },
    [editPartnership.rejected]: (state, action) => {
      state.masterPartnershipLoading = false;
    },
    [editPartnership.fulfilled]: (state, action) => {
      state.isPartnerShipUpdated = true;
    },

    [getPartnersOfUser.pending]: (state, action) => {
      state.masterPartnershipLoading = true;
    },
    [getPartnersOfUser.rejected]: (state, action) => {
      state.masterPartnershipLoading = false;
      state.partnersOfUserList = {};
    },
    [getPartnersOfUser.fulfilled]: (state, action) => {
      state.masterPartnershipLoading = false;
      state.partnersOfUserList = action.payload;
    },
    [getUserOfPartner.pending]: (state, action) => {
      state.masterPartnershipLoading = true;
      state.userOfPartnerList = {};
    },
    [getUserOfPartner.rejected]: (state, action) => {
      state.masterPartnershipLoading = false;
      state.userOfPartnerList = {};
    },
    [getUserOfPartner.fulfilled]: (state, action) => {
      state.masterPartnershipLoading = false;
      state.userOfPartnerList = action.payload;
    },
  },
});

export const {
  setStartApiCallPartnershipList,
  setStartPagePartnership,
  setSearchTextMasterPartnership,
  setPartnershipViewData,
  setSelectedPartnerAccountInPartnershipAdd,
  setIsPartnershipUpdated,
  setSortMasterPartnershipAdd,
  setSinglePartnershipEditDeleteData,
  setPartnershipByUser,
} = partnershipSlice.actions;

export default partnershipSlice.reducer;
