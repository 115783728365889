import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showMessage } from "../common.slice";
import axios from "axios";
import { getSessionData } from "utils/common";

const initialState = {
  isSettingClientSymbolUpdated: false,
  selectedAccountInSettingClientSegment: "",
  accountSettingType: 1,
  currentPageSettingClientSegment: 1,
  settingClientSegmentList: [],
  settingClientSegmentLoading: false,
  initialSegmentSettingData: {},
  searchTextSegmentSettings: "",
  isSettingClientSymbolGet: false,
  settingClientSegmentListOfLoginUser: [],
  settingClientSegmentLoadingOfLoginUser: false,
};

export const addSettingClientSegment = createAsyncThunk(
  "/setting/client-segment/Segment-setting-setting/add",
  (paramData, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.createdBy = userId;
      newFinalObject.id = paramData.id;
      newFinalObject.userId =
        paramData.userId && typeof paramData.userId === "number"
          ? paramData.userId
          : paramData.userId.value;
      newFinalObject.segmentId = paramData.segmentId.value;
      newFinalObject.tradeType = Number(paramData.tradeType);
      // newFinalObject.subBrokerPercentage = paramData.subBrokerPercentage
      // 	? Number(paramData.subBrokerPercentage)
      // 	: 0;
      newFinalObject.numberOfScripts = paramData.numberOfScripts
        ? Number(paramData.numberOfScripts)
        : 0;
      newFinalObject.marginAmount = paramData.marginAmount
        ? Number(paramData.marginAmount)
        : 0;
      newFinalObject.isAllowLimitOrder = paramData.isAllowLimitOrder;
      newFinalObject.isLimitOnly = paramData.isLimitOnly;
      newFinalObject.isFirstSellNotAllowed = paramData.isFirstSellNotAllowed;
      newFinalObject.isFirstSellLimitStopAllowed = paramData.isFirstSellLimitStopAllowed;
      axios
        .post(
          `settings/client-segment/save-client-segment-setting`,
          newFinalObject
        )
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getClientSegmentList = createAsyncThunk(
  "/clientsegment/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { searchText, pageNo, pageSize, appendData, id } = data;
      axios
        .get(
          `settings/client-segment/get-client-segment-settings-by-user/${id}`
        )
        .then((itemData) => {
          let { status, data } = itemData.data;
          if (status == 200) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getClientSegmentListOfLoginUser = createAsyncThunk(
  "/clientsegment/get/loginuser",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();

      axios
        .get(
          `settings/client-segment/get-client-segment-settings-by-user/${userId}`
        )
        .then((itemData) => {
          let { status, data } = itemData.data;
          if (status == 200) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const deleteSettingSegment = createAsyncThunk(
  "setting/segment/delete",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { id } = data;
      axios
        .delete(`settings/client-segment/delete-client-segment-setting/${id}`)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const settingClientSegment = createSlice({
  name: "clientSegment",
  initialState,
  reducers: {
    setSelectedAccountInSettingClientSegmentAddEditForm: (state, action) => {
      state.selectedAccountInSettingClientSegment = action.payload;
    },
    setAccountSettingClientSegmentType: (state, action) => {
      state.accountSettingType = action.payload;
    },
    setIsSettingClientSegmentUpdated: (state, action) => {
      state.isSettingClientSymbolUpdated = action.payload;
    },
    setStartPageSettingClientSegment: (state, action) => {
      state.currentPageSettingClientSegment = action.payload;
    },
    setSettingClientSegmentList: (state, action) => {
      state.settingClientSegmentList = action.payload;
    },
    setSearchTextSegmentSettings: (state, action) => {
      state.searchTextSegmentSettings = action.payload;
    },
    setIsSettingClientSymbolGet: (state, action) => {
      state.isSettingClientSymbolGet = action.payload;
    },
    setInitialSegmentSettingData: (state, action) => {
      state.initialSegmentSettingData = action.payload;
    },
  },
  extraReducers: {
    [addSettingClientSegment.pending]: (state, action) => {
      state.isSettingClientSymbolUpdated = false;
    },
    [addSettingClientSegment.rejected]: (state, action) => {
      state.isSettingClientSymbolUpdated = false;
    },
    [addSettingClientSegment.fulfilled]: (state, action) => {
      state.isSettingClientSymbolUpdated = true;
    },
    [getClientSegmentList.pending]: (state, action) => {
      state.settingClientSegmentLoading = true;
    },
    [getClientSegmentList.rejected]: (state, action) => {
      state.settingClientSegmentLoading = false;
    },
    [getClientSegmentList.fulfilled]: (state, action) => {
      state.settingClientSegmentList = action.payload;
      state.settingClientSegmentLoading = false;
    },
    [getClientSegmentListOfLoginUser.pending]: (state, action) => {
      state.settingClientSegmentLoadingOfLoginUser = true;
    },
    [getClientSegmentListOfLoginUser.rejected]: (state, action) => {
      state.settingClientSegmentLoadingOfLoginUser = false;
    },
    [getClientSegmentListOfLoginUser.fulfilled]: (state, action) => {
      state.settingClientSegmentListOfLoginUser = action.payload;
      state.settingClientSegmentLoadingOfLoginUser = false;
    },
    [deleteSettingSegment.pending]: (state, action) => {
      state.isSettingClientSymbolUpdated = false;
    },
    [deleteSettingSegment.rejected]: (state, action) => {
      state.isSettingClientSymbolUpdated = false;
    },
    [deleteSettingSegment.fulfilled]: (state, action) => {
      state.isSettingClientSymbolUpdated = true;
    },
  },
});
export const {
  setSelectedAccountInSettingClientSegmentAddEditForm,
  setAccountSettingClientSegmentType,
  setIsSettingClientSegmentUpdated,
  setStartPageSettingClientSegment,
  setSettingClientSegmentList,
  setSearchTextSegmentSettings,
  setIsSettingClientSymbolGet,
  setInitialSegmentSettingData,
} = settingClientSegment.actions;
export default settingClientSegment.reducer;
