import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {showMessage} from "../common.slice";
import axios from "axios";
import {getSessionData} from "utils/common";

const initialState = {
	isSettingOptionStrikeUpdated: false,
	selectedAccountInSettingClientSegment: "",
	accountSettingType: 1,
	currentPageSettingClientSegment: 1,
	settingOptionStrikeSettingList: [],
	isOptionStrikeSettingsLoading: false,
	selectedAccountInOptionStireValidationSetting: {},
	searchTextOptionStrikeSettings: "",
	startPageOptionStrikeSetting: 1,
	totalRecordsOptionStrikeSettings: 0,
	isSettingOptionStrikeGet: false,
};

export const addEditSettingOptionStrikeSetting = createAsyncThunk(
	"/setting/option-strike-setting/add",
	(paramData, {dispatch}) => {
		return new Promise((resolve, reject) => {
			let {userId} = getSessionData();
			let newFinalObject = {};
			newFinalObject.id = paramData.id;
			newFinalObject.userId = paramData.userId.value;
			newFinalObject.isRange = paramData.isRange;
			newFinalObject.createdBy = userId;
			newFinalObject.symbol = paramData.symbol.value;
			newFinalObject.rateStart = Number(paramData.rateStart);
			newFinalObject.rateEnd = Number(paramData.rateEnd);
			newFinalObject.segmentId = paramData.segmentId.value;
			newFinalObject.value = Number(paramData.value);
			if (!paramData.isRange) {
				newFinalObject.rateStart = 0;
				newFinalObject.rateEnd = 0;
			}
			newFinalObject.settingType = Number(paramData.settingType);
			axios
				.post(
					`settings/option-strike-validation-setting/save-option-strike-validation-setting`,
					newFinalObject
				)
				.then(({data}) => {
					if (data.status == 200) {
						dispatch(showMessage({message: data.message, varient: "success"}));
						resolve(data);
					} else {
						dispatch(showMessage({message: data.message}));
						reject(data);
					}
				})
				.catch((error) => {
					if (error.response) {
						dispatch(showMessage({message: error.response.data.message}));
					} else if (error.request) {
						dispatch(showMessage({message: error.request.message}));
					} else {
						dispatch(showMessage({message: error.message}));
					}
					reject(error);
				});
		});
	}
);

export const getOptionStrikeSettingList = createAsyncThunk(
	"/option-strike-setting/get",
	(data, {dispatch}) => {
		return new Promise((resolve, reject) => {
			let {searchText, pageNo, pageSize, appendData, userId} = data;
			axios
				.get(
					`settings/option-strike-validation-setting/get-option-strike-validation/${userId}/${pageNo}/${pageSize}${
						searchText ? `?searchText=${searchText}` : ""
					}`
				)
				.then(({data}) => {
					if (data.status == 200 && data && data.data.records.length > 0) {
						resolve({
							data: data.data.records,
							appendData,
							searchText,
							totalRecords: data.data.totalRecords,
						});
					} else {
						reject(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
);

export const deleteOptionStrikeSetting = createAsyncThunk(
	"/setting/quantity_delete_setting",
	(data, {dispatch}) => {
		return new Promise((resolve, reject) => {
			let {id} = data;
			axios
				.delete(
					`settings/option-strike-validation-setting/delete-option-strike-validation-setting/${id}`
				)
				.then(({data}) => {
					if (data.status == 200) {
						dispatch(showMessage({message: data.message, varient: "success"}));
						resolve(data);
					} else {
						dispatch(showMessage({message: data.message}));
						reject(data);
					}
				})
				.catch((error) => {
					if (error.response) {
						dispatch(showMessage({message: error.response.data.message}));
					} else if (error.request) {
						dispatch(showMessage({message: error.request.message}));
					} else {
						dispatch(showMessage({message: error.message}));
					}
					reject(error);
				});
		});
	}
);

export const settingOptionStrikeSetting = createSlice({
	name: "optionStrikeSetting",
	initialState,
	reducers: {
		setSelectedAccountInOptionStireValidationSetting: (state, action) => {
			state.selectedAccountInOptionStireValidationSetting = action.payload;
		},
		setAccountSettingType: (state, action) => {
			state.accountSettingType = action.payload;
		},
		setSettingOptionStrikeUpdated: (state, action) => {
			state.isSettingOptionStrikeUpdated = action.payload;
		},
		setOptionStrikeSettingDeleteId: (state, action) => {
			state.optionStrikeSettingDeleteId = action.payload;
		},
		setStartPageOptionStrikeSetting: (state, action) => {
			state.startPageOptionStrikeSetting = action.payload;
		},
		setSettingOptionStrikeSettingList: (state, action) => {
			state.settingOptionStrikeSettingList = action.payload;
		},
		setSearchTextOptionStrikeSettings: (state, action) => {
			state.searchTextOptionStrikeSettings = action.payload;
		},
		setIsSettingOptionStrikeGet: (state, action) => {
			state.isSettingOptionStrikeGet = action.payload;
		},
	},
	extraReducers: {
		[addEditSettingOptionStrikeSetting.pending]: (state, action) => {
			state.isSettingOptionStrikeUpdated = false;
		},
		[addEditSettingOptionStrikeSetting.rejected]: (state, action) => {
			state.isSettingOptionStrikeUpdated = false;
		},
		[addEditSettingOptionStrikeSetting.fulfilled]: (state, action) => {
			state.isSettingOptionStrikeUpdated = true;
		},
		[deleteOptionStrikeSetting.pending]: (state, action) => {
			state.isSettingOptionStrikeUpdated = false;
		},
		[deleteOptionStrikeSetting.rejected]: (state, action) => {
			state.isSettingOptionStrikeUpdated = false;
		},
		[deleteOptionStrikeSetting.fulfilled]: (state, action) => {
			state.isSettingOptionStrikeUpdated = true;
		},
		[getOptionStrikeSettingList.pending]: (state, action) => {
			state.isOptionStrikeSettingsLoading = true;
		},
		[getOptionStrikeSettingList.rejected]: (state, action) => {
			state.isOptionStrikeSettingsLoading = false;
			state.settingOptionStrikeSettingList = [];
		},
		[getOptionStrikeSettingList.fulfilled]: (state, action) => {
			if (action.payload.appendData) {
				state.settingOptionStrikeSettingList = [
					...state.settingOptionStrikeSettingList,
					...action.payload.data,
				];
			} else {
				state.settingOptionStrikeSettingList = action.payload.data;
			}
			state.isOptionStrikeSettingsLoading = false;
			state.totalRecordsOptionStrikeSettings = action.payload.totalRecords;
		},
	},
});
export const {
	setSettingOptionStrikeSettingList,
	setOptionStrikeSettingDeleteId,
	setSettingOptionStrikeUpdated,
	setSelectedAccountInSettingClientSegmentAddEditForm,
	setAccountSettingType,
	setSearchTextSegmentSettings,
	setSelectedAccountInOptionStireValidationSetting,
	setSearchTextOptionStrikeSettings,
	setStartPageOptionStrikeSetting,
	setIsSettingOptionStrikeGet,
} = settingOptionStrikeSetting.actions;
export default settingOptionStrikeSetting.reducer;
