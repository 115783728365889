import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { setSymbolNameString, getIndexScript } from "store/reducers/marketWatch.slice";
import MobileNewDashboard from './MobileNewDashboard';

export default function MarketWatchMain() {
  const dispatch = useDispatch();
  const [isSocketCallData, setIsSocketCall] = useState(true);
  const isSocketCall = useMemo(() => isSocketCallData, [isSocketCallData]);

  useEffect(() => {
    dispatch(getIndexScript());
    if (isSocketCall) {
    } else {
      dispatch(setSymbolNameString(""));
      Swal.close();
    }
  }, [dispatch, isSocketCall]);

  const checkTabFocused = () => {
    if (document.visibilityState === "visible") {
      if (!isSocketCall) {
        setIsSocketCall(true);
      }
    }
 
  }
  const hello = (event) => {
    if (navigator.onLine) {
      if (!isSocketCall) {
        window.location.reload();
      }
    } else {
      if (isSocketCall) {
        setIsSocketCall(false);
      }
    }
  };
  window.addEventListener("online", hello);
  window.addEventListener("offline", hello);
  document.addEventListener("visibilitychange", checkTabFocused);

  return (
    <MobileNewDashboard isSocketCall={isSocketCall} />
  );
}
