import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getSessionData } from "utils/common";
import { showMessage } from "store/reducers/common.slice";

const initialState = {
  adminSubBrokerUserList: [],
  adminSubBrokerUserLoading: false,
  isAdminSubBrokerUsersUpdated: false,
  adminSubBrokerUsersTotalRecords: 0,
  adminSubBrokerUsersSearchText: "",
  activeUser: 0,
  sortAdminSubBrokerUserArr: [],

  adminSystemUserList: [],
  totalAdminSystemUserList: 0,
  adminSystemUserLoading: false,
  isUpdatedAdminSystemUser: false,
  adminSortSystemUserArr: [],
  subBrokerUsersViewData: [],
  multiDeleteSystemUserList: [],
  searchTextSystemUserMap: "",

  adminClientMapList: [],
  adminClientMapLoading: false,
  searchTextClientMap: "",
  adminSortClientMapArr: [],

  userRightsList: [],
  isUserRightsList: false,
  userAuthorityLimits: [],
  userAuthorityLimitstLoading: false,
  isUserAuthorityLimitsUpdate: false,

  userAuthorityList: [],
  userAuthorityListLoading: false,

  callPutSettingFromMasterAcc: {}
};

const adminSubBrokerData = [];

export const getAdminSubBrokerUser = createAsyncThunk(
  "admin/sub broker user",
  () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(adminSubBrokerData);
      }, 1000);
    });
  }
);

export const getAuthoritysList = createAsyncThunk(
  "admin/getUserauthorityList",
  (id) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(`common/get-authority-by-userid/${userId}`)
        .then((resData) => {
          if (resData.status === 200) {
            let { data } = resData.data;
            resolve(data);
          } else {
            reject(resData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getAuthoritysLimits = createAsyncThunk(
  `settings/getAuthorityLimits`,
  (data) => {
    return new Promise((resolve, reject) => {
      const { pageNo, pageSize, searchText } = data;
      let { userId } = getSessionData();
      axios
        .get(
          `settings/authorityLimits/get-authority-limits/${userId}/${pageNo}/${pageSize}${searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then((resData) => {
          if (resData.status === 200) {
            resolve(resData.data);
          } else {
            reject(resData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const addAuthoritysLimits = createAsyncThunk(
  `settings/addAuthoritysLimits`,
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      const saveLimitsObj = {
        createdBy: userId,
        id: data?.id ? data.id : 0,
        userId: data?.authority?.value ? parseInt(data?.authority?.value) : 0,
        noofSupperMasters: data?.superMaster ? data.superMaster : 0,
        noofMasters: data?.master ? data.master : 0,
        noofUsers: data?.user ? data.user : 0,
        noofSystemUsers: data?.systemUser ? data.systemUser : 0,
      };
      axios
        .post("settings/authorityLimits/save-authority-limits", saveLimitsObj)
        .then((resData) => {
          if (resData.status === 200) {
            dispatch(
              showMessage({
                message: resData.data.message,
                varient: "success",
              })
            );
            const { data } = resData.data;
            resolve(data);
          } else {
            reject(resData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const deleteAuthoritysLimits = createAsyncThunk(
  `settings/deleteAuthoritysLimits`,
  (id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`settings/authorityLimits/delete-authority-limits/${id}`)
        .then((resData) => {
          if (resData.status === 200) {
            const { data } = resData.data;
            resolve(data);
            dispatch(
              showMessage({
                message: resData.data.message,
                varient: "success",
              })
            );
          } else {
            reject(resData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getUserRightsList = createAsyncThunk(
  "admin/getUserRightsList/list",
  (props) => {
    return new Promise((resolve, reject) => {
      const { userId, isMaster } = props
      axios
        .get(`settings/authorityRights/get-authorityRights-by-userid/${userId}`)
        .then((resData) => {
          if (resData.status === 200) {
            let { data } = resData.data;
            resolve({ data: data, isMaster: isMaster });
          } else {
            reject(resData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const saveUserRights = createAsyncThunk(
  "admin/saveUserRights",
  ({ values, userRightsList }, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let finalObject = {};
      let { userId } = getSessionData();
      finalObject.createdBy = userId;
      finalObject.userId = values.user.value;
      finalObject.formMasterPermission = userRightsList;
      axios
        .post(`settings/authorityRights/add-authorityRights`, finalObject)
        .then((dataResponse) => {
          if (dataResponse.status == 200) {
            dispatch(
              showMessage({
                message: dataResponse.data.message,
                varient: "success",
              })
            );
            resolve(dataResponse);
          } else {
            dispatch(
              showMessage({
                message: dataResponse.data.message,
              })
            );
            reject(dataResponse);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(
              showMessage({
                message: error.response.data.message,
              })
            );
          } else if (error.request) {
            dispatch(
              showMessage({
                message: error.request.message,
              })
            );
          } else {
            dispatch(
              showMessage({
                message: error.message,
              })
            );
          }
          reject(error);
        });
    });
  }
);

export const getAdminSystemUsers = createAsyncThunk(
  "admin/system users",
  (data) => {
    return new Promise((resolve, reject) => {
      let { searchText, pageNo, pageSize, systemUserId } = data;
      axios
        .get(
          `systemuser/get-system-user-map/${systemUserId}/${pageNo}/${pageSize}${searchText ? `?searchText=${searchText}` : " "
          }`
        )
        .then((resData) => {
          if (resData.status === 200) {
            let { data } = resData.data;
            resolve(data);
          } else {
            reject(resData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const saveAdminSystemUsers = createAsyncThunk(
  "admin/add-system-users-map",
  (data) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      const objSystemUserMap = {
        id: data?.id ? data.id : 0,
        createdBy: userId,
        userId: data?.client?.value ? Number(data.client.value) : 0,
        systemUserId: Number(data?.systemUser?.value),
      };
      axios
        .post("systemuser/save-system-user-map", objSystemUserMap)
        .then((resData) => {
          if (resData.status === 200) {
            let { data } = resData.data;
            resolve(data);
          } else {
            reject(resData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const deleteSystemUsersMap = createAsyncThunk(
  `settings/delete-system-users-map`,
  (deleteSystemUserMap, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post("systemuser/delete-system-users-map", deleteSystemUserMap)
        .then((resData) => {
          if (resData.status === 200) {
            const { data } = resData.data;
            resolve(data);
            dispatch(
              showMessage({
                message: resData.data.message,
                varient: "success",
              })
            );
          } else {
            reject(resData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getAdminClientMapList = createAsyncThunk(
  "admin/client map",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { searchText, pageNo, pageSize, Id } = data;
      axios
        .get(
          `settings/client-map/get-authority-user-by-id/${Id}/${pageNo}/${pageSize}${searchText ? `?searchText=${searchText}` : " "
          }`
        )
        .then((resData) => {
          if (resData.status === 200) {
            let { data } = resData.data;
            resolve(data);
          } else {
            reject(resData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const saveClientMapList = createAsyncThunk(
  "admin/saveClientMapList",
  (values, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let finalObject = {};
      let { userId } = getSessionData();
      finalObject.createdBy = userId;
      finalObject.userId = values.client.value;
      finalObject.authorityId = values.user.value;
      axios
        .post(`settings/client-map/save-client-map`, finalObject)
        .then((dataResponse) => {
          if (dataResponse.status == 200) {
            dispatch(
              showMessage({
                message: dataResponse.data.message,
                varient: "success",
              })
            );
            resolve(dataResponse);
          } else {
            dispatch(
              showMessage({
                message: dataResponse.data.message,
              })
            );
            reject(dataResponse);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(
              showMessage({
                message: error.response.data.message,
              })
            );
          } else if (error.request) {
            dispatch(
              showMessage({
                message: error.request.message,
              })
            );
          } else {
            dispatch(
              showMessage({
                message: error.message,
              })
            );
          }
          reject(error);
        });
    });
  }
);

export const adminSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setSortAdminSubBrokerUser: (state, action) => {
      state.sortAdminSubBrokerUserArr = action.payload;
    },
    setActiveUser: (state, action) => {
      state.activeUser = action.payload;
    },
    setSubBrokerUsersViewData: (state, action) => {
      state.subBrokerUsersViewData = action.payload;
    },
    setSortAdminClientMap: (state, action) => {
      state.setSortAdminClientMap = action.payload;
    },
    setCallPutSettingFromMasterAcc: (state, action) => {
      state.callPutSettingFromMasterAcc = action.payload;
    },
    setIsUserAuthorityLimitsUpdate: (state, action) => {
      state.isUserAuthorityLimitsUpdate = action.payload;
    },
    setIsUpdatedAdminSystemUser: (state, action) => {
      state.isUpdatedAdminSystemUser = action.payload;
    },
    setMultiDeleteSystemUserList: (state, action) => {
      state.multiDeleteSystemUserList = action.payload;
    },
    setSearchTextSystemUserMap: (state, action) => {
      state.searchTextSystemUserMap = action.payload;
    },
  },
  extraReducers: {
    //quantity setting
    [getAdminSubBrokerUser.pending]: (state, action) => {
      state.adminSubBrokerUserList = [];
      state.adminSubBrokerUserLoading = true;
    },
    [getAdminSubBrokerUser.rejected]: (state, action) => {
      state.adminSubBrokerUserList = [];
      state.adminSubBrokerUserLoading = false;
    },
    [getAdminSubBrokerUser.fulfilled]: (state, action) => {
      state.adminSubBrokerUserList = action.payload;
      state.adminSubBrokerUserLoading = false;
    },
    //admin system users
    [getAdminSystemUsers.pending]: (state, action) => {
      state.adminSystemUserList = [];
      state.adminSystemUserLoading = true;
    },
    [getAdminSystemUsers.rejected]: (state, action) => {
      state.adminSystemUserList = [];
      state.adminSystemUserLoading = false;
    },
    [getAdminSystemUsers.fulfilled]: (state, action) => {
      state.adminSystemUserList = action.payload?.records;
      state.totalAdminSystemUserList = action?.payload?.totalRecords;
      state.adminSystemUserLoading = false;
    },
    //save admin system users
    [saveAdminSystemUsers.pending]: (state, action) => {
      state.adminSystemUserLoading = true;
    },
    [saveAdminSystemUsers.rejected]: (state, action) => {
      state.adminSystemUserLoading = false;
    },
    [saveAdminSystemUsers.fulfilled]: (state, action) => {
      state.isUpdatedAdminSystemUser = true;
      state.adminSystemUserLoading = false;
    },
    //delete admin system users
    [deleteSystemUsersMap.pending]: (state, action) => {
      state.adminSystemUserLoading = true;
    },
    [deleteSystemUsersMap.rejected]: (state, action) => {
      state.adminSystemUserLoading = false;
    },
    [deleteSystemUsersMap.fulfilled]: (state, action) => {
      state.isUpdatedAdminSystemUser = true;
      state.adminSystemUserLoading = false;
    },
    //Admin Client Map List
    [getAdminClientMapList.pending]: (state, action) => {
      state.adminClientMapList = [];
      state.adminClientMapLoading = true;
    },
    [getAdminClientMapList.rejected]: (state, action) => {
      state.adminClientMapList = [];
      state.adminClientMapLoading = false;
    },
    [getAdminClientMapList.fulfilled]: (state, action) => {
      state.adminClientMapList = action.payload;
      state.adminClientMapLoading = false;
    },

    [getUserRightsList.pending]: (state, action) => {
      state.userRightsList = [];
      state.isUserRightsList = true;
    },
    [getUserRightsList.rejected]: (state, action) => {
      state.userRightsList = [];
      state.isUserRightsList = false;
    },
    [getUserRightsList.fulfilled]: (state, action) => {
      if (action.payload.isMaster) {
        state.masterRightsList = action.payload.data
      } else {
        if (state.masterRightsList) {
          let masterRightsListData = state.masterRightsList
          let userRightsListData = action.payload.data
          userRightsListData = userRightsListData.map(item => {
            const obj = masterRightsListData.find(master => master.formName === item.formName)
            return {
              ...item,
              isViewAllowFormMaster: obj.isViewAllow,
              isAddAllowFormMaster: obj.isAddAllow,
              isEditAllowFormMaster: obj.isEditAllow,
              isDeleteAllowFormMaster: obj.isDeleteAllow
            }
          })
          state.userRightsList = userRightsListData
        } else {
          state.userRightsList = action.payload.data;
        }
      }
      state.isUserRightsList = false;
    },

    [getAuthoritysList.pending]: (state, action) => {
      state.userAuthorityList = [];
      state.userAuthorityListLoading = true;
    },
    [getAuthoritysList.rejected]: (state, action) => {
      state.userAuthorityList = [];
      state.userAuthorityListLoading = false;
    },
    [getAuthoritysList.fulfilled]: (state, action) => {
      state.userAuthorityList = action.payload;
      state.userAuthorityListLoading = false;
    },
    [getAuthoritysLimits.pending]: (state, action) => {
      state.userAuthorityLimits = [];
      state.userAuthorityLimitstLoading = true;
    },
    [getAuthoritysLimits.rejected]: (state, action) => {
      state.userAuthorityLimits = [];
      state.userAuthorityLimitstLoading = false;
    },
    [getAuthoritysLimits.fulfilled]: (state, action) => {
      state.userAuthorityLimits = action.payload.data.records;
      state.userAuthorityLimitstLoading = false;
    },
    [addAuthoritysLimits.pending]: (state, action) => {
      state.userAuthorityLimitstLoading = true;
    },
    [addAuthoritysLimits.rejected]: (state, action) => {
      state.userAuthorityLimitstLoading = false;
    },
    [addAuthoritysLimits.fulfilled]: (state, action) => {
      state.isUserAuthorityLimitsUpdate = true;
      state.userAuthorityLimitstLoading = false;
    },
    [deleteAuthoritysLimits.pending]: (state, action) => {
      state.userAuthorityLimitstLoading = true;
    },
    [deleteAuthoritysLimits.rejected]: (state, action) => {
      state.userAuthorityLimitstLoading = false;
    },
    [deleteAuthoritysLimits.fulfilled]: (state, action) => {
      state.isUserAuthorityLimitsUpdate = true;
      state.userAuthorityLimitstLoading = false;
    },
  },
});

export const {
  setSortAdminSubBrokerUser,
  setActiveUser,
  setSubBrokerUsersViewData,
  setSearchTextSystemUserMap,
  setSortAdminClientMap,
  setIsUserAuthorityLimitsUpdate,
  setIsUpdatedAdminSystemUser,
  setMultiDeleteSystemUserList,
  setCallPutSettingFromMasterAcc
} = adminSlice.actions;

export default adminSlice.reducer;
