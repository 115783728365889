import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showMessage, setAgentORSubBrokerList } from "./common.slice";
import axios from "axios";
import { exportToExcel } from "utils/reportGenerator";
import _ from "lodash";
import moment from "moment";
import { getSessionData, checkIsEmpty } from "utils/common";

const initialState = {
  sortPositionReportArr: [],
  positionDisplayNames: "",
  positionReportList: new Map(),
  duplicatePositionReportList: [],
  filteredPositionReportList: new Map(),
  positionReportDetail: {},
  isPositionReportDetailLoading: false,
  positionReportDetailList: [],
  positionReportLoading: false,
  positionReportSearchText: "",
  positionCloseConfirmDialog: false,
  currentPagePositionReport: 1,
  positionCloseData: {},
  isClosePosition: false,
  isPositionCloseLoading: false,
  pageSizePositionReport: 10000,
  positionReportFilterValue: {},
  positionReportFilterValue2: {},
  closePositonSelected: [],
  isPositionGet: false,
  isPositionGet2: false,
  closePositionData: {},
  liveRateFromSocket: {},
};

export const getPositionReportList = createAsyncThunk(
  "/positionReport",
  (data, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      let { userId, roles } = getSessionData();
      let { valansList } = getState().valan;
      let dataObject = {};
      const {
        searchText,
        pageNo,
        pageSize,
        appendData,
        disptype,
        clientcode,
        subbrokercode,
        crtype,
        segment,
        valan,
        symbol,
        reporttype,
        exdate,
        isExDateSel,
      } = data;
      dataObject.createdBy = userId;
      dataObject.reportType =
        reporttype == "client" ? 1 : reporttype == "script" ? 2 : 3;
      dataObject.userId = data.userId
        ? data.userId
        : reporttype == "self" && !checkIsEmpty(subbrokercode)
          ? Number(subbrokercode.value)
          : userId;
      dataObject.pageNo = pageNo;
      dataObject.pageSize = pageSize;
      dataObject.isDayWise = crtype && crtype == "day" ? true : false;
      dataObject.isOutStanding = disptype && disptype != "all" ? true : false;
      dataObject.segmentId = segment && segment.value ? segment.value : null;
      if (crtype == "day") {
        dataObject.startDate = moment().format();
        dataObject.endDate = moment().format();
      } else {
        dataObject.startDate = !checkIsEmpty(valan)
          ? moment(valan.value.startDate).format()
          : //   : moment().format();
          valansList[0].startDate;
        dataObject.endDate = !checkIsEmpty(valan)
          ? moment(valan.value.endDate).format()
          : //   : moment().format();
          valansList[0].endDate;
      }
      dataObject.valanId =
        !checkIsEmpty(valan) && valan.value ? valan.value.id : 0;
      dataObject.authorityId =
        reporttype == "self" && !checkIsEmpty(subbrokercode)
          ? subbrokercode.value
          : roles[0] !== "Super Admin" && reporttype == "self"
            ? userId
            : null;
      dataObject.subBrokerId =
        !checkIsEmpty(subbrokercode) && reporttype !== "self"
          ? Number(subbrokercode.value)
          : null;
      if (reporttype == "client") {
        dataObject.clientId =
          clientcode && Object.keys(clientcode).length > 0
            ? clientcode.value
            : roles[0] == "User"
              ? Number(userId)
              : null;
      } else if (reporttype == "self") {
        dataObject.clientId = roles[0] == "User" ? Number(userId) : null;
      } else {
        dataObject.clientId = null;
      }
      dataObject.searchText = searchText ? searchText : null;
      dataObject.exDate = isExDateSel == true ? exdate : null;
      dataObject.symbol =
        symbol && Object.keys(symbol).length > 0 ? symbol.value : null;
      axios
        .post("position-report/get-position-report", dataObject)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const exportPositionReport = createAsyncThunk(
  "/export/positionReport",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId, roles } = getSessionData();
      let dataObject = {};

      const tableColumn = [
        { dataKey: "segment", title: "segment" },
        { dataKey: "segmentId", title: "segmentId" },
        { dataKey: "segmentCode", title: "segmentCode" },
        { dataKey: "token", title: "token" },
        { dataKey: "exDate", title: "exDate" },
        { dataKey: "symbol", title: "symbol" },
        { dataKey: "clientName", title: "clientName" },
        { dataKey: "script", title: "script" },
        { dataKey: "displayName", title: "displayName" },
        { dataKey: "totalBuyQty", title: "totalBuyQty" },
        { dataKey: "buyAvgPrice", title: "buyAvgPrice" },
        { dataKey: "totalSellQty", title: "totalSellQty" },
        { dataKey: "sellAvgPrice", title: "sellAvgPrice" },
        { dataKey: "netLot", title: "netLot" },
        { dataKey: "netQty", title: "netQty" },
        { dataKey: "beAvg", title: "beAvg" },
        { dataKey: "ltp", title: "ltp" },
        { dataKey: "mtm", title: "mtm" },
        { dataKey: "isOrderBetweenHighLow", title: "isOrderBetweenHighLow" },
      ];
      const {
        searchText,
        pageNo,
        pageSize,
        appendData,
        disptype,
        clientcode,
        subbrokercode,
        crtype,
        segment,
        valan,
        symbol,
        reporttype,
        exdate,
        isExDateSel,
      } = data;
      dataObject.reportType =
        reporttype == "client" ? 1 : reporttype == "script" ? 2 : 3;
      dataObject.userId = userId;
      dataObject.pageNo = pageNo;
      dataObject.pageSize = pageSize;
      dataObject.isDayWise = crtype && crtype == "day" ? true : false;
      dataObject.isOutStanding = disptype && disptype == "all" ? false : true;
      dataObject.segmentId = segment && segment.value ? segment.value : null;
      if (crtype == "day") {
        dataObject.startDate = moment().format();
        dataObject.endDate = moment().format();
      } else {
        dataObject.startDate = !checkIsEmpty(valan)
          ? moment(valan.value.startDate).format()
          : moment().format();
        dataObject.endDate = !checkIsEmpty(valan)
          ? moment(valan.value.endDate).format()
          : moment().format();
      }
      dataObject.valanId =
        valan && Object.keys(valan).length > 0 ? valan.value.id : null;
      dataObject.subBrokerId = !checkIsEmpty(subbrokercode)
        ? Number(subbrokercode.value)
        : null;
      if (reporttype == "client") {
        dataObject.clientId = !checkIsEmpty(clientcode)
          ? clientcode.value
          : roles[0] == "User"
            ? Number(userId)
            : null;
      } else if (reporttype == "self") {
        dataObject.clientId = roles[0] == "User" ? Number(userId) : null;
      } else {
        dataObject.clientId = null;
      }
      dataObject.searchText = searchText ? searchText : null;
      if (isExDateSel == true) {
        dataObject.exdate = exdate;
      }
      dataObject.symbol =
        symbol && Object.keys(symbol).length > 0 ? symbol.value : null;
      axios
        .post("position-report/get-position-report", dataObject)
        .then(({ data }) => {
          if (data.status == 200) {
            let finalExcelData = data.data.records.map((item) =>
              _.omit(item, ["userId"])
            );
            let exportData = {
              FileName: "Trade Book",
              exportColumn: tableColumn,
              arrayName: finalExcelData,
              // headData: [
              //   [
              //     {
              //       content: "User",
              //       colSpan: 12,
              //       styles: { halign: "center", fillColor: "#adb5bd" },
              //     },
              //   ],
              // ],
              title: "Position Report",
              theme: "grid",
              headStyles: { with: "50px", fillColor: [46, 142, 207] },
              date: moment().format("DD-MM-YYYY"),
              reportType: "positionReport",
              // layout: pageLayout.value,
            };
            // generatePDF(exportData);
            exportToExcel({
              apiData: finalExcelData,
              fileName: "Tradebook",
            });
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const closePosition = createAsyncThunk(
  "position/close",
  (data, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      let { userId, userIpFinal } = getSessionData();
      let dataObject = {};
      dataObject.createdBy = userId;
      dataObject.userId = data.userId;
      dataObject.id = 0;
      dataObject.deviceType = 1;
      dataObject.segmentId = data.segmentId;
      dataObject.script = data.script;
      dataObject.displayName = data.displayName;
      dataObject.userIp = userIpFinal;
      dataObject.orderType = data.oldNetQty > 0 ? 2 : 1;
      dataObject.lot = data.lot;
      dataObject.qty =
        data.segmentId !== 1 ? data.oneQtySize * data.lot : data.qty;
      dataObject.rate = data.price;
      dataObject.mediumPrice = data.ltp;
      dataObject.lowPrice = 0;
      dataObject.highPrice = 0;
      dataObject.executionType = data.marketType == "market"
        ? data.marketType
        : data.marketType == "limit" && data.oldNetQty > 0
          ? "sellLimit"
          : "buyLimit";
      dataObject.symbol = data.symbol;
      axios
        .post("position-report/save-close-position", dataObject)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const saveAllClosePosition = createAsyncThunk(
  "position/close",
  (data, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      let { userId, userIpFinal } = getSessionData();
      let finalArray = [];
      data &&
        data.forEach((item) => {
          let finalObject = {};
          finalObject.deviceType = 1;
          finalObject.createdBy = userId;
          finalObject.userId = item.userId;
          finalObject.id = 0;
          finalObject.segmentId = item.segmentId;
          finalObject.script = item.script;
          finalObject.displayName = item.displayName;
          finalObject.userIp = userIpFinal;
          finalObject.orderType = item.netQty > 0 ? 2 : 1;
          finalObject.lot = Math.abs(item.netLot);
          finalObject.qty = Math.abs(item.netQty);
          finalObject.rate = item.ltp;
          finalObject.mediumPrice = item.ltp;
          finalObject.lowPrice = item.lowPrice ? item.lowPrice : 0;
          finalObject.highPrice = item.highPrice ? item.highPrice : 0;
          finalObject.executionType = 5;
          finalObject.symbol = item.symbol;
          finalArray.push(finalObject);
        });
      axios
        .post("position-report/save-all-close-position", finalArray)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const rollOverPosition = createAsyncThunk(
  "position/rollover",
  (data, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      let { closePositonSelected } = getState().positionReport;
      let { userId, userIpFinal } = getSessionData();
      let finalArray = [];
      if (closePositonSelected && closePositonSelected.length > 0) {
        closePositonSelected.forEach((item) => {
          let finalObject = {};
          finalObject.userId = item.userId;
          finalObject.subBrokerId = null;
          finalObject.script = item.script;
          finalObject.segmentId = item.segmentId;
          finalObject.segmentCode = item.segmentCode;
          finalObject.symbol = item.symbol;
          finalObject.token = item.token;
          finalObject.exDate = moment(item.exDate).format("YYYY-MM-DD");
          finalObject.date = moment().format();
          finalObject.displayName = item.displayName;
          finalObject.userIp = userIpFinal;
          finalObject.valanId = 0;
          finalObject.deviceType = 1;
          finalObject.orderType = item.netQty > 0 ? 1 : 2;
          finalObject.tradeOption = 1;
          finalObject.tradeType = 1;
          finalObject.isCheckHL = true;
          finalObject.lot = item.netLot > 0 ? item.netLot : item.netLot * -1;
          finalObject.qty = item.netQty > 0 ? item.netQty : item.netQty * -1;
          finalObject.lowPrice = 0;
          finalObject.mediumPrice = 0;
          finalObject.highPrice = 0;
          finalObject.createdBy = userId;
          finalArray.push(finalObject);
        });
      } else {
        dispatch(showMessage({ message: "Please select at least one record" }));
        return;
      }
      axios
        .post("position-report/save-roll-over-position", finalArray)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getAuthorityByUserId = createAsyncThunk(
  "/account/getAuthorityByUserId",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId, userIpFinal } = getSessionData();
      axios
        .get(`common/get-authority-by-userid/${userId}`)
        .then(({ data }) => {
          if (data.status == 200) {
            let { data: dataArray } = data;
            if (dataArray && dataArray.length > 0) {
              dispatch(setAgentORSubBrokerList(dataArray));
              resolve(data);
            } else {
              reject(data);
            }
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getPositionReportDetail = createAsyncThunk(
  "/position/detail",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post("trade/get-trades-by-symbol", data)
        .then(({ data }) => {
          if (data.status == 200) {
            if (data.data?.records && data.data.records.length > 0) {
              resolve(data.data.records);
            } else {
              reject(data);
            }
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const positionReportSlice = createSlice({
  name: "positionReport",
  initialState,
  reducers: {
    setSortPositionReport: (state, action) => {
      state.sortPositionReportArr = action.payload;
    },
    setPositionReportSearchText: (state, action) => {
      state.positionReportSearchText = action.payload;
    },
    setPositionReportList: (state, action) => {
      const list = action.payload || [];
      let map = new Map();
      list.forEach((x) => map.set(x?.rowId, x));
      state.positionReportList = map;
    },
    setDuplicatePositionReportList: (state, action) => {
      state.duplicatePositionReportList = action.payload;
    },
    setfilteredPositionReportList: (state, action) => {
      const list = action.payload || [];
      let map = new Map();
      list.forEach((x) => map.set(x?.rowId, x));
      state.filteredPositionReportList = map;
    },
    setPositionCloseConfirmDialog: (state, action) => {
      state.positionCloseConfirmDialog = action.payload;
    },
    setStartPagePositionReport: (state, action) => {
      state.currentPagePositionReport = action.payload;
    },
    setPositionCloseData: (state, action) => {
      state.positionCloseData = action.payload;
    },
    setIsClosePosition: (state, action) => {
      state.isClosePosition = action.payload;
    },
    setTotalRecordPositionReport: (state, action) => {
      state.totalRecordPositionReport = action.payload;
    },
    setPositionReportFilterValue: (state, action) => {
      state.positionReportFilterValue = action.payload;
    },
    setPositionReportFilterValue2: (state, action) => {
      state.positionReportFilterValue2 = action.payload;
    },
    setClosePositonSelected: (state, action) => {
      state.closePositonSelected = action.payload;
    },
    setIsPositionGet: (state, action) => {
      state.isPositionGet = action.payload;
    },
    setIsPositionGet2: (state, action) => {
      state.isPositionGet2 = action.payload;
    },
    setPositionReportDetail: (state, action) => {
      state.positionReportDetail = action.payload;
    },
    setPositionReportDetailList: (state, action) => {
      state.positionReportDetailList = action.payload;
    },
    setClosePositionData: (state, action) => {
      state.closePositionData = action.payload;
    },
    setSocketData: (state, action) => {
      state.liveRateFromSocket = action.payload;
    },
    setPositionDisplayNames: (state, action) => {
      state.positionDisplayNames = action.payload;
    },
  },
  extraReducers: {
    [getPositionReportList.pending]: (state, action) => {
      state.positionReportLoading = true;
    },
    [getPositionReportList.rejected]: (state, action) => {
      state.positionReportList = [];
      state.duplicatePositionReportList = [];
      state.positionReportLoading = false;
      state.totalRecordPositionReport = 0;
    },
    [getPositionReportList.fulfilled]: (state, action) => {
      let map = new Map();
      let positionDisplayNames = "";
      if (action.payload.appendData) {
        let list =
          [...state.positionReportList, ...action.payload.data.records] || [];
          positionDisplayNames = list
          .filter((a) => a.netQty !== 0)
          ?.map(curVal => curVal.displayName)
        state.positionDisplayNames = positionDisplayNames;
        list = _.map(list, (o, index) =>
          _.extend(
            { isCheck: o.isCheck ? o.isCheck : false, rowId: ++index },
            o
          )
        );
        list.forEach((x) => map.set(x?.rowId, x));
        state.positionReportList = map;
        state.duplicatePositionReportList = [...map.values()];
      } else {
        let list = action.payload.data.records || [];
        positionDisplayNames = list
          .filter((a) => a.netQty !== 0)
          ?.map(curVal => curVal.displayName)
        list = _.map(list, (o, index) =>
          _.extend({ isCheck: false, rowId: ++index }, o)
        );
        list = _.each(list, (value, index) => (value.rowId = ++index));
        list.forEach((x) => map.set(x?.rowId, x));
        state.positionReportList = map;
        state.duplicatePositionReportList = [...map.values()];
        state.positionDisplayNames = positionDisplayNames;
      }
      state.positionReportLoading = false;
      state.totalRecordPositionReport = action.payload.data.totalRecords;
    },
    [closePosition.pending]: (state, action) => {
      state.isPositionCloseLoading = true;
      state.isClosePosition = false;
    },
    [closePosition.rejected]: (state, action) => {
      state.isPositionCloseLoading = false;
      state.isClosePosition = false;
    },
    [closePosition.fulfilled]: (state, action) => {
      state.isPositionCloseLoading = false;
      state.isClosePosition = true;
    },
    [saveAllClosePosition.pending]: (state, action) => {
      state.isPositionCloseLoading = true;
      state.isClosePosition = false;
    },
    [saveAllClosePosition.rejected]: (state, action) => {
      state.isPositionCloseLoading = false;
      state.isClosePosition = false;
    },
    [saveAllClosePosition.fulfilled]: (state, action) => {
      state.isPositionCloseLoading = false;
      state.isClosePosition = true;
    },
    [getPositionReportDetail.pending]: (state, action) => {
      state.isPositionReportDetailLoading = true;
      state.isClosePosition = false;
    },
    [getPositionReportDetail.rejected]: (state, action) => {
      state.isPositionReportDetailLoading = false;
      state.isClosePosition = false;
    },
    [getPositionReportDetail.fulfilled]: (state, action) => {
      state.positionReportDetailList = action.payload;
      state.isPositionReportDetailLoading = false;
      state.isClosePosition = true;
    },
    [rollOverPosition.pending]: (state, action) => {
      state.isPositionCloseLoading = true;
      state.isClosePosition = false;
    },
    [rollOverPosition.rejected]: (state, action) => {
      state.isPositionCloseLoading = false;
      state.isClosePosition = false;
    },
    [rollOverPosition.fulfilled]: (state, action) => {
      state.isPositionCloseLoading = false;
      state.isClosePosition = true;
    },
  },
});

export const {
  setSortPositionReport,
  setPositionReportSearchText,
  setPositionReportList,
  setPositionCloseConfirmDialog,
  setStartPagePositionReport,
  setPositionCloseData,
  setIsClosePosition,
  setTotalRecordPositionReport,
  setPositionReportFilterValue,
  setPositionReportFilterValue2,
  setClosePositonSelected,
  setIsPositionGet,
  setIsPositionGet2,
  setPositionReportDetail,
  setPositionReportDetailList,
  setClosePositionData,
  setSocketData,
  setPositionDisplayNames,
  setfilteredPositionReportList,
  setDuplicatePositionReportList,
} = positionReportSlice.actions;

export default positionReportSlice.reducer;
