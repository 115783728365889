import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";
import { showMessage } from "../common.slice";
import { getSessionData } from "utils/common";

const initialState = {
  totalRecordScriptSettings: 0,
  scriptSettingList: [],
  ScriptLoading: false,
  startApiCallScriptSettingList: false,
  isScriptSettingUpdated: false,
  searchTextScriptSettings: "",
  accountSettingType: 1,
  selectedAccountInScriptSetting: {},
  currentPageScriptSettings: 1,
  pageSizeScriptSettings: 10,
  isScriptSettingGet: false,
  segmentId: {},
};

// symbol

export const getScriptSettingList = createAsyncThunk(
  "/setting/script/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { searchText, pageNo, pageSize, appendData, userId } = data;
      axios
        .get(
          `settings/client-block-allow-script/get-block-allow-symbol-by-user/${userId}/${pageNo}/${pageSize}${
            searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData, searchText });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const addSymbolSetting = createAsyncThunk(
  "/setting/block-scripts/add",
  (paramData, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.userId = Number(paramData.userId.value);
      newFinalObject.id = paramData.id;
      newFinalObject.createdBy = userId;
      newFinalObject.symbol = paramData.symbol.value;
      newFinalObject.segmentId = paramData.segmentId.value;
      newFinalObject.isBlocked = paramData.settingType == 1 ? true : false;
      axios
        .post(
          `settings/client-block-allow-script/save-block-allow-symbol`,
          newFinalObject
        )
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const deleteScriptSetting = createAsyncThunk(
  "/setting/block-scripts",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "settings/client-block-allow-script/delete-block-allow-symbol",
          data
        )
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const settingScriptSlice = createSlice({
  name: "settingscript",
  initialState,
  reducers: {
    //Script setting
    setIsScriptSettingUpdated: (state, action) => {
      state.isScriptSettingUpdated = action.payload;
    },
    setStartApiCallScriptSettingList: (state, action) => {
      state.startApiCallQuantitySettingList = action.payload;
    },
    setSearchTextScriptSetting: (state, action) => {
      state.searchTextScriptSettings = action.payload;
    },
    setStartPageScriptSetting: (state, action) => {
      state.currentPageScriptSettings = action.payload;
    },
    setScriptSettingList: (state, action) => {
      state.scriptSettingList = action.payload;
    },
    setAccountSettingType: (state, action) => {
      state.accountSettingType = action.payload;
    },
    setSelectedAccountInScriptSetting: (state, action) => {
      state.selectedAccountInScriptSetting = action.payload;
    },
    setSegmentId: (state, action) => {
      state.segmentId = action.payload;
    },
    setIsScriptSettingGet: (state, action) => {
      state.isScriptSettingGet = action.payload;
    },
  },
  extraReducers: {
    // Symbol/script
    [addSymbolSetting.pending]: (state, action) => {
      state.isScriptSettingUpdated = false;
    },
    [addSymbolSetting.rejected]: (state, action) => {
      state.isScriptSettingUpdated = false;
    },
    [addSymbolSetting.fulfilled]: (state, action) => {
      state.isScriptSettingUpdated = true;
    },

    [getScriptSettingList.pending]: (state, action) => {
      state.ScriptLoading = true;
      state.startApiCallSymbolExpirySettingList = false;
    },
    [getScriptSettingList.rejected]: (state, action) => {
      state.scriptSettingList = [];
      state.ScriptLoading = false;
      state.totalRecordScriptSettings = 0;
      state.startApiCallSymbolExpirySettingList = false;
    },
    [getScriptSettingList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.scriptSettingList = [
          ...state.scriptSettingList,
          ...action.payload.data.records,
        ];
      } else {
        state.scriptSettingList = action.payload.data.records;
      }
      state.ScriptLoading = false;
      if (action.payload.searchText) {
        state.totalRecordScriptSettings = action.payload.data.records.length;
      } else {
        state.totalRecordScriptSettings = action.payload.data.totalRecords;
      }
      state.startApiCallSymbolExpirySettingList = false;
    },
    [deleteScriptSetting.pending]: (state, action) => {
      state.isScriptSettingUpdated = false;
    },
    [deleteScriptSetting.rejected]: (state, action) => {
      state.isScriptSettingUpdated = false;
    },
    [deleteScriptSetting.fulfilled]: (state, action) => {
      state.isScriptSettingUpdated = true;
    },
  },
});

export const {
  setIsScriptSettingUpdated,
  setStartPageScriptSetting,
  setScriptSettingList,
  setSearchTextScriptSetting,
  setAccountSettingType,
  setSelectedAccountInScriptSetting,
  setSegmentId,
  setIsScriptSettingGet,
} = settingScriptSlice.actions;

export default settingScriptSlice.reducer;
