import React from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

export default function FilterMarketWatchData({ openProfileModal, filterValue, setFilterValue, onSubmitFilterModal }) {
  return (
    <div
      className={
        openProfileModal === true
          ? "show custom_popup_wrap"
          : "custom_popup_wrap"
      }
    >
      <div className="custom_popup_inner">
        <div className="popup_header">
          <h4 className="text-white m-0">Filter Setting</h4>
        </div>
        <div className="popup_content">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={filterValue?.id === 'script' ? "active" : ""}
                onClick={() => {
                  setFilterValue({ ...filterValue, id: "script" })
                }}
              >
                Alphabatic
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={filterValue?.id === 'netQty' ? "active" : ""}
                onClick={() => {
                  setFilterValue({ ...filterValue, id: "netQty" })
                }}
              >
                Position
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={filterValue?.id === 'changeAmount' ? "active" : ""}
                onClick={() => {
                  setFilterValue({ ...filterValue, id: "changeAmount" })
                }}
              >
                Change
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={filterValue?.id === 'changePercent' ? "active" : ""}
                onClick={() => {
                  setFilterValue({ ...filterValue, id: "changePercent" })
                }}
              >
                Change%
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent>
            <TabPane tabId="1" className={filterValue?.id === 'script' ? "active" : ""}>
              <ul className="d-flex">
                <li className="w-100 mr-2">
                  <button type="button" className={filterValue?.id === 'script' && filterValue?.desc === false ? "btn_blue_border active" : "btn_blue_border"} onClick={() => setFilterValue({ ...filterValue, desc: false })}>
                    A-Z
                  </button>
                </li>
                <li className="w-100">
                  <button type="button" className={filterValue?.id === 'script' && filterValue?.desc === true ? "btn_blue_border active" : "btn_blue_border"} onClick={() => setFilterValue({ ...filterValue, desc: true })}>
                    Z-A
                  </button>
                </li>
              </ul>
            </TabPane>
            <TabPane tabId="2" className={filterValue?.id === 'netQty' ? "active" : ""}>
              <ul className="d-flex">
                <li className="w-100 mr-2">
                  <button type="button" className={filterValue?.id === 'netQty' && filterValue?.desc === false ? "btn_blue_border active" : "btn_blue_border"} onClick={() => setFilterValue({ ...filterValue, desc: false })}>
                    Low To High
                  </button>
                </li>
                <li className="w-100">
                  <button type="button" className={filterValue?.id === 'netQty' && filterValue?.desc === true ? "btn_blue_border active" : "btn_blue_border"} onClick={() => setFilterValue({ ...filterValue, desc: true })}>
                    High To Low
                  </button>
                </li>
              </ul>
            </TabPane>
            <TabPane tabId="3" className={filterValue?.id === 'changeAmount' ? "active" : ""}>
              <ul className="d-flex">
                <li className="w-100 mr-2">
                  <button type="button" className={filterValue?.id === 'changeAmount' && filterValue?.desc === false ? "btn_blue_border active" : "btn_blue_border"} onClick={() => setFilterValue({ ...filterValue, desc: false })}>
                    Low To High
                  </button>
                </li>
                <li className="w-100">
                  <button type="button" className={filterValue?.id === 'changeAmount' && filterValue?.desc === true ? "btn_blue_border active" : "btn_blue_border"} onClick={() => setFilterValue({ ...filterValue, desc: true })}>
                    Hight To Low
                  </button>
                </li>
              </ul>
            </TabPane>
            <TabPane tabId="4" className={filterValue?.id === 'changePercent' ? "active" : ""}>
              <ul className="d-flex">
                <li className="w-100 mr-2">
                  <button type="button" className={filterValue?.id === 'changePercent' && filterValue?.desc === false ? "btn_blue_border active" : "btn_blue_border"} onClick={() => setFilterValue({ ...filterValue, desc: false })}>
                    Low To High
                  </button>
                </li>
                <li className="w-100">
                  <button type="button" className={filterValue?.id === 'changePercent' && filterValue?.desc === true ? "btn_blue_border active" : "btn_blue_border"} onClick={() => setFilterValue({ ...filterValue, desc: true })}>
                    Hight To Low
                  </button>
                </li>
              </ul>
            </TabPane>
          </TabContent>
          <div className="custom_popup_footer">
            <div className="row">
              <div className="col-6">
                <button type="button" className="btn_grey w-100" onClick={() => {
                  setFilterValue({ "id": "script", desc: false, })
                  onSubmitFilterModal({ "id": "script", desc: false, })
                }}>
                  Reset
                </button>
              </div>
              <div className="col-6">
                <button type="button" className="btn_blue w-100" onClick={() => onSubmitFilterModal(filterValue)}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
