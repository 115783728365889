import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import { showMessage } from "./common.slice";
import { getSessionData } from "utils/common";

const initialState = {
  masterBrokerageList: [],
  brokerageListType: "",
  masterBrokerageLoading: false,
  sortMasterBrokerageArr: [],
  sortMasterBrokerageAddArr: [],
  brokeragesListByUser: [],
  brokeragesListByUserLoading: false,
  searchTextMasterBrokerages: "",
  currentPageBrokerages: 1,
  pageSizeBrokerages: 10,
  totalRecordBrokerages: 0,
  startApiCallBrokeragesList: true,
  selectedUserAccountInBrokerageAdd: {},
  isBrokerageUpdated: false,
  brokerageViewData: {},
  singleBrokerageEditDeleteData: {},
  selectOptionType: 1,
  brokerageFromUpline: {}
};

export const addBrokerage = createAsyncThunk(
  "master/brokerage/add",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      const clientBrokerage = {};
      clientBrokerage.id = 0;
      clientBrokerage.rate = data.cRate ? data.cRate : 0;
      clientBrokerage.minimumRate = data.cMinimumRate ? data.cMinimumRate : 0;
      clientBrokerage.intradayRate = data.cIntradayRate
        ? data.cIntradayRate
        : 0;
      clientBrokerage.isHigherSideOnly = data.cIsHigherSideOnly;
      clientBrokerage.minimumPercentage = data.cMinimumPercentage
        ? data.cMinimumPercentage
        : 0;
      clientBrokerage.isMinPercentageOnDelivery =
        data.cIsMinPercentageOnDelivery;
      const subBrokerBrokerage = {};
      subBrokerBrokerage.id = 0;
      subBrokerBrokerage.rate = data.sbRate ? data.sbRate : 0;
      subBrokerBrokerage.minimumRate = data.sbMinimumRate
        ? data.sbMinimumRate
        : 0;
      subBrokerBrokerage.intradayRate = data.sbIntradayRate
        ? data.sbIntradayRate
        : 0;
      subBrokerBrokerage.isHigherSideOnly = data.sbIsHigherSideOnly;
      subBrokerBrokerage.minimumPercentage = data.sbMinimumPercentage
        ? data.sbMinimumPercentage
        : 0;
      subBrokerBrokerage.isMinPercentageOnDelivery =
        data.sbIsMinPercentageOnDelivery;
      let isself = {};
      if (data.accountType !== "1") {
        isself.isself = data.isself ? data.isself : false;
      }
      // segmentId
      axios
        .post("master/brokerage/add-brokerage", {
          createdBy: userId,
          id: 0,
          userId: data.userId.value,
          brokerageType: data.brokerageType == "%" ? 1 : 2,
          SegmentId: data.segmentId.value,
          symbol: data.isScriptWise ? data.script.value : null,
          isScriptWise: data.isScriptWise,
          ...isself,
          clientBrokerage,
          subBrokerBrokerage,
        })
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const editBrokerage = createAsyncThunk(
  "master/brokerage/edit",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      const clientBrokerage = {};
      clientBrokerage.id = 0;
      clientBrokerage.rate = data.cRate;
      clientBrokerage.minimumRate =
        data.cMinimumRate == "" ? 0 : data.cMinimumRate;
      clientBrokerage.intradayRate = data.cIntradayRate;
      clientBrokerage.isHigherSideOnly = data.cIsHigherSideOnly;
      clientBrokerage.minimumPercentage =
        data.cMinimumPercentage === "" ? 0 : data.cMinimumPercentage;
      clientBrokerage.isMinPercentageOnDelivery =
        data.cIsMinPercentageOnDelivery;
      const subBrokerBrokerage = {};
      subBrokerBrokerage.id = 0;
      subBrokerBrokerage.rate = data.sbRate == "" ? 0 : data.sbRate;
      subBrokerBrokerage.minimumRate =
        data.sbMinimumRate == "" ? 0 : data.sbMinimumRate;
      subBrokerBrokerage.intradayRate =
        data.sbIntradayRate == "" ? 0 : data.sbIntradayRate;
      subBrokerBrokerage.isHigherSideOnly = data.sbIsHigherSideOnly;
      subBrokerBrokerage.minimumPercentage =
        data.sbMinimumPercentage == "" ? 0 : data.sbMinimumPercentage;
      subBrokerBrokerage.isMinPercentageOnDelivery =
        data.sbIsMinPercentageOnDelivery;
      let isself = {};
      if (data.accountType !== "8") {
        isself.isself = data.isself ? data.isself : false;
      }
      // segmentId
      axios
        .post("master/brokerage/update-brokerage", {
          createdBy: userId,
          id: data.id,
          userId: data.userId.value,
          brokerageType: data.brokerageType == "%" ? 1 : 2,
          SegmentId: data.segmentId.value,
          symbol: data.isScriptWise ? data.script.value : null,
          isScriptWise: data.isScriptWise,
          clientBrokerage,
          ...isself,
          subBrokerBrokerage,
        })
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const deleteBrokerage = createAsyncThunk(
  "/brokerage/delete",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      //user info
      let { id } = data;
      axios
        .delete(`master/brokerage/delete-brokerage/${id}`)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getMasterBrokerageList = createAsyncThunk(
  "/brokeragee",
  ({ searchText, pageNo, pageSize, appendData, brokrageFor }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(
          `master/brokerage/get-brokerages/${brokrageFor}/${userId}/${pageNo}/${pageSize}${searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getBrokerageFromUpline = createAsyncThunk(
  "/brokerage/get-brokerage-from-upline",
  ({ segmentId, isScriptWise, symbol }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(
          `master/brokerage/get-brokerages-by-userid-and-segmentId/${userId}/${segmentId}/${isScriptWise}${symbol ? `?Symbol=${symbol}` : ""
          }`
        )
        .then(({ data }) => {
          if (data.status == 200) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getBrokeragesByUser = createAsyncThunk(
  "/brokeragee/get/userId",
  ({ id }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`master/brokerage/get-brokerages-by-user/${id}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const masterSlice = createSlice({
  name: "positionReport",
  initialState,
  reducers: {
    setBrokerageListType: (state, action) => {
      state.brokerageListType = action.payload;
    },
    setSortMasterBrokerageAdd: (state, action) => {
      state.sortMasterBrokerageAddArr = action.payload;
    },
    setSortMasterBrokerage: (state, action) => {
      state.sortMasterBrokerageArr = action.payload;
    },
    setSearchTextMasterBrokerages: (state, action) => {
      state.searchTextMasterBrokerages = action.payload;
    },
    setStartPageBrokerages: (state, action) => {
      state.currentPageBrokerages = action.payload;
    },
    setTotalRecordBrokerages: (state, action) => {
      state.totalRecordBrokerages = action.payload;
    },
    setSelectedUserAccountInBrokerageAdd: (state, action) => {
      state.selectedUserAccountInBrokerageAdd = action.payload;
    },
    setIsBrokerageUpdated: (state, action) => {
      state.isBrokerageUpdated = action.payload;
    },
    setBrokerageViewData: (state, action) => {
      state.brokerageViewData = action.payload;
    },
    setSingleBrokerageEditDeleteData: (state, action) => {
      state.singleBrokerageEditDeleteData = action.payload;
    },
    setBrokeragesListByUser: (state, action) => {
      state.brokeragesListByUser = action.payload;
    },
    setSelectOptionType: (state, action) => {
      state.selectOptionType = action.payload;
    },
    setBrokerageFromUpline: (state, action) => {
      state.brokerageFromUpline = action.payload;
    },
  },
  extraReducers: {
    [addBrokerage.pending]: (state, action) => {
      state.positionReportLoading = true;
    },
    [addBrokerage.rejected]: (state, action) => {
      state.positionReportLoading = false;
    },
    [addBrokerage.fulfilled]: (state, action) => {
      state.isBrokerageUpdated = true;
    },
    [getBrokerageFromUpline.fulfilled]: (state, action) => {
      state.brokerageFromUpline = action.payload ? action.payload : {};
    },
    [getMasterBrokerageList.pending]: (state, action) => {
      state.masterBrokerageLoading = true;
    },
    [getMasterBrokerageList.rejected]: (state, action) => {
      state.masterBrokerageList = [];
      state.masterBrokerageLoading = false;
      state.totalRecordBrokerages = 0
    },
    [getMasterBrokerageList.fulfilled]: (state, action) => {
      state.totalRecordBrokerages = action.payload.data.totalRecords;
      if (action.payload.appendData) {
        state.masterBrokerageList = [
          ...state.masterBrokerageList,
          ...action.payload.data.records,
        ];
      } else {
        state.masterBrokerageList = action.payload.data.records;
      }
      state.masterBrokerageLoading = false;
    },
    //user brokerage list
    [getBrokeragesByUser.pending]: (state, action) => {
      state.brokeragesListByUserLoading = true;
    },
    [getBrokeragesByUser.rejected]: (state, action) => {
      state.brokeragesListByUser = [];
      state.brokeragesListByUserLoading = false;
    },
    [getBrokeragesByUser.fulfilled]: (state, action) => {
      state.brokeragesListByUser = action.payload;
      state.brokeragesListByUserLoading = false;
    },

    //edit brokerage
    [editBrokerage.pending]: (state, action) => {
      state.isBrokerageUpdated = false;
    },
    [editBrokerage.rejected]: (state, action) => {
      state.isBrokerageUpdated = false;
    },
    [editBrokerage.fulfilled]: (state, action) => {
      state.isBrokerageUpdated = true;
    },
    //delete brokerage
    [deleteBrokerage.pending]: (state, action) => {
      state.isBrokerageUpdated = false;
    },
    [deleteBrokerage.rejected]: (state, action) => {
      state.isBrokerageUpdated = false;
    },
    [deleteBrokerage.fulfilled]: (state, action) => {
      state.isBrokerageUpdated = true;
    },
  },
});

export const {
  setSortMasterBrokerageAdd,
  setBrokerageListType,
  setSortMasterBrokerage,
  setStartPageBrokerages,
  setSearchTextMasterBrokerages,
  setTotalRecordBrokerages,
  setSelectedUserAccountInBrokerageAdd,
  setIsBrokerageUpdated,
  setBrokerageViewData,
  setSingleBrokerageEditDeleteData,
  setBrokeragesListByUser,
  setSelectOptionType,
  setBrokerageFromUpline
} = masterSlice.actions;

export default masterSlice.reducer;
