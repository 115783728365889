import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  sortPositionReportArr: [],
  bannedBlockedScriptList: [],
  bannedBlockedScriptLoading: false,
  bannedBlockedScripSearchText: "",
};

export const getBannedBlockedScriptList = createAsyncThunk(
  "banned/blocked/scirptslist",
  () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`script/block-script`)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const bannedBlockedScriptSlice = createSlice({
  name: "bannedBlockedScript",
  initialState,
  reducers: {
    setBannedBlickedScriptSearchText: (state, action) => {
      state.positionReportSearchText = action.payload;
    },
  },
  extraReducers: {
    [getBannedBlockedScriptList.pending]: (state, action) => {
      state.bannedBlockedScriptLoading = true;
    },
    [getBannedBlockedScriptList.rejected]: (state, action) => {
      state.bannedBlockedScriptList = [];
      state.bannedBlockedScriptLoading = false;
    },
    [getBannedBlockedScriptList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.bannedBlockedScriptList = [
          ...state.bannedBlockedScriptList,
          ...action.payload.data,
        ];
      } else {
        state.bannedBlockedScriptList = action.payload.data;
      }
      state.bannedBlockedScriptLoading = false;
    },
  },
});

export const { setBannedBlickedScriptSearchText } =
  bannedBlockedScriptSlice.actions;

export default bannedBlockedScriptSlice.reducer;
