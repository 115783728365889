import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {showMessage} from "../common.slice";
import {getSessionData} from "utils/common";
import axios from "axios";
const initialState = {
	settingClientOrderLimitList: [],
	settingSymbolClientOrderLimitLoading: false,
	isSettingSymbolClientOrderLimitUpdated: false,
	startApiCallsettingSymbolClientOrderLimitList: true,
	totalRecordSettingSymboClientOrderLimit: 0,
	currentPageSettingSymbolClientOrderLimit: 1,
	pageSizeSettingSymbolClientOrderLimit: 10,
	searchTextSettingSymbolClientOrderLimit: "",
	selectedAccountInSettingSymbolClientOrderLimit: "",
	startPageSettingSymbolClientOrderLimit: 1,
	accountSettingType: 1,
	symbolsOfClientOrderLimitSymbolList: [],
	isSettingClientOrderLimitGet: false,
};

export const getSymbolClientOrderLimitList = createAsyncThunk(
	"setting/symbolClientOrderLimit",
	(data, {dispatch}) => {
		return new Promise((resolve, reject) => {
			let {searchText, pageNo, pageSize, appendData, userId, segmentId} = data;
			axios
				.get(
					`settings/client-order-limit/get-client-order-limits-by-user/${userId}/${segmentId}/${pageNo}/${pageSize}${
						searchText ? `?searchText=${searchText}` : ""
					}`
				)
				.then(({data}) => {
					let {status, data: dataArray} = data;
					if (status == 200 && dataArray && dataArray.records.length > 0) {
						resolve({data: dataArray, appendData, searchText});
					} else {
						reject(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
);

export const addSettingSymbolClientOrderLimit = createAsyncThunk(
	"/setting/symbol-ClientOrderLimit-setting/add",
	(paramData, {dispatch}) => {
		return new Promise((resolve, reject) => {
			let {userId} = getSessionData();
			let newFinalObject = {};
			newFinalObject.accountSettingType = paramData.accountSettingType;
			newFinalObject.id = paramData.id;
			newFinalObject.isRange = paramData.isRange;
			newFinalObject.selectedUser = paramData.selectedUser;
			newFinalObject.userType = paramData.userType;
			newFinalObject.value = paramData.value;
			newFinalObject.createdBy = userId;
			newFinalObject.userId = paramData.userId.value;
			newFinalObject.symbol = paramData.symbol.value;
			newFinalObject.segmentId = paramData.segmentId.value;
			newFinalObject.rateStart = Number(paramData.rateStart);
			newFinalObject.rateEnd = Number(paramData.rateEnd);
			if (!paramData.isRange) {
				newFinalObject.rateStart = 0;
				newFinalObject.rateEnd = 0;
			}
			newFinalObject.limitType = Number(paramData.settingType);
			axios
				.post(
					`settings/client-order-limit/save-client-order-limit`,
					newFinalObject
				)
				.then(({data}) => {
					if (data.status == 200) {
						dispatch(showMessage({message: data.message, varient: "success"}));
						resolve(data);
					} else {
						dispatch(showMessage({message: data.message}));
						reject(data);
					}
				})
				.catch((error) => {
					if (error.response) {
						dispatch(showMessage({message: error.response.data.message}));
					} else if (error.request) {
						dispatch(showMessage({message: error.request.message}));
					} else {
						dispatch(showMessage({message: error.message}));
					}
					reject(error);
				});
		});
	}
);

export const deleteSettingSymbolClientOrderLimit = createAsyncThunk(
	"/setting/symbol-delete-setting",
	(data, {dispatch}) => {
		return new Promise((resolve, reject) => {
			axios
				.post('settings/client-order-limit/delete-client-order-limit',data)
				.then(({data}) => {
					if (data.status == 200) {
						dispatch(showMessage({message: data.message, varient: "success"}));
						resolve(data);
					} else {
						dispatch(showMessage({message: data.message}));
						reject(data);
					}
				})
				.catch((error) => {
					if (error.response) {
						dispatch(showMessage({message: error.response.data.message}));
					} else if (error.request) {
						dispatch(showMessage({message: error.request.message}));
					} else {
						dispatch(showMessage({message: error.message}));
					}
					reject(error);
				});
		});
	}
);

export const getSymbolClientOrderLimitSymbolList = createAsyncThunk(
	"/symbollist/get",
	({id}, {dispatch}) => {
		return new Promise((resolve, reject) => {
			axios
				.get(
					`settings/client-order-limit/get-client-order-limit-symbols-by-segment/${id}`
				)
				.then(({data}) => {
					let {status, data: dataArray} = data;
					if (status == 200 && dataArray && dataArray.length > 0) {
						resolve(dataArray);
					} else {
						reject(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
);

export const settingClientOrderLimit = createSlice({
	name: "setting",
	initialState,
	reducers: {
		setSelectedAccountInSettingSymbolClientOrderLimitAddEditForm: (
			state,
			action
		) => {
			state.selectedAccountInSettingSymbolClientOrderLimit = action.payload;
		},
		setAccountSettingSymbolClientOrderLimitType: (state, action) => {
			state.accountSettingType = action.payload;
		},
		setIsSettingSymbolClientOrderLimitUpdated: (state, action) => {
			state.isSettingSymbolClientOrderLimitUpdated = action.payload;
		},
		setStartPageSettingSymbolClientOrderLimit: (state, action) => {
			state.currentPageSettingSymbolClientOrderLimit = action.payload;
		},
		setSearchTextSettingSymbolClientOrderLimit: (state, action) => {
			state.searchTextSettingSymbolClientOrderLimit = action.payload;
		},
		setSettingSymbolClientOrderLimitList: (state, action) => {
			state.settingSymbolClientOrderLimitList = action.payload;
		},
		setTotalRecordSettingSymbolClientOrderLimit: (state, action) => {
			state.totalRecordSettingSymbolMaxValue = action.payload;
		},
		setSymbolsClientOrderLimitSymbolList: (state, action) => {
			state.symbolsOfClientOrderLimitSymbolList = action.payload;
		},
		setIsSettingClientOrderLimitGet: (state, action) => {
			state.isSettingClientOrderLimitGet = action.payload;
		},
	},
	extraReducers: {
		//Quantity setting
		[getSymbolClientOrderLimitList.pending]: (state, action) => {
			state.settingSymbolClientOrderLimitLoading = true;
			state.startApiCallsettingSymbolClientOrderLimitList = false;
		},
		[getSymbolClientOrderLimitList.rejected]: (state, action) => {
			state.settingSymbolClientOrderLimitList = [];
			state.settingSymbolClientOrderLimitLoading = false;
			state.totalRecordSettingSymbolClientOrderLimit = 0;
			state.startApiCallsettingSymbolClientOrderLimitList = false;
		},
		[getSymbolClientOrderLimitList.fulfilled]: (state, action) => {
			if (action.payload.appendData) {
				state.settingSymbolClientOrderLimitList = [
					...state.settingSymbolClientOrderLimitList,
					...action.payload.data.records,
				];
			} else {
				state.settingSymbolClientOrderLimitList = action.payload.data.records;
			}
			state.settingSymbolClientOrderLimitLoading = false;
			if (action.payload.searchText) {
				state.totalRecordSettingSymbolClientOrderLimit =
					action.payload.data.records.length;
			} else {
				state.totalRecordSettingSymbolClientOrderLimit =
					action.payload.data.totalRecords;
			}
			state.startApiCallsettingSymbolClientOrderLimitList = false;
		},
		[addSettingSymbolClientOrderLimit.pending]: (state, action) => {
			state.isSettingSymbolClientOrderLimitUpdated = false;
		},
		[addSettingSymbolClientOrderLimit.rejected]: (state, action) => {
			state.isSettingSymbolClientOrderLimitUpdated = false;
		},
		[addSettingSymbolClientOrderLimit.fulfilled]: (state, action) => {
			state.isSettingSymbolClientOrderLimitUpdated = true;
		},
		[deleteSettingSymbolClientOrderLimit.pending]: (state, action) => {
			state.isSettingSymbolClientOrderLimitUpdated = false;
		},
		[deleteSettingSymbolClientOrderLimit.rejected]: (state, action) => {
			state.isSettingSymbolClientOrderLimitUpdated = false;
		},
		[deleteSettingSymbolClientOrderLimit.fulfilled]: (state, action) => {
			state.isSettingSymbolClientOrderLimitUpdated = true;
		},

		[getSymbolClientOrderLimitSymbolList.pending]: (state, action) => {
			state.symbolsOfClientOrderLimitSymbolList = [];
		},
		[getSymbolClientOrderLimitSymbolList.rejected]: (state, action) => {
			state.symbolsOfClientOrderLimitSymbolList = [];
		},
		[getSymbolClientOrderLimitSymbolList.fulfilled]: (state, action) => {
			state.symbolsOfClientOrderLimitSymbolList = action.payload;
		},
	},
});

export const {
	setSelectedAccountInSettingSymbolClientOrderLimitAddEditForm,
	setAccountSettingSymbolClientOrderLimitType,
	setIsSettingSymbolClientOrderLimitUpdated,
	setStartPageSettingSymbolClientOrderLimit,
	setSearchTextSettingSymbolClientOrderLimit,
	setSettingSymbolClientOrderLimitList,
	setTotalRecordSettingSymbolClientOrderLimit,
	setSymbolsClientOrderLimitSymbolList,
	setIsSettingClientOrderLimitGet,
} = settingClientOrderLimit.actions;

export default settingClientOrderLimit.reducer;
