import React from "react";
import "assets/css/toastnotification.css";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage } from "store/reducers/common.slice";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

function Toast() {
  const { showMessage, message, varient } = useSelector(({ common }) => common);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (showMessage === true) {
      setTimeout((s) => {
        dispatch(hideMessage());
      }, 3000);
    }
  }, [dispatch, showMessage]);

  return (
    <React.Fragment>
      <div className={showMessage === true ? varient === "successOrder" ? "show is_green" : "show" : ""} id="toast_custom">
        <div className=" flex justify-center items-center " id="img">
          {varient === "success" ? (
            <span className="material-icons-outlined" color="#45e652" size="30">
              check_circle_outline
            </span>
          ) : (
            <span className="material-icons-outlined" color="#e64550" size="30">
              info
            </span>
          )}
        </div>
        <div id="desc">{message !== undefined && message.toString()}</div>
      </div>
    </React.Fragment>
  );
}
export default Toast;
