import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showMessage } from "./common.slice";
import _ from "lodash";
import moment from "moment-timezone";
import { Search } from "./home.slice";
import { getSessionData } from "utils/common";
import { checkIsEmpty } from "utils/common";

const initialState = {
  sortMarketWatchArr: [],
  isMarketWatchUpdated: false,
  multipleSelectedScriptData: [],
  marketWatchList: new Map(),
  filteredMarketWatchList: new Map(),
  scriptIndexList: new Map(),
  nseFutWatchList: [],
  mcxWatchList: [],
  nseOptWatchList: [],
  nseCdWatchList: [],
  marketWatchLoading: false,
  addMarketWatchLoading: false,
  errorAddMarketWatch: "",
  searchTextMarketWatch: "",
  selectedScriptOption: [],
  selectedExpiryDate: {},
  liveRateFromApiList: [],
  liveRateFromSocket: {},
  expiryDatesBySymbol: [],
  selectedSegment: {},
  deleteOrderObj: {},
  selectedScript: {},
  symbolNameString: "",
  symbolNameArray: [],
  symbolIndexScriptArray: [],
  oldSymbolNameArray: [],
  selectedFinalOrderFormObject: {},
  isOpenBottomOrderForm: false,
  scriptsBySegmentOfOrderList: [],
  isSelectedFinalOrderFormObject: false,
  scriptMarketWatchList: [],
  strikeOptionsList: [],
  scriptNewScriptString: "",
  oldScriptNewScriptString: "",
  currentTab: {},
  isAddedOrder: false,
  isAddedOrderLoading: false,
  marketWatchMainList: {},
  keyCode: {},
  callPutSelectedOption: {},
  lotQtySymbolObject: {},
  symbolInOrderList: [],
  symbolListWithExpiry: [],
  symbolListWithExpiryLoading: false,
  openProfileModal: false,
  isScriptRowSelected: false,
  formBuySellValues: {
    symbol: "",
    marketType: "",
    buySell: "BUY",
    market: { value: 5, label: "Market" },
    script: "",
    lot: "",
    qty: "",
    price: "",
    client: "",
  },
  buySellFormValues: {
    buySellType: "BUY",
    orderType: { value: 5, label: "Market" },
    lot: 0,
    qty: 0,
    price: 0,
    client: undefined,
    displayName: "",
  },
  addScriptFormValues: {
    marketType: "",
    symbol: "",
    expiry: "",
    callPut: "",
    strike: "",
  },
  addOrderLoading: false,
};

let ajaxWatchListRequest = null;
let ajaxExpiryDatesRequest = null;

export const loginApiKey = createAsyncThunk(
  "/loginApi/trade",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      fetch(
        "http://www.bbdterminal.com/api/apirahul?e10adc3949ba59abbe56e057f20f883e&json=1"
      )
        .then((response) => response.text())
        .then((data) => {
          if (data && data.split("\n") && data.split("\n").length > 2) {
            let dataKey = data.split("\n")[1].split("key:")[1];
            resolve(dataKey);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getExpiryDatesBySymbol = createAsyncThunk(
  "/marketwatch/symbol/expiry/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { symbol, segmentId } = data;

      if (ajaxExpiryDatesRequest) ajaxExpiryDatesRequest.cancel("CANCEL");
      ajaxExpiryDatesRequest = axios.CancelToken.source();

      axios
        .get(`market-watch/get-expiry-dates-by-symbol/${segmentId}/${symbol}`, {
          cancelToken: ajaxExpiryDatesRequest.token,
        })
        .then(({ data }) => {
          let { status, data: dataArray } = data;
          if (status === 200 && dataArray && dataArray.length > 0) {
            resolve(dataArray);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const insertAllNewExpiryScript = createAsyncThunk(
  "/marketwatch/insert-all-new-expiry-script",
  ({ userId, segmentId }, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData()
      axios
        .get(`market-watch/Insert-all-new-expiry-script-in-watchwatch/${userId}/${segmentId}`)
        .then(({ data }) => {
          if (data?.status === 200) {
            dispatch(showMessage({ message: data.message, varient: "success" }));
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getLiveRateFromApi = createAsyncThunk(
  "/liveRage/trade",
  ({ key, token, segment }) => {
    return new Promise((resolve, reject) => {
      fetch(`http://bbdterminal.com/api/${key}/json2?${segment}&token=${token}`)
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const insertAllScriptInMarketWatch = createAsyncThunk(
  "/marketwatch/insert-all-script",
  ({ userId, segmentId }, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `market-watch/Insert-all-script-in-watchwatch/${userId}/${segmentId}`
        )
        .then(({ data }) => {
          if (data?.status === 200) {
            dispatch(showMessage({ message: data.message, varient: "success" }));
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const getSymbolWithExpiry = createAsyncThunk(
  "/marketwatch/get-symbol-with-expiry",
  ({ segmentId, symbolName }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(
          `market-watch/get-all-script-by-user/${userId}/${segmentId}${symbolName ? `?Symbol=${symbolName}` : ""}`
        )
        .then(({ data }) => {
          resolve(data?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getSegmentWiseMarketWatch = createAsyncThunk(
  "/marketwatch/segmentwise",
  ({ segmentId, pageNo, pageSize }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(`market-watch/get-market-watches-by-user/${userId}/${segmentId}`)
        .then(({ data }) => {
          if (data.status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getIndexScript = createAsyncThunk(
  "/marketwatch/indexscript",
  () => {
    return new Promise((resolve, reject) => {
      axios
        .get("script/get-index-script")
        .then(({ data }) => {
          if (data.status === 200) {
            resolve(data.data);
          }
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  }
);

export const getMarketWatchList = createAsyncThunk(
  "/marketwatch/market-watch-list",
  (data, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      const { isRefresh = false } = data || {};
      const { marketWatchList = new Map() } = getState().marketWatch;
      if (marketWatchList?.size > 0 && !isRefresh) {
        resolve([...marketWatchList.values()]);
        return;
      }

      let { userId } = getSessionData();
      if (ajaxWatchListRequest) ajaxWatchListRequest.cancel("CANCEL");
      ajaxWatchListRequest = axios.CancelToken.source();

      axios
        .get(`market-watch/get-market-watches-by-user/${userId}`, {
          cancelToken: ajaxWatchListRequest.token,
        })
        .then(({ data = {} }) => {
          let marketWatchList = [];
          marketWatchList = data?.data || [];
          resolve(marketWatchList);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const addMarketWatch = createAsyncThunk(
  "marketwatch/add",
  (paramData, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      const { marketType, callPut, symbol, expiry, strike } = paramData || {};
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.createdBy = userId;
      newFinalObject.userId = userId;
      newFinalObject.id = 0;
      newFinalObject.expiryDate = moment(expiry.label, 'DD-MM-YYYY').format('YYYY-MM-DD')
      newFinalObject.segmentId = marketType.value;
      newFinalObject.symbol = symbol.value;
      newFinalObject.script =
        !checkIsEmpty(marketType) && marketType.value !== 3
          ? symbol.value +
          " " +
          moment(expiry.label, "DD-MM-YYYY")
            .tz("Asia/Kolkata")
            .format("DDMMMYYYY")
          : symbol.value +
          " " +
          strike.label +
          " " +
          callPut.value +
          " " +
          moment(expiry.label, "DD-MM-YYYY")
            .tz("Asia/Kolkata")
            .format("DDMMMYYYY");
      newFinalObject.displayName =
        !checkIsEmpty(marketType) && marketType.value !== 3
          ? expiry.value
          : strike.value;

      const display_name = newFinalObject.displayName?.trim()?.toLowerCase();
      const symbol_name = newFinalObject.symbol?.trim()?.toLowerCase();
      let script = newFinalObject.script
        ?.split(" ")?.[0]
        ?.trim()
        ?.toLowerCase();

      // Check if display name and script are same, if not throw error
      if (!display_name.includes(symbol_name) || symbol_name !== script) {
        reject();
        dispatch(
          showMessage({ message: "Please select script again and try again" })
        );
      } else {
        axios
          .post("market-watch/save-market-watch", newFinalObject)
          .then((item) => {
            if (item.status === 200) {
              dispatch(
                showMessage({
                  message: item.data.message,
                  varient: "success",
                })
              );
              resolve(item.data.data);
            } else {
              reject(item);
            }
          })
          .catch((error) => {
            if (error.response) {
              dispatch(showMessage({ message: error.response.data.message }));
            } else if (error.request) {
              dispatch(showMessage({ message: error.request.message }));
            } else {
              dispatch(showMessage({ message: error.message }));
            }
            reject(error);
          });
      }
    });
  }
);

export const deleteMarketWatch = createAsyncThunk(
  "/market-watch/delete",
  (payload, { dispatch }) => {
    return new Promise((resolve, reject) => {
      //user info
      let { id, displayName } = payload;
      axios
        .delete(`market-watch/delete-market-watch/${id}`)
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve({ resData: data, id, displayName });
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const multipleDeleteMarketWatch = createAsyncThunk(
  "/market-watch/multidelete-marketwatch",
  (multiDeleteData, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let deleteScript = multiDeleteData.map((item) => item.id);
      axios
        .post("market-watch/multidelete-market-watch", deleteScript)
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve({ resData: data, deleteData: multiDeleteData });
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getScriptsBySegmentOfOrder = createAsyncThunk(
  "/order/scriptBySymbol/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { segmentId, symbol, date } = data;
      date = date.toISOString();
      axios
        .get(
          `order/get-scripts-by-segment/${date}/${segmentId}${symbol ? `?symbol=${symbol}` : ""
          }`
        )
        .then(({ data }) => {
          let { status, data: dataArray } = data;
          if (status === 200 && dataArray && dataArray.length > 0) {
            resolve(dataArray);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getStrikeOptionsList = createAsyncThunk(
  "/marketWatch/stike/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { segmentId, symbol, expiryDate, optType } = data;
      axios
        .get(
          `market-watch/get-strike-prices-by-symbol/${segmentId}/${symbol}/${expiryDate}/${optType}`
        )
        .then(({ data }) => {
          let { status, data: dataArray } = data;
          if (status === 200 && dataArray && dataArray.length > 0) {
            resolve(dataArray);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const addOrder = createAsyncThunk(
  "marketwatch/order/add",
  ({ data, liveDataItem: liveData, isAppView = false }, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      const baseQty = data.qty;
      let { userId, userIpFinal } = getSessionData();
      let executionType = 5;
      let dataObject = {};
      dataObject.createdBy = userId;
      dataObject.userId =
        data.client && data.client.value !== null
          ? Number(data.client.value)
          : 0;
      dataObject.id = 0;
      dataObject.deviceType = 1;
      dataObject.segmentId = liveData && liveData.segmentId;
      dataObject.script = liveData.script;
      dataObject.displayName = liveData.displayName;
      dataObject.userIp = userIpFinal;
      dataObject.orderType = data.buySell === "BUY" ? 1 : 2;
      dataObject.lot = data.lot;
      dataObject.qty = data.qty;
      dataObject.rate = data.price;
      dataObject.lowPrice = liveData.low;
      dataObject.mediumPrice = liveData.lastTradedPrice;
      dataObject.highPrice = liveData.high;
      if (data.market.value !== 5) {
        if (data.buySell === "BUY") {
          executionType = liveData.lastTradedPrice > Number(data.price) ? 1 : 2;
        } else {
          executionType = liveData.lastTradedPrice > Number(data.price) ? 4 : 3;
        }
      }
      dataObject.executionType = executionType;
      dataObject.symbol = liveData.symbol;
      // dataObject.BidRate = liveData.buyPrice;
      // dataObject.AskRate = liveData.sellPrice;
      if (
        dataObject.highPrice <= 0 ||
        dataObject.mediumPrice <= 0 ||
        dataObject.lowPrice <= 0
      ) {
        dispatch(
          showMessage({
            message: "High, Low or LTP Price should not be 0 or below",
          })
        );
        reject();
        return;
      }
      axios
        .post("order/save-order", dataObject)
        .then((res) => {
          if (res.data.status === 200) {
            if (isAppView) {
              dispatch(
                showMessage({ message: res.data.message, varient: "successOrder" })
              );
            } else {
              dispatch(
                showMessage({ message: res.data.message, varient: "success" })
              );
            }
            resolve({
              executionType: Number(data.market.value),
              status: res.data.status,
              displayName: liveData.displayName,
              netQty: liveData.netQty,
              buySell: data.buySell,
              qty: baseQty ? parseInt(baseQty) : 0,
            });
          } else {
            reject(res.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getSymbolInOrderList = createAsyncThunk(
  "/marketwatch/script/get",
  ({ segmentId, optType }, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `market-watch/get-symbols-by-segment/${segmentId}${optType ? `?symbol=${optType}` : ""
          }
        `
        )
        .then(({ data }) => {
          let { status, data: dataArray } = data;
          if (status === 200 && dataArray && dataArray.length > 0) {
            resolve(dataArray);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getLotQtyByDisplayName = createAsyncThunk(
  "/order/lotqty/get",
  ({ segmentId, displayname }, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `market-watch/get-lot-and-qty-by-displayname/${segmentId}/${displayname}`
        )
        .then(({ data }) => {
          let { status } = data;
          if (status === 200) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const marketWatchSlice = createSlice({
  name: "marketWatch",
  initialState,
  reducers: {
    setSortMarketWatch: (state, action) => {
      state.sortMarketWatchArr = action.payload;
    },
    setIsMarketWatchUpdated: (state, action) => {
      state.isMarketWatchUpdated = action.payload;
    },
    setSearchTextMarketWatch: (state, action) => {
      state.searchTextMarketWatch = action.payload;
    },
    setMarketWatchSelectedScript: (state, action) => {
      state.selectedScriptOption = action.payload;
    },
    setSelectedExpiryDate: (state, action) => {
      state.selectedExpiryDate = action.payload;
    },
    setSocketData: (state, action) => {
      state.liveRateFromSocket = action.payload;
    },
    setMarketWatchList: (state, action) => {
      const list = action.payload || [];
      let map = new Map();
      list?.forEach((x) => map.set(x?.displayName, x));
      state.marketWatchList = map;
    },
    setFilteredMarketWatchList: (state, action) => {
      const list = action.payload || [];
      let map = new Map();
      list?.forEach((x) => map.set(x?.displayName, x));
      state.filteredMarketWatchList = map;
    },
    setScriptIndexList: (state, action) => {
      const list = action.payload || [];
      let map = new Map();
      list?.forEach((x) => map.set(x?.displayName, x));
      state.scriptIndexList = map;
    },
    setDeleteOrderObj: (state, action) => {
      state.deleteOrderObj = action.payload;
    },
    setSelectedScript: (state, action) => {
      state.selectedScript = action.payload;
    },
    setSymbolNameString: (state, action) => {
      state.symbolNameString = action.payload;
    },
    setSymbolNameArray: (state, action) => {
      state.symbolNameArray = action.payload;
    },
    setSymbolIndexScriptArray: (state, action) => {
      state.symbolIndexScriptArray = action.payload;
    },
    setOldSymbolNameArray: (state, action) => {
      state.oldSymbolNameArray = action.payload;
    },
    setAppendSymbolNameString: (state, action) => {
      state.scriptNewScriptString = action.payload;
    },
    setOldScriptNewScriptString: (state, action) => {
      state.oldScriptNewScriptString = action.payload;
    },
    setSelectedFinalOrderFormObject: (state, action) => {
      state.selectedFinalOrderFormObject = action.payload;
    },
    setIsOpenBottomOrderForm: (state, action) => {
      state.isOpenBottomOrderForm = action.payload;
    },
    setSelectedSegment: (state, action) => {
      state.selectedSegment = action.payload;
    },
    setScriptsBySegmentOfOrderList: (state, action) => {
      state.scriptsBySegmentOfOrderList = action.payload;
    },
    setNseFutWatchList: (state, action) => {
      state.nseFutWatchList = action.payload;
    },
    setNseOptWatchList: (state, action) => {
      state.nseOptWatchList = action.payload;
    },
    setMcxWatchList: (state, action) => {
      state.mcxWatchList = action.payload;
    },
    setIsSelectedFinalOrderFormObject: (state, action) => {
      state.isSelectedFinalOrderFormObject = action.payload;
    },
    setNseCdWatchList: (state, action) => {
      state.nseCdWatchList = action.payload;
    },
    setScriptMarketWatchList: (state, action) => {
      state.scriptMarketWatchList = action.payload;
    },
    setExpiryDatesBySymbol: (state, action) => {
      state.expiryDatesBySymbol = action.payload;
    },
    setStrikeOptionsList: (state, action) => {
      state.strikeOptionsList = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setAddScriptFormValues: (state, action) => {
      state.addScriptFormValues = {
        ...state.addScriptFormValues,
        ...action.payload,
      };
    },
    setIsAddedOrderLoading: (state, action) => {
      state.isAddedOrderLoading = action.payload;
    },
    searchMarketWatch: (state, action) => {
      let { currentTab, searchTextMarketWatch, dataArray } = action.payload;
      // if (currentTab.value === 1) {
      //   let nseData = Search(searchTextMarketWatch, dataArray);
      //   let nseFutWatchList = [];
      //   let mcxWatchList = [];
      //   let nseOptWatchList = [];
      //   let nseCdWatchList = [];
      //   if (nseData && nseData.length > 0) {
      //     nseData.forEach((item, i) => {
      //       if (item.segmentId === 1) {
      //         nseFutWatchList.push(item);
      //       } else if (item.segmentId === 2) {
      //         mcxWatchList.push(item);
      //       } else if (item.segmentId === 3) {
      //         nseOptWatchList.push(item);
      //       } else {
      //         nseCdWatchList.push(item);
      //       }
      //       state.nseFutWatchList = nseFutWatchList;
      //       state.mcxWatchList = mcxWatchList;
      //       state.nseOptWatchList = nseOptWatchList;
      //       state.nseCdWatchList = nseCdWatchList;
      //     });
      //   } else {
      //     state.nseFutWatchList = [];
      //     state.mcxWatchList = [];
      //     state.nseOptWatchList = [];
      //     state.nseCdWatchList = [];
      //   }
      // }
      if (currentTab.value === 1) {
        state.nseFutWatchList = Search(searchTextMarketWatch, dataArray);
      } else if (currentTab.value === 2) {
        state.mcxWatchList = Search(searchTextMarketWatch, dataArray);
      } else if (currentTab.value === 3) {
        state.nseOptWatchList = Search(searchTextMarketWatch, dataArray);
      } else if (currentTab.value === 4) {
        state.nseCdWatchList = Search(searchTextMarketWatch, dataArray);
      }
    },
    setIsAddedOrder: (state, action) => {
      state.isAddedOrder = action.payload;
    },
    setMarketWatchMainList: (state, action) => {
      state.marketWatchMainList = action.payload;
    },
    setKeyCode: (state, action) => {
      state.keyCode = action.payload;
    },
    setCallPutSelectedOption: (state, action) => {
      state.callPutSelectedOption = action.payload;
    },
    setOpenProfileModal: (state, action) => {
      state.openProfileModal = action.payload;
    },
    setMultipleSelectedScriptData: (state, action) => {
      state.multipleSelectedScriptData = action.payload;
    },
    setIsScriptRowSelected: (state, action) => {
      state.isScriptRowSelected = action.payload;
    },
    setFormBuySellValues: (state, action) => {
      state.formBuySellValues = action.payload;
    },
    setSymbolListWithExpiry: (state, action) => {
      state.symbolListWithExpiry = action.payload;
    },
    setBuySellFormValues: (state, action) => {
      state.buySellFormValues = {
        ...state.buySellFormValues,
        ...action.payload,
      };
    },
    clearBuySellFormValues: (state, action) => {
      state.buySellFormValues = JSON.parse(
        JSON.stringify(initialState.buySellFormValues)
      );
    },
  },
  extraReducers: {
    [getLiveRateFromApi.pending]: (state, action) => {
      state.liveRateFromApiList = [];
    },
    [getLiveRateFromApi.rejected]: (state, action) => {
      state.liveRateFromApiList = [];
    },
    [getLiveRateFromApi.fulfilled]: (state, action) => {
      state.liveRateFromApiList = action.payload;
    },
    [getIndexScript.pending]: (state, action) => {
      // state.scriptIndexList = []
    },
    [getIndexScript.rejected]: (state, action) => {
      // state.scriptIndexList = []
    },
    [getIndexScript.fulfilled]: (state, action) => {
      let map = new Map();
      const list = action.payload || [];
      list?.forEach((x) => map.set(x?.displayName, x));
      const newString = list?.map(curVal => curVal.displayName)
      if (newString) {
        // state.symbolNameArray = newString
        state.symbolIndexScriptArray = newString || ''
      }
      state.scriptIndexList = map;
      state.symbolNameString = newString
    },
    //get market wathch
    [getMarketWatchList.pending]: (state, action) => {
      state.isMarketWatchUpdated = false;
      state.marketWatchLoading = true;
    },
    [getMarketWatchList.rejected]: (state, action) => {
      state.isMarketWatchUpdated = false;
      state.marketWatchLoading = false;
    },
    [getMarketWatchList.fulfilled]: (state, action) => {
      let map = new Map();
      let list = action.payload || [];
      list = _.map(list, (o, index) =>
        _.extend({ isCheck: o.isCheck ? o.isCheck : false }, o)
      );
      list?.forEach((x) => map.set(x?.displayName, x));
      state.marketWatchList = map;
      // state.symbolNameString = list?.reduce(
      //   (acc, curVal) => `${curVal.displayName},${acc}`,
      //   ""
      // );
      state.isMarketWatchUpdated = false;
      state.marketWatchLoading = false;
    },
    //add
    [addMarketWatch.pending]: (state, action) => {
      state.addMarketWatchLoading = true;
      state.isMarketWatchUpdated = false;
    },
    [addMarketWatch.rejected]: (state, action) => {
      state.isMarketWatchUpdated = false;
      state.addMarketWatchLoading = false;
    },
    [addMarketWatch.fulfilled]: (state, action) => {
      let addedObject = _.omit(action.payload, ["createdBy"]);
      const data = new Map(state.marketWatchList);
      data.set(addedObject?.displayName, addedObject);
      state.marketWatchList = data;
      state.addMarketWatchLoading = false;
      state.isMarketWatchUpdated = true;
    },

    [deleteMarketWatch.pending]: (state, action) => {
      state.isMarketWatchUpdated = false;
    },
    [deleteMarketWatch.rejected]: (state, action) => {
      state.isMarketWatchUpdated = false;
    },
    [deleteMarketWatch.fulfilled]: (state, action) => {
      state.isMarketWatchUpdated = true;
      const data = new Map(state.marketWatchList);
      data.delete(action?.payload?.displayName);
      state.marketWatchList = data;
    },
    [multipleDeleteMarketWatch.pending]: (state, action) => {
      state.isMarketWatchUpdated = false;
    },
    [multipleDeleteMarketWatch.rejected]: (state, action) => {
      state.isMarketWatchUpdated = false;
    },
    [multipleDeleteMarketWatch.fulfilled]: (state, action) => {
      if (action?.payload?.deleteData?.length > 0) {
        const multiDeleteData = [...action.payload.deleteData];
        let marketWatchListData = new Map(state.marketWatchList);
        let filteredMarketWatchListData = new Map(
          state.filteredMarketWatchList
        );
        multiDeleteData.map((item) => {
          marketWatchListData.delete(item.displayName);
          filteredMarketWatchListData.delete(item.displayName);
        });
        state.isMarketWatchUpdated = true;
        state.marketWatchList = marketWatchListData;
        state.filteredMarketWatchList = filteredMarketWatchListData;
      }
    },

    [addOrder.pending]: (state, action) => {
      state.isAddedOrderLoading = true;
      state.isAddedOrder = false;
      state.addOrderLoading = true;
    },
    [addOrder.rejected]: (state, action) => {
      state.isAddedOrderLoading = false;
      state.isAddedOrder = false;
      state.addOrderLoading = false;
    },
    [addOrder.fulfilled]: (state, action) => {
      const marketWatchListData = new Map(state.marketWatchList);
      const filteredMarketWatchListData = new Map(
        state.filteredMarketWatchList
      );
      let obj = marketWatchListData.get(action.payload.displayName);
      let selectScript = state.selectedScript;
      if (action.payload.executionType === 5) {
        if (action.payload.buySell === "BUY") {
          if (
            selectScript &&
            Object.keys(selectScript).length > 0 &&
            selectScript?.displayName === action.payload.displayName
          ) {
            selectScript = {
              ...selectScript,
              netQty: action.payload.netQty + action.payload.qty,
            };
          }
          marketWatchListData.set(obj.displayName, {
            ...obj,
            netQty: action.payload.netQty + action.payload.qty,
          });
          filteredMarketWatchListData.set(obj.displayName, {
            ...obj,
            netQty: action.payload.netQty + action.payload.qty,
          });
        } else {
          if (
            selectScript &&
            Object.keys(selectScript).length > 0 &&
            selectScript?.displayName === action.payload.displayName
          ) {
            selectScript = {
              ...selectScript,
              netQty: action.payload.netQty - action.payload.qty,
            };
          }
          marketWatchListData.set(obj.displayName, {
            ...obj,
            netQty: action.payload.netQty - action.payload.qty,
          });
          filteredMarketWatchListData.set(obj.displayName, {
            ...obj,
            netQty: action.payload.netQty - action.payload.qty,
          });
        }
      }
      state.selectedScript = selectScript;
      state.marketWatchList = marketWatchListData;
      state.filteredMarketWatchList = filteredMarketWatchListData;
      state.isAddedOrderLoading = false;
      state.isAddedOrder = true;
      state.addOrderLoading = false;
    },

    [getSymbolInOrderList.pending]: (state, action) => {
      // state.symbolMarketWatchList = [];
    },
    [getSymbolInOrderList.rejected]: (state, action) => {
      state.symbolInOrderList = [];
    },
    [getSymbolInOrderList.fulfilled]: (state, action) => {
      state.symbolInOrderList = action.payload;
    },

    //getSymbolExpiryFromSymbol market watch
    [getExpiryDatesBySymbol.pending]: (state, action) => {
      // state.expiryDatesBySymbol = [];
    },
    [getExpiryDatesBySymbol.rejected]: (state, action) => {
      state.expiryDatesBySymbol = [];
    },
    [getExpiryDatesBySymbol.fulfilled]: (state, action) => {
      state.expiryDatesBySymbol = action.payload;
    },

    [getScriptsBySegmentOfOrder.pending]: (state, action) => { },
    [getScriptsBySegmentOfOrder.rejected]: (state, action) => {
      state.scriptsBySegmentOfOrderList = [];
    },
    [getScriptsBySegmentOfOrder.fulfilled]: (state, action) => {
      state.scriptsBySegmentOfOrderList = action.payload;
    },

    [getStrikeOptionsList.pending]: (state, action) => {
      // state.strikeOptionsList = [];
    },
    [getStrikeOptionsList.rejected]: (state, action) => {
      state.strikeOptionsList = [];
    },
    [getStrikeOptionsList.fulfilled]: (state, action) => {
      state.strikeOptionsList = action.payload;
    },

    [getLotQtyByDisplayName.pending]: (state, action) => {
      // state.LotQtyBySymbol = [];
    },
    [getLotQtyByDisplayName.rejected]: (state, action) => {
      state.lotQtySymbolObject = [];
    },
    [getLotQtyByDisplayName.fulfilled]: (state, action) => {
      state.lotQtySymbolObject = action.payload;
    },
    // symbol with expiry
    [getSymbolWithExpiry.pending]: (state, action) => {
      state.symbolListWithExpiry = [];
      state.symbolListWithExpiryLoading = true;
    },
    [getSymbolWithExpiry.rejected]: (state, action) => {
      state.symbolListWithExpiry = [];
      state.symbolListWithExpiryLoading = false;
    },
    [getSymbolWithExpiry.fulfilled]: (state, action) => {
      state.symbolListWithExpiry = action.payload;
      state.symbolListWithExpiryLoading = false;
    },
  },
});

export const {
  setSortMarketWatch,
  setSymbolListWithExpiry,
  fetchMasterWatchRequest,
  fetchMasterWatchSuccess,
  setIsMarketWatchUpdated,
  setSearchTextMarketWatch,
  setMarketWatchSelectedScript,
  setSelectedExpiryDate,
  setSocketData,
  setMarketWatchList,
  setFilteredMarketWatchList,
  setDeleteOrderObj,
  setSelectedScript,
  setSymbolNameString,
  setSymbolNameArray,
  setSymbolIndexScriptArray,
  setOldSymbolNameArray,
  setOldScriptNewScriptString,
  setSelectedFinalOrderFormObject,
  setIsOpenBottomOrderForm,
  setSelectedSegment,
  setScriptsBySegmentOfOrderList,
  setSelectedScriptOrderFinal,
  setNseFutWatchList,
  setMcxWatchList,
  setNseOptWatchList,
  setNseCdWatchList,
  setIsSelectedFinalOrderFormObject,
  setScriptMarketWatchList,
  setAppendSymbolNameString,
  setExpiryDatesBySymbol,
  setStrikeOptionsList,
  setCurrentTab,
  searchMarketWatch,
  setIsAddedOrder,
  setMarketWatchMainList,
  setAddScriptFormValues,
  setIsAddedOrderLoading,
  setKeyCode,
  setCallPutSelectedOption,
  setOpenProfileModal,
  setMultipleSelectedScriptData,
  setIsScriptRowSelected,
  setFormBuySellValues,
  setBuySellFormValues,
  clearBuySellFormValues,
  setScriptIndexList,
} = marketWatchSlice.actions;

export default marketWatchSlice.reducer;
