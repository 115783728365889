import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import { getSessionData,checkIsEmpty } from "utils/common";
import { generatePDF, exportToExcel } from "utils/reportGenerator";
import moment from "moment";

const initialState = {
  value: 0,
  sortSummaryArr: [{ id: "code", desc: false }],
  homeDashboardList: [],
  homeDashboardLoading: false,
  sortM2MAlertArr: [],
  sortUserArr: [],
  summaryOrderList: [],
  dataLimitSummary: 30,
  totalRecordSummaryUser: 0,
  currentPageSummaryOrder: 1,
  summaryList: [],
  summaryLoading: false,
  m2mAlertList: [],
  m2mAlertLoading: false,
  searchTextHomeSummary: "",
  totalRecordHomeSummarys: 0,
  summaryStats:0,
  currentPageHomeSummarys: 1,
  openModalSummaryUser: false,
  openModalSummaryOrder: false,
  openModalSummaryTrade: false,
  openModalSummaryPosition: false,
  openSummaryUserViewData: {},
  openSummaryUserViewDataOld: [],
  summaryViewData: {},
  //users
  homeUsersList: [],
  homeUsersListLoading: false,
  searchTextHomeUsers: "",
  searchTextM2MAlert: "",
  searchTextUserLoginHistory: "",
  currentPageHomeUsers: 1,
  pageSizeHomeUsers: 10,
  totalRecordHomeUsers: 0,
  startApiCallHomeUsersList: true,
  openModalUsersHistory: false,
  openModalUsersHistoryRowData: {},
  userLoginHistoryList: [],
  userLoginHistoryLoading: false,
  startApiCallHomeUsersLoginList: false,
  currentPageHomeUsersLoginHistory: 1,
  pageSizeHomeUsersLoginHistory: 10,
  totalRecordHomeUsersLoginHistory: 0,
  pageSizeSummaryList: 10,
  filterData: {},
  totalRecordM2MAlert: 0,
};
const summaryOrderListDummy = [];
const dataRunningOrder = [];
const tradesListDummy = [];
const m2mAlertListDummy = [];
const executedOrdersListDummy = [];

export const Search = (nameKey, myArray) => {
  let arrayObject = [];

  let flag = false;
  for (let i = 0; i < myArray.length; i++) {
    if (flag) {
      flag = false;
      continue;
    }
    let itemObj = myArray[i];
    Object.keys(itemObj).forEach((key) => {
      if (flag) {
        return false;
      }
      if (
        nameKey !== "+" &&
        String(itemObj[key]).toLowerCase().search(nameKey.toLowerCase()) >= 0
      ) {
        arrayObject.push(itemObj);
        flag = true;
        return false;
      }
    });
    if (flag) {
      flag = false;
      continue;
    }
  }
  return arrayObject;
};

export const getHomeDashboardList = createAsyncThunk(
  "home/DashboardAll",
  (data) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let finalObject = {};
      finalObject.userId = userId;
      finalObject.segmentId = data && data.segment ? data.segment.value : null;
      finalObject.symbol = data && data.script ? data.script.value : null;
      finalObject.clientId = data && data.client ? data.client.value : null;
      axios
        .post(`home/dashboard/get-dashboard-data`, finalObject)
        .then(({ data }) => {
          resolve({ data: data.data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getSummaryOrderList = createAsyncThunk(
  "summary/orderlist",
  ({ userId, searchText, pageNo, pageSize, appendData }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `home/summary/get-summary/${userId}/${pageNo}/${pageSize}${
            searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          resolve({ data: data.data, appendData });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getDashboardRunningOrdersList = createAsyncThunk(
  "dashboardrunningorder",
  () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(dataRunningOrder);
      }, 1000);
    });
  }
);

export const getSummayList = createAsyncThunk(
  "homesummay",
  ({ pageNo, pageSize, searchText,WithZeroLedger=false, appendData }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(
          `home/summary/get-summary/${userId}/${pageNo}/${pageSize}${
            searchText ? `?searchText=${searchText}` : ""
          }${
            (searchText ? '&' : '?') +`WithZeroLedger=${WithZeroLedger}`
          }`
        )
        .then(({ data }) => {
          resolve({ data: data.data, appendData });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getHomeUserLoginHistory = createAsyncThunk(
  "get/homeUserLogin/history",
  (data, { getState }) => {
    return new Promise((resolve, reject) => {
      let { searchText, pageNo, pageSize, appendData, id } = data;
      let stateData = getState();
      axios
        .get(
          `home/user/get-user-login-detail/${
            stateData.home.openModalUsersHistoryRowData.id
          }/${pageNo}/${pageSize}${
            searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          resolve({ data: data.data, appendData });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getUsersHomeList = createAsyncThunk(
  "/home/users",
  ({ searchText, pageNo, pageSize, appendData }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(
          `home/user/get-home-users/${userId}/${pageNo}/${pageSize}${
            searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          resolve({ data: data.data, appendData });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getM2MAletList = createAsyncThunk(
  "home/m2malert",
  ({ searchText, pageNo, pageSize, appendData }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(
          `home/m2m/get-m2m-alert/${userId}/${pageNo}/${pageSize}${
            searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          resolve({ data: data.data, appendData });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const exportSummary = createAsyncThunk(
  "/export/Summary",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId, roles } = getSessionData();
      let dataObject = {};
      const tableColumn = [
        { dataKey: "id", title: "ID" },
        { dataKey: "accountCode", title: "accountCode" },
        { dataKey: "accountTypeName", title: "accountTypeName" },
        { dataKey: "accountName", title: "accountName" },
        { dataKey: "margin", title: "margin" },
        { dataKey: "usedMargin", title: "usedMargin" },
        { dataKey: "balanceMargin", title: "balanceMargin" },
        { dataKey: "ledgerBalance", title: "ledgerBalance" },
        { dataKey: "fatakBalance", title: "fatakBalance" },
        { dataKey: "liveM2M", title: "liveM2M" },
        { dataKey: "mySharing", title: "mySharing" },
        { dataKey: "netProfitLoss", title: "netProfitLoss" },
        { dataKey: "netProfitLossPartner", title: "netProfitLossPartner" },
        { dataKey: "isActive", title: "isActive" },
        { dataKey: "freeMargin", title: "freeMargin" },
        { dataKey: "requiredCFMargin", title: "requiredCFMargin" },
        { dataKey: "cfMargin", title: "cfMargin" },
        { dataKey: "netBalance", title: "netBalance" },
      ];
      const {
        searchText,
        pageNo,
        pageSize,
        WithZeroLedger,
        appendData,
        exporttype,
      } = data;
     
      axios
        .get(
          `home/summary/get-summary/${userId}/${pageNo}/${pageSize}${
            searchText ? `?searchText=${searchText}` : ""
          }${
            (searchText ? '&' : '?') +`WithZeroLedger=${WithZeroLedger}`
          }`
        )
        .then(({ data }) => {
          if (data.status == 200) {
            let finalExcelData = data.data.records.map((item) =>
              _.omit(item, ["userId"])
            );
            console.log(finalExcelData);
            let exportData = {
              FileName: "Summary",
              exportColumn: tableColumn,
              arrayName: finalExcelData,
              title: "Summary",
              theme: "grid",
              headStyles: { with: "50px", fillColor: [46, 142, 207] },
              date: moment().format("DD-MM-YYYY"),
              reportType: "Summary",
            };
            if(exporttype=='pdf'){
              generatePDF(exportData);   
            }else{
              exportToExcel({
              apiData: finalExcelData,
              fileName: "Summary",
            });
            }
          
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setSortOrderSummary: (state, action) => {
      state.sortSummaryArr = action.payload;
    },
    setSortOrderM2MAleart: (state, action) => {
      state.sortM2MAlertArr = action.payload;
    },
    setSortUser: (state, action) => {
      state.sortUserArr = action.payload;
    },
    setSearchTextHomeSummary: (state, action) => {
      state.searchTextHomeSummary = action.payload;
    },
    setStartPageHomeSummaryList: (state, action) => {
      state.currentPageHomeSummarys = action.payload;
    },
    setCurrentPageSummaryOrder: (state, action) => {
      state.currentPageSummaryOrder = action.payload;
    },
    setSearchTextUserLoginHistory: (state, action) => {
      state.searchTextUserLoginHistory = action.payload;
    },
    setSearchTextHomeUsers: (state, action) => {
      state.searchTextHomeUsers = action.payload;
    },
    setSearchTextM2MAlert: (state, action) => {
      state.searchTextM2MAlert = action.payload;
    },
    setStartPageHomeUsers: (state, action) => {
      state.currentPageHomeUsers = action.payload;
    },
    setStartApiCallHomeUsersList: (state, action) => {
      state.startApiCallHomeUsersList = action.payload;
    },
    setTotalRecordHomeUsers: (state, action) => {
      state.totalRecordHomeUsers = action.payload;
    },
    setTotalRecordHomeSummarys: (state, action) => {
      state.totalRecordHomeSummarys = action.payload;
    },
    setsummaryStats: (state, action) => {
      state.summaryStats = action.payload;
    },
    setOpenModalUsersHistory: (state, action) => {
      state.openModalUsersHistory = action.payload;
    },
    setOpenModalUsersHistoryRowData: (state, action) => {
      state.openModalUsersHistoryRowData = action.payload;
    },
    setStartPageHomeUserLoginHistory: (state, action) => {
      state.currentPageHomeUsersLoginHistory = action.payload;
    },
    setStartApiCallHomeUserLoginHistory: (state, action) => {
      state.startApiCallHomeUsersLoginList = action.payload;
    },
    setHomeUsersList: (state, action) => {
      state.homeUsersList = action.payload;
    },
    setHomeDashboardList: (state, action) => {
      state.homeDashboardList = action.payload;
    },
    setOpenModalSummaryUser: (state, action) => {
      state.openModalSummaryUser = action.payload;
    },
    setOpenModalSummaryOrder: (state, action) => {
      state.openModalSummaryOrder = action.payload;
    },
    setOpenModalSummaryTrade: (state, action) => {
      state.openModalSummaryTrade = action.payload;
    },
    setOpenModalSummaryPosition: (state, action) => {
      state.openModalSummaryPosition = action.payload;
    },
    setOpenSummaryUserViewData: (state, action) => {
      state.openSummaryUserViewData = action.payload;
    },
    setOpenSummaryUserViewDataOld: (state, action) => {
      if (action.payload.appendData) {
        state.openSummaryUserViewDataOld = action.payload.dataObject;
      } else {
        state.openSummaryUserViewDataOld = [
          ...state.openSummaryUserViewDataOld,
          action.payload.dataObject,
        ];
      }
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    setSummaryViewData: (state, action) => {
      state.summaryViewData = action.payload;
    },
    setSummaryList: (state, action) => {
      state.summaryList = action.payload;
    },
    setTotalRecordSummaryOrder: (state, action) => {
      state.totalRecordSummaryUser = action.payload;
    },
    setTotalRecordM2MAlert: (state, action) => {
      state.totalRecordM2MAlert = action.payload;
    },
    setCurrentPageHomeSummarys: (state, action) => {
      state.currentPageHomeSummarys = action.payload;
    },
    setSummaryOrderList: (state, action) => {
      state.summaryOrderList = [];
    },
  },
  extraReducers: {
    [getSummaryOrderList.pending]: (state, action) => {
      state.summaryLoading = true;
    },
    [getSummaryOrderList.rejected]: (state, action) => {
      state.summaryOrderList = [];
      state.summaryLoading = false;
    },
    [getSummaryOrderList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.summaryOrderList = [
          ...state.summaryOrderList,
          ...action.payload.data.records,
        ];
        state.totalRecordSummaryUser = action.payload.data.totalRecords;
      } else {
        state.summaryOrderList = action.payload.data.records;
        state.totalRecordSummaryUser = action.payload.data.totalRecords;
      }
      state.summaryLoading = false;
    },
    //get summary
    [getSummayList.pending]: (state, action) => {
      state.summaryList = [];
      state.summaryLoading = true;
    },
    [getSummayList.rejected]: (state, action) => {
      state.summaryList = [];
      state.summaryLoading = false;
    },
    [getSummayList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.summaryList = [
          ...state.summaryList,
          ...action.payload.data.records,
        ];
      } else {
        state.summaryList = action.payload.data.records;
      }
      state.totalRecordHomeSummarys = action.payload.data.totalRecords;
      state.summaryStats = action.payload.data.summaryStats;
      state.summaryLoading = false;
    },
    //get getM2MAletList
    [getM2MAletList.pending]: (state, action) => {
      state.m2mAlertList = [];
      state.m2mAlertLoading = true;
    },
    [getM2MAletList.rejected]: (state, action) => {
      state.m2mAlertList = [];
      state.totalRecordM2MAlert = 0;
      state.m2mAlertLoading = false;
    },
    [getM2MAletList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.m2mAlertList = [
          ...state.m2mAlertList,
          ...action.payload.data.records,
        ];
      } else {
        state.m2mAlertList = action.payload.data.records;
      }
      state.totalRecordM2MAlert = action.payload.data.totalRecords;
      state.m2mAlertLoading = false;
    },
    //users
    [getUsersHomeList.pending]: (state, action) => {
      state.startApiCallHomeUsersList = false;
      state.homeUsersListLoading = true;
    },
    [getUsersHomeList.rejected]: (state, action) => {
      state.startApiCallHomeUsersList = false;
      state.homeUsersList = [];
      state.totalRecordHomeUsers = 0;
      state.homeUsersListLoading = false;
    },
    [getUsersHomeList.fulfilled]: (state, action) => {
      state.startApiCallHomeUsersList = false;
      if (action.payload.appendData) {
        state.homeUsersList = [
          ...state.homeUsersList,
          ...action.payload.data.records,
        ];
      } else {
        state.homeUsersList = action.payload.data.records;
      }
      state.totalRecordHomeUsers = action.payload.data.totalRecords;
      state.homeUsersListLoading = false;
    },

    [getHomeUserLoginHistory.pending]: (state, action) => {
      state.startApiCallHomeUsersLoginList = false;
      state.userLoginHistoryLoading = true;
    },
    [getHomeUserLoginHistory.rejected]: (state, action) => {
      state.startApiCallHomeUsersLoginList = false;
      state.userLoginHistoryList = [];
      state.totalRecordHomeUsers = 0;
      state.userLoginHistoryLoading = false;
    },
    [getHomeUserLoginHistory.fulfilled]: (state, action) => {
      state.startApiCallHomeUsersLoginList = false;
      if (action.payload.appendData) {
        state.userLoginHistoryList = [
          ...state.userLoginHistoryList,
          ...action.payload.data.records,
        ];
      } else {
        state.userLoginHistoryList = action.payload.data.records;
      }
      state.totalRecordHomeUsersLoginHistory = action.payload.data.totalRecords;
      state.userLoginHistoryLoading = false;
    },
    [getHomeDashboardList.pending]: (state, action) => {
      // state.homeDashboardList = [];
      state.homeDashboardLoading = true;
    },
    [getHomeDashboardList.rejected]: (state, action) => {
      // state.homeDashboardList = [];
      state.homeDashboardLoading = false;
    },
    [getHomeDashboardList.fulfilled]: (state, action) => {
      state.homeDashboardList = action.payload.data;
      state.homeDashboardLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  incrementByAmount,
  setSortOrderSummary,
  setSortOrderM2MAleart,
  setSortUser,
  setSearchTextHomeSummary,
  setStartPageHomeSummaryList,
  setStartPageHomeUsers,
  setStartApiCallHomeUsersList,
  setTotalRecordHomeUsers,
  setSearchTextHomeUsers,
  setOpenModalUsersHistory,
  setOpenModalUsersHistoryRowData,
  setStartPageHomeUserLoginHistory,
  setStartApiCallHomeUserLoginHistory,
  setTotalRecordHomeSummarys,
  setsummaryStats,
  setHomeUsersList,
  setOpenModalSummaryUser,
  setOpenModalSummaryTrade,
  setOpenModalSummaryPosition,
  setOpenSummaryUserViewData,
  setOpenSummaryUserViewDataOld,
  setFilterData,
  setSummaryViewData,
  setSummaryList,
  setTotalRecordSummaryOrder,
  setCurrentPageHomeSummarys,
  setOpenModalSummaryOrder,
  setTotalRecordM2MAlert,
  setSearchTextM2MAlert,
  setSearchTextUserLoginHistory,
  setHomeDashboardList,
  setCurrentPageSummaryOrder,
  setSummaryOrderList,
} = homeSlice.actions;

export default homeSlice.reducer;
