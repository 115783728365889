import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Form, ErrorMessage } from "formik";
import "../../assets/scss/mobile/newStyleMobile.scss";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import {
  getTradeBookList,
  setTradeBookSearchText,
  setStartPageTradeBook,
  setTradeBookList,
  setTotalRecordTradeBook,
  setTradeBookFilterValue,
  setFilterEndDate,
  setFilterStartDate
} from 'store/reducers/tradeBook.slice';
import { setAccountOptionList, getSegmentOptionList, getSymbolList } from 'store/reducers/common.slice';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { stylesSelect, DropdownIndicator, ClearIndicator } from "styled/select";
import { Button, Spinner, Modal } from 'reactstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';

export default function MobileNewTradeBook() {
  const {
    tradeBookList,
    tradeBookLoading,
    tradeBookSearchText,
    totalRecordTradeBook,
    currentPageTradeBook,
    filterStartDate,
    filterEndDate,
    tradeBookFilterValue,
  } = useSelector(({ tradeBook }) => tradeBook);
  const { segmentsList, symbolList } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const [addFilterModal, setFilterModal] = useState(false);
  const [data, setData] = useState(undefined);
  const [infineHeight, setInfineHeight] = useState("650px");
  useEffect(() => {
    dispatch(
      setTradeBookFilterValue({
        ...tradeBookFilterValue,
        searchText: "",
        pageNo: 1,
        pageSize: 20,
        buyOrSell: null,
        client: null,
        segment: null,
        subBrokerId: null,
        symbol: null,
        partnerId: null,
        maintenanceType: { value: 2, label: "All" },
        excludeSB: false,
      })
    );
    dispatch(setTotalRecordTradeBook(0));
    dispatch(getSegmentOptionList());
    return () => {
      setData(undefined);
      dispatch(setTradeBookSearchText(""));
      dispatch(setStartPageTradeBook(1));
      dispatch(setTradeBookList(undefined));
      dispatch(setAccountOptionList([]));
      dispatch(setTotalRecordTradeBook(0));
    };
  }, []);
  useEffect(() => {
    dispatch(
      getTradeBookList({
        ...tradeBookFilterValue,
        searchText: '',
        pageNo: 1,
        pageSize: 20,
        appendData: false,
        startDate: new Date(),
        endDate: new Date(),
      })
    );
  }, []);
  useEffect(() => {
    if (tradeBookList) {
      let mainHeight = tradeBookList.length * 25 + 150;
      if (mainHeight <= 500) {
        setInfineHeight(mainHeight + "px");
      } else {
        setInfineHeight("650px");
      }

      setData(tradeBookList);
    } else {
      setInfineHeight("200px");
      setData(undefined);
    }
  }, [tradeBookList, dispatch]);
  const buySelloptions = [
    { value: 1, label: "BUY" },
    { value: 2, label: "SELL" },
  ];
  const tradeBookSchema = Yup.object().shape({
    startDate: Yup.date().required("*Required field"),
    endDate: Yup.date().required("*Required field"),
  });
  function fetchMoreDataInTable() {
    if (data?.length < totalRecordTradeBook) {
      dispatch(setStartPageTradeBook(currentPageTradeBook + 1));
      dispatch(
        getTradeBookList({
          ...tradeBookFilterValue,
          pageNo: currentPageTradeBook + 1,
          pageSize: 20,
          searchText: tradeBookSearchText,
          appendData: true,
        })
      );
    }
  }
  const debounceHandleTradeBookSearchTextChange = React.useCallback(
    _.debounce(searchTradeBookTextDataDebounce, 800),
    []
  );
  function searchTradeBookTextDataDebounce(e, tradeBookFilterValue) {
    dispatch(setTradeBookSearchText(e.target.value));
    dispatch(setStartPageTradeBook(1));
    dispatch(
      getTradeBookList({
        ...tradeBookFilterValue,
        searchText: e.target.value,
        pageNo: 1,
        pageSize: 20,
        startDate: filterStartDate,
        endDate: filterEndDate,
      })
    );
  }
  const handleResetHandler = useCallback(
    async () => {
      const tradeBookFilterValueObjv = {
        ...tradeBookFilterValue,
        searchText: "",
        pageNo: 1,
        pageSize: 20,
        buyOrSell: null,
        client: null,
        segment: null,
        subBrokerId: null,
        symbol: null,
        partnerId: null,
        maintenanceType: { value: 2, label: "All" },
        excludeSB: false,
      }
      await dispatch(setStartPageTradeBook(1));
      await dispatch(
        getTradeBookList(tradeBookFilterValueObjv)
      );
      await dispatch(setTradeBookFilterValue(tradeBookFilterValueObjv));
      setFilterModal(!addFilterModal);
    },
    [addFilterModal, tradeBookFilterValue, dispatch],
  )

  return (
    <div className='mtradebook_main_wrap'>
      <header>
        <div className='tradebook_header_wrap'>
          <div className='order_top_header tradebook_top_header'>
            <div className='d-flex justify-content-between align-items-center'>
              <h2 className='text_blue fw_bold'>Trades Book</h2>
              <span className="material-symbols-outlined text_blue filter_icon" onClick={() => setFilterModal(!addFilterModal)}>
                filter_alt
              </span>
            </div>
            <div className='order_search'>
              <div className="searchBar"><span className="material-symbols-outlined text_blue">search</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Script"
                  onChange={(e) =>
                    debounceHandleTradeBookSearchTextChange(e, tradeBookFilterValue)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className='mtradebook_inner_wrap'>
          <div className='mtradebook_row_wrap px_12'>
            <div
              id="custom_table_observer"
              className="table-responsive table-responsive-y trade_custom_height"
              style={{
                maxHeight: infineHeight,
              }}
            >
              <InfiniteScroll
                dataLength={data?.length || 0}
                next={fetchMoreDataInTable}
                hasMore={data?.length < totalRecordTradeBook}
                scrollableTarget="custom_table_observer"
              >
                <div className='mtradebook_row'>
                  <ul>
                    {data?.map((trade) => {
                      return (
                        <li className='bg_blue_box trder_box'>
                          <div className='row g-2 align-items-center'>
                            <div className='col-6'>
                              <div className="d-flex align-items-center">
                                <p
                                  className={
                                    trade?.orderTypeName === "Buy"
                                      ? "bedge_blue bedge"
                                      : "red_bedge bedge"
                                  }
                                >
                                  {trade?.orderTypeName}
                                </p>
                                <p className='text_small mb-2 pl-1'>
                                  {trade?.tradeOptionName}
                                </p>
                              </div>
                            </div>
                            <div className='col-6'>
                              <ul className='d-flex justify-content-end align-items-center pending_order_wrap'>
                                <li>
                                  <p className='text_small d-flex align-items-center'>
                                    <span className="material-symbols-outlined schedule_icon">
                                      schedule
                                    </span>
                                    {trade?.addTime}
                                  </p>
                                </li>
                              </ul>
                            </div>
                            <div className='col-6'>
                              <p className='text_mediam text_blue fw_bold'>{trade?.script}</p>
                            </div>
                            <div className='col-6'>
                              <p className='text_small text_grey text-right'>Price<span className='text_blue text_small pl-1 fw_bold'>{trade?.rate?.toFixed(2)}</span></p>
                            </div>
                            <div className='col-4'>
                              <p className='text_blue text_small'>Qty.<span className='fw_bold pl-1'>{trade?.quantity}</span></p>
                            </div>
                            <div className='col-4'>
                              <p className='text_blue text_small'>Lot.<span className='fw_bold pl-1'>{trade?.lot?.toFixed(2)}</span></p>
                            </div>
                            <div className='col-4'>
                              <p className='text_blue text-right text_small'>Net Rate.<span className='text_blue fw_bold pl-1'>{trade?.netRate?.toFixed(2)}</span></p>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
        {tradeBookLoading && (
          <div className="spinner-box">
            <Spinner className="ml-2" color="primary" />
          </div>
        )}
        {!tradeBookLoading && (data && data?.length === 0) && (
          <h4 className="d-flex justify-content-center text-danger mt-2">
            Data Not Found
          </h4>
        )
        }
        <Modal
          className="modal-dialog-centered filter_modal"
          centered
          style={{ zIndex: 999999 }}
          isOpen={addFilterModal}
          toggle={() => setFilterModal(!addFilterModal)}
        >
          <div className="ModalBody">
            <h4>Filter</h4>
            <Formik
              enableReinitialize={true}
              initialValues={tradeBookFilterValue}
              validationSchema={tradeBookSchema}
              onSubmit={async (values, { resetForm }) => {
                await dispatch(setTradeBookFilterValue(values));
                await dispatch(setStartPageTradeBook(1));
                await dispatch(
                  getTradeBookList({
                    searchText: tradeBookSearchText,
                    pageNo: currentPageTradeBook,
                    pageSize: 20,
                    appendData: false,
                    ...values,
                  })
                );
                setFilterModal(!addFilterModal);
              }}
            >
              {({
                handleSubmit,
                values,
                handleBlur,
                handleChange,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="row g-2">
                    <div className="col-12">
                      <div className="filter_select">
                        <Select
                          styles={stylesSelect}
                          components={{ DropdownIndicator, ClearIndicator }}
                          name="segment"
                          id="segment"
                          isClearable={true}
                          placeholder={"Segment"}
                          value={values.segment}
                          onBlur={() => {
                            handleBlur({ target: { name: "segment" } });
                          }}
                          onChange={(selectedOption) => {
                            let e = {
                              target: {
                                name: "segment",
                                value: selectedOption,
                              },
                            };
                            selectedOption !== undefined &&
                              selectedOption !== null &&
                              Object.keys(selectedOption).length > 0 &&
                              dispatch(
                                getSymbolList({
                                  id: selectedOption.value,
                                })
                              );
                            setFieldValue("symbol", "");
                            handleChange(e);
                          }}
                          options={segmentsList}
                        />
                        <span className="text-error">
                          <ErrorMessage name="segment" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="filter_select">
                        <Select
                          styles={stylesSelect}
                          components={{ DropdownIndicator, ClearIndicator }}
                          name="symbol"
                          placeholder="Symbol"
                          isClearable={true}
                          id="symbol"
                          value={values.symbol}
                          onBlur={() => {
                            handleBlur({ target: { name: "symbol" } });
                          }}
                          onChange={(selectedOption) => {
                            let e = {
                              target: {
                                name: "symbol",
                                value: selectedOption,
                              },
                            };
                            handleChange(e);
                          }}
                          options={symbolList}
                        />
                        <span className="text-error">
                          <ErrorMessage name="symbol" />
                        </span>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="filter_select">
                        <Select
                          styles={stylesSelect}
                          components={{ DropdownIndicator, ClearIndicator }}
                          name="buyOrSell"
                          id="buyOrSell"
                          placeholder={"Buy/Sell"}
                          value={values.buyOrSell}
                          onBlur={() => {
                            handleBlur({ target: { name: "buyOrSell" } });
                          }}
                          isClearable={true}
                          onChange={(selectedOption) => {
                            let e = {
                              target: {
                                name: "buyOrSell",
                                value: selectedOption,
                              },
                            };
                            handleChange(e);
                          }}
                          options={buySelloptions}
                        />
                        <span className="text-error">
                          <ErrorMessage name="buyOrSell" />
                        </span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="filter_select">
                        <DatePicker
                          className="form-control"
                          selected={values.startDate}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          onChange={(e) => {
                            setFieldValue("startDate", e);
                            dispatch(setFilterStartDate(e));
                          }}
                        />
                        <span className="text-error">
                          <ErrorMessage name="startDate" />
                        </span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="filter_select">
                        <DatePicker
                          className="form-control"
                          selected={values.endDate}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          onChange={(e) => {
                            setFieldValue("endDate", e);
                            dispatch(setFilterEndDate(e));
                          }}
                        />
                        <span className="text-error">
                          <ErrorMessage name="endDate" />
                        </span>
                      </div>
                    </div>
                    <div className="col-6">
                      <Button
                        className="w-100"
                        color="default"
                        disabled={tradeBookLoading}
                        type="button"
                        onClick={handleResetHandler}
                      >
                        Reset
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button
                        className="w-100"
                        color="default"
                        disabled={tradeBookLoading}
                        type="submit"
                      >
                        Find
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div
              className="close_btn"
              onClick={() => setFilterModal(!addFilterModal)}
            >
              <span className="material-symbols-outlined">close</span>
            </div>
          </div>
        </Modal>
      </main>
    </div>
  )
}
