import { useCallback, useState, useEffect, useMemo } from "react";
import { scriptWithoutDot, scriptWithDotAccept, scriptWithDot } from "configs/routesConfig";
import { useDispatch, useSelector } from "react-redux";
import { Input, Spinner } from "reactstrap";
import { showMessage } from "store/reducers/common.slice";
import { addOrder } from "store/reducers/marketWatch.slice";
import { getQuantityDetail } from "store/reducers/settingsSlice/setting.slice";
import { getSessionData, checkIsEmpty } from "utils/common"

const MobileBuySellModal = (props = {}) => {
  const dispatch = useDispatch()
  const userData = useMemo(() => {
    let localData = getSessionData();
    let id = localData?.userId || "";
    let name = localData?.userName || "";
    return { label: name, value: id }
  }, []);
  const { onClose } = props
  const { selectedScript = {}, isAddedOrderLoading } = useSelector(({ marketWatch }) => marketWatch);
  const { cepeSettingLoading } = useSelector(({ setting }) => setting);
  const {
    script,
    changeAmountColor,
    changeAmount,
    changePercent,
    buyPrice,
    sellPrice,
    ltTime,
    ltTime2,
    lastTradedPrice,
    offQuoteSecond,
    stopOutLevelAmount,
    stopOutLevelType,
    high,
    low,
    lot,
    qty,
    segmentId,
    symbol,
    close } = selectedScript
  const [buySellObject, setBuySellObject] = useState({
    orderType: "market",
    lot: lot.toString(),
    qty: qty.toString(),
    price: lastTradedPrice,
    positionLimit: 0,
    maxOrder: 0,
    minOrder: 0,
    buySellType: "BUY"
  })
  const client = userData
  const getQtySettingDetail = useCallback(
    async () => {
      const { payload: data } = await dispatch(getQuantityDetail({
        userRole: "User",
        segmentId: segmentId,
        Symbol: symbol,
        pageNo: 1,
        pageSize: 1,
        searchText: ""
      }))
      if (data?.data?.records?.[0]) {
        let buySellObjectObj = { ...buySellObject }
        const maxQtyDetail = data?.data?.records?.[0]
        if (maxQtyDetail.segmentId === 1 || maxQtyDetail.segmentId === 5) {
          if (maxQtyDetail.settingType === 1) {
            let qtyPositionLimit = maxQtyDetail.positionLimit
            let qtyMaxOrder = maxQtyDetail.maxOrder
            let qtyMinOrder = maxQtyDetail.minOrder
            let lotPositionLimit = (maxQtyDetail.positionLimit / maxQtyDetail.qty).toFixed(2)
            let lotMaxOrder = (maxQtyDetail.maxOrder / maxQtyDetail.qty).toFixed(2)
            let lotMinOrder = (maxQtyDetail.minOrder / maxQtyDetail.qty).toFixed(2)
            buySellObjectObj.positionLimit = `Q-${qtyPositionLimit} L-${lotPositionLimit}`
            buySellObjectObj.maxOrder = `Q-${qtyMaxOrder} L-${lotMaxOrder}`
            buySellObjectObj.minOrder = `Q-${qtyMinOrder} L-${lotMinOrder}`
          } else if (maxQtyDetail.settingType === 2) {
            let lotPositionLimit = maxQtyDetail.positionLimit
            let lotMaxOrder = maxQtyDetail.maxOrder
            let lotMinOrder = maxQtyDetail.minOrder
            let qtyPositionLimit = Math.trunc(maxQtyDetail.positionLimit * maxQtyDetail.qty)
            let qtyMaxOrder = Math.trunc(maxQtyDetail.maxOrder * maxQtyDetail.qty)
            let qtyMinOrder = Math.ceil(maxQtyDetail.minOrder * maxQtyDetail.qty)
            buySellObjectObj["positionLimit"] = `Q-${qtyPositionLimit} L-${lotPositionLimit}`
            buySellObjectObj["maxOrder"] = `Q-${qtyMaxOrder} L-${lotMaxOrder}`
            buySellObjectObj["minOrder"] = `Q-${qtyMinOrder} L-${lotMinOrder}`
          } else {
            buySellObjectObj.positionLimit = `V - ${maxQtyDetail.positionLimit}`
            buySellObjectObj.maxOrder = `V - ${maxQtyDetail.maxOrder}`
            buySellObjectObj.minOrder = `V - ${maxQtyDetail.minOrder}`
          }
        } else {
          buySellObjectObj.positionLimit = `L - ${maxQtyDetail.positionLimit}`
          buySellObjectObj.maxOrder = `L - ${maxQtyDetail.maxOrder}`
          buySellObjectObj.minOrder = `L - ${maxQtyDetail.minOrder}`
        }
        setBuySellObject(buySellObjectObj)
      }
    },
    [dispatch],
  )

  useEffect(() => {
    getQtySettingDetail()
  }, [dispatch])

  const onCloseModal = useCallback(() => {
    if (onClose) onClose()
  }, [onClose])
  const onConfirmSubmit = useCallback(async () => {
    const text = `Are you sure? \n Lot: ${buySellObject.lot}, \n Qty: ${buySellObject.qty}`;
    if (window.confirm(text) === true) {
      const data = {
        client,
        buySell: buySellObject.buySellType,
        lot: buySellObject.lot,
        qty: buySellObject.qty,
        price: buySellObject.price,
        market: buySellObject.orderType === "market" ? { value: 5 } : buySellObject.buySellType === "BUY" ? { value: 1 } : { value: 3 },
      };
      const result = await dispatch(
        addOrder({ data, liveDataItem: selectedScript, isAppView: true })
      );
      if (result?.payload?.status === 200) onCloseModal();
    }
  }, [
    dispatch,
    onCloseModal,
    buySellObject,
    client,
    selectedScript,
  ]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      if (
        checkIsEmpty(client) ||
        !Number(buySellObject.price || 0) ||
        !Number(buySellObject.lot || 0) ||
        !Number(buySellObject.qty || 0)
      )
        return;
      let isSubmit = true;
      let message = "";
      const orderTypeVal = buySellObject.orderType === "market" ? 5 : 1;
      let fPrice = parseFloat(buySellObject.price);
      if (orderTypeVal !== 5) {
        let priceMinLimit = 0;
        let priceMaxLimit = 0;
        if (segmentId !== 3) {
          priceMinLimit = fPrice - (fPrice * 10) / 100;
          priceMaxLimit = fPrice + (fPrice * 10) / 100;
        } else {
          priceMinLimit = fPrice - (fPrice * 150) / 100;
          priceMaxLimit = fPrice + (fPrice * 150) / 100;
          if (priceMinLimit < 0) priceMinLimit = 0;
        }
        if (
          segmentId === 1 ||
          segmentId === 3 ||
          (segmentId === 2 && scriptWithDot.includes(symbol))
        ) {
          let decimals = fPrice.toString().split(".");
          decimals[1] = decimals[1]
            ? decimals[1].length === 1
              ? decimals[1] + "0"
              : decimals[1]
            : "00";
          if (!scriptWithDotAccept.includes(decimals[1]) && (segmentId === 1 && fPrice>=250)) {
            message = "Ticksize must be : 0.05";
            isSubmit = false;
          }
        }
        if (priceMinLimit > lastTradedPrice || priceMaxLimit < lastTradedPrice) {
          message = "Limit oder must be placed within circuit levels";
          isSubmit = false;
        }
        /*   if (isSubmit && Number(ltTime) !== 0 && offQuoteSecond !== 0) {
            if (segmentId !== 5) {
              let lastTime = new Date();
              let ltTimeTime = `${ltTime.slice(0, 2)}:${ltTime.slice(2, 4)}:${ltTime.slice(4, 6)}`
              ltTimeTime = ltTimeTime.split(':');
              lastTime.setHours(ltTimeTime[0], ltTimeTime[1], ltTimeTime[2], 0)
              let diffTime = parseInt((new Date() - lastTime) / 1000)
              if (offQuoteSecond < diffTime) {
                message = "Off Quote";
                isSubmit = false;
              }
            } else {
              let lastTime = new Date();
              let date = new Date(ltTime * 1000)
              let ltTimeTime = `${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`
              ltTimeTime = ltTimeTime.split(':');
              lastTime.setHours(ltTimeTime[0], ltTimeTime[1], ltTimeTime[2], 0)
              let diffTime = parseInt((new Date() - lastTime) / 1000)
              if (offQuoteSecond < diffTime) {
                message = "Off Quote";
                isSubmit = false;
              }
            }
          } */
        if (isSubmit) {
          let minPriceLimit, maxPriceLimit = 0
          if (stopOutLevelType === 1) {
            minPriceLimit = Number(buyPrice) - stopOutLevelAmount
            maxPriceLimit = Number(sellPrice) + stopOutLevelAmount
          } else {
            minPriceLimit = Number(buyPrice) - (stopOutLevelAmount === 0 ? stopOutLevelAmount : ((Number(buyPrice) * stopOutLevelAmount) / 100))
            maxPriceLimit = Number(sellPrice) + (stopOutLevelAmount === 0 ? stopOutLevelAmount : ((Number(sellPrice) * stopOutLevelAmount) / 100))
          }
          if (fPrice > minPriceLimit && fPrice < maxPriceLimit) {
            message = "Stop Out";
            isSubmit = false;
          }
        }
      }
      if (isSubmit && Number(ltTime) !== 0 && offQuoteSecond !== 0) {
        if (segmentId === 5) {
          let lastTime = new Date();
          let ltTimeTime = ltTime2.split(':');
          lastTime.setHours(ltTimeTime[0], ltTimeTime[1], ltTimeTime[2], 0)
          let diffTime = parseInt((new Date() - lastTime) / 1000)
          if (offQuoteSecond < diffTime) {
            message = "Off Quote";
            isSubmit = false;
          }
        } else {
          let lastTime = new Date();
          let ltTimeTime = ltTime2.split(':');
          lastTime.setHours(ltTimeTime[0], ltTimeTime[1], ltTimeTime[2], 0)
          let diffTime = parseInt((new Date() - lastTime) / 1000)
          if (offQuoteSecond < diffTime) {
            message = "Off Quote";
            isSubmit = false;
          }
        }
      }
      if (isSubmit && Number(buyPrice) >= Number(sellPrice)) {
        message = "Wrong Quote";
        isSubmit = false;
      }
      if (message) dispatch(showMessage({ message }));
      if (isSubmit) onConfirmSubmit();
    },
    [
      dispatch,
      buyPrice,
      segmentId,
      ltTime,
      ltTime2,
      stopOutLevelType,
      symbol,
      buySellObject,
      lastTradedPrice,
      offQuoteSecond,
      sellPrice,
      stopOutLevelAmount,
      client,
      onConfirmSubmit
    ]
  )
  const renderError = useCallback(() => {
    return <span className="text-error">*Required field</span>;
  }, []);
  const isAllowBuySell = useMemo(() => {
    let isAllowBuySellStatus = false
    let minPriceLimit, maxPriceLimit = 0
    if (buySellObject.orderType === "limitStop") {
      const fPrice = Number(buySellObject.price)
      if (stopOutLevelType === 1) {
        minPriceLimit = (Number(buyPrice)) - stopOutLevelAmount
        maxPriceLimit = (Number(sellPrice)) + stopOutLevelAmount
      } else {
        minPriceLimit = (Number(buyPrice)) - (stopOutLevelAmount === 0 ? stopOutLevelAmount : ((Number(buyPrice) * stopOutLevelAmount) / 100))
        maxPriceLimit = (Number(sellPrice)) + (stopOutLevelAmount === 0 ? stopOutLevelAmount : ((Number(sellPrice) * stopOutLevelAmount) / 100))
      }
      if ((fPrice > minPriceLimit && fPrice < maxPriceLimit) || (fPrice >= Number(buyPrice) && fPrice <= Number(sellPrice))) {
        isAllowBuySellStatus = true;
      }
    }
    return isAllowBuySellStatus
  }, [buySellObject.price, buyPrice, sellPrice, buySellObject.orderType, stopOutLevelAmount, stopOutLevelType])
  const buySellPopUp = useMemo(() => {
    return (
      <>
        {(cepeSettingLoading || isAddedOrderLoading) && <div className="spinner-box">
          <Spinner className="ml-2" color="primary" />
        </div>}
        <form onSubmit={handleSubmit}>
          <div className="byusell_popup_header">
            <div className="byusell_popup_title d-flex align-items-center justify-content-between">
              <h4 className="text-white m-0">{script}</h4>
              <span className="material-symbols-outlined text-white" onClick={onCloseModal}>close</span>
            </div>
            <div className="byusell_popup_radio_wrap d-flex align-items-center justify-content-between">
              <h4 className="m-0 text-white">{lastTradedPrice}</h4>
              <div className="radio-buttons_wrap orange_radio_wrap">
                <ul>
                  <li>
                    <input type="radio" id="market" name="orderType" value="market" checked={buySellObject.orderType === "market"} onClick={(e) => setBuySellObject({ ...buySellObject, orderType: e.target.value })} />
                    <label htmlFor="market">MARKET</label>
                  </li>
                  <li className="p-0">
                    <input type="radio" id="limitStop" name="orderType" value="limitStop" checked={buySellObject.orderType === "limitStop"} onClick={(e) => setBuySellObject({ ...buySellObject, orderType: e.target.value })} />
                    <label htmlFor="limitStop">LIMIT/STOP</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="byusell_popup_content">
            <ul className="flex-wrap">
              <li>
                <p className="text_grey text_mediam font-weight-500 m-0">Positon: {buySellObject.positionLimit}</p>
              </li>
              <li>
                <p className="text_grey text_mediam font-weight-500 m-0 text-right">Max: {buySellObject.maxOrder}</p>
              </li>
              <li className="w-100">
                <p className="text_grey text_mediam font-weight-500 m-0">Min: {buySellObject.minOrder}</p>
              </li>
            </ul>
            <ul className="flex-wrap g-2">
              <li className="w-50">
                <label htmlFor="lot" className="text_mediam">Lot</label>
                <input
                  type="number"
                  name="lot"
                  className="btn_grey_border w-100"
                  min={(segmentId === 2 || segmentId === 3 || segmentId === 4) ? 1 : false}
                  value={buySellObject.lot}
                  onKeyPress={(event) => {
                    if (
                      event.key === "-" ||
                      event.key === "+" ||
                      event.key === "*" ||
                      event.key === "/"
                    ) {
                      event.preventDefault();
                    } else if (
                      event.key === "." &&
                      (segmentId === 2 || segmentId === 3 || segmentId === 4)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onBlur={() => {
                    if (segmentId !== 2 || segmentId !== 3 || segmentId !== 4) {
                      setBuySellObject({
                        ...buySellObject,
                        qty: Math.round(
                          Number(buySellObject?.lot.slice(0, (buySellObject?.lot.indexOf(".") !== -1 ? (buySellObject?.lot.indexOf(".") + 3) : buySellObject?.lot.length))) * qty
                        ),
                        lot: buySellObject?.lot.slice(0, (buySellObject?.lot.indexOf(".") !== -1 ? (buySellObject?.lot.indexOf(".") + 3) : buySellObject?.lot.length)),
                      })
                    }
                  }}
                  onChange={(e) => {
                    if (Number(e.target.value) >= 0 && (e.target.value?.length <= 10 || e.target.value?.length <= buySellObject.length)) {
                      const newQty = Math.round(
                        Number(e.target.value) * qty
                      );
                      setBuySellObject({
                        ...buySellObject,
                        qty: newQty,
                        lot: e.target.value,
                      })
                    }
                  }}
                />
                {!Number(buySellObject.lot || 0) && renderError()}
              </li>
              <li className="w-50">
                <label htmlFor="qty" className="text_mediam">Qty</label>
                <input
                  type="number"
                  name="qty"
                  min={1}
                  className="btn_grey_border w-100"
                  value={buySellObject.qty}
                  disabled={segmentId === 2 || segmentId === 3 || segmentId === 4}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) e.preventDefault();
                  }}
                  onChange={(e) => {
                    if (Number(e.target.value) >= 0 && e.target.value?.length <= 15) {
                      if (segmentId === 2 || segmentId === 3 || segmentId === 4) {
                        setBuySellObject({
                          ...buySellObject,
                          lot: e.target.value,
                        })
                      } else {
                        const newLot = (Number(e.target.value) / qty).toString();
                        setBuySellObject({
                          ...buySellObject,
                          qty: e.target.value,
                          lot: newLot.slice(0, (newLot.indexOf(".") !== -1 ? (newLot.indexOf(".") + 3) : newLot.length)),
                        })
                      }
                    }
                  }}
                />
                {!Number(buySellObject.qty || 0) && renderError()}
              </li>
              {buySellObject.orderType === "limitStop" && <li className="w-100">
                <label htmlFor="price" className="text_mediam">Price</label>
                <Input
                  type="number"
                  placeholder="Price"
                  onKeyDown={(event) => {
                    if (
                      scriptWithoutDot.includes(symbol) &&
                      (event.key === "." ||
                        event.key === "-" ||
                        event.key === "+" ||
                        event.key === "*")
                    )
                      event.preventDefault();
                  }}
                  value={buySellObject.price}
                  onChange={(e) => {
                    if (Number(e.target.value) >= 0) {
                      setBuySellObject({
                        ...buySellObject,
                        price: e.target.value,
                      })
                    }
                  }}
                />
                {!Number(buySellObject.price || 0) && renderError()}
              </li>}
            </ul>
            <ul>
              <li className="w-50">
                <span className={`text_mediam fw_bold ${changeAmountColor}`}>
                  {changeAmount.toFixed(2)} ({changePercent.toFixed(2)})
                </span>
              </li>
              <li className="w-50">
                <p className="m-0 text_grey text_mediam text-right">
                  C:<span className="text_blue fw_bold pl-1">{close}</span>
                </p>
              </li>
            </ul>
            <ul className="mb-2">
              <li className="w-50">
                <p className="m-0 text_grey text_mediam">
                  High:<span className="text_blue fw_bold pl-1">{high}</span>
                </p>
              </li>
              <li className="w-50">
                <p className="m-0 text_grey text_mediam text-right">
                  Low:<span className="text_blue fw_bold pl-1">{low}</span>
                </p>
              </li>
            </ul>
            <ul>
              <li className="mx-1">
                <button type="submit" className="btn_orange w-100" disabled={isAllowBuySell} onClick={() => setBuySellObject({ ...buySellObject, buySellType: "SELL" })}>
                  Sell Now @<span className="d-block">{buyPrice}</span>
                </button>
              </li>
              <li className="mx-1">
                <button type="submit" className="btn_blue w-100" disabled={isAllowBuySell} onClick={() => setBuySellObject({ ...buySellObject, buySellType: "BUY" })}>
                  Buy Now @<span className="d-block">{sellPrice}</span>
                </button>
              </li>
            </ul>
          </div>
        </form>
      </>
    )
  }, [
    buySellObject,
    changeAmount,
    changePercent,
    lastTradedPrice,
    script,
    close,
    high,
    low,
    sellPrice,
    buyPrice,
    segmentId,
    renderError,
    handleSubmit,
    symbol,
    qty,
    changeAmountColor,
    cepeSettingLoading,
    isAddedOrderLoading,
    isAllowBuySell,
    onCloseModal
  ])
  return <>{buySellPopUp}</>
}

export default MobileBuySellModal