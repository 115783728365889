import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showMessage } from "./common.slice";
import { getSessionData } from "utils/common";
import { exportToExcel, generatePDF } from 'utils/reportGenerator';

const initialState = {
  maxQuantityDetailsList: [],
  maxQuantityDetailsLoading: false,
  maxQuantityDetailsSearchText: "",
  totalRecordMaxQuantityDetails: 0,
  startPageMaxQuantityDetails: 1
};

export const getMaxQuantityDetailsList = createAsyncThunk(
  "detailsetting/maxQuantity-details",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let { searchText, pageNo, pageSize, appendData, segmentId } = data;
      userId = data?.userId ? data?.userId : userId
      axios
        .get(`settings/quantity-setting/get-quantity-settings-details/${userId}/${pageNo}/${pageSize}${segmentId ? `?SegmentId=${segmentId}` : ''}${searchText ? `?searchText=${searchText}` : ""
          }`)
        .then(({ data }) => {
          if (data.status === 200) {
            resolve({ data: data.data, appendData });
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const exportMaxQuantityDetailsList = createAsyncThunk(
  "detailsetting/export-max-quantity-details",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let { searchText, segmentId, exportType } = data;
      userId = data?.userId ? data?.userId : userId
      axios
        .get(`settings/quantity-setting/get-quantity-settings-details/${userId}/${1}/${100000}${segmentId ? `?SegmentId=${segmentId}` : ''}${searchText ? `?searchText=${searchText}` : ""
          }`)
        .then(({ data }) => {
          if (data.status === 200) {
            if (data?.data?.records.length > 0) {
              const allMaxQtyDetail = data.data.records.map(item => {
                return {
                  userName: item.userName,
                  authorityName: item.authorityName,
                  segment: item.segment.toUpperCase(),
                  symbol: item.symbol === null ? item.segment ? `${item.segment.toUpperCase()} SEG` : '-' : item.symbol,
                  rateStart: item.rateStart,
                  rateEnd: item.rateEnd,
                  positionLimit: item.positionLimit,
                  settingTypeName: item.settingTypeName,
                  maxOrder: item.maxOrder,
                  minOrder: item.minOrder
                }
              })
              if (exportType === 'excel') {
                exportToExcel({
                  apiData: allMaxQtyDetail,
                  fileName: "All_Max_Quantity_Details",
                });
              }
              else {
                let aligh = { halign: "right", fontStyle: "bold" };
                let bgBlue = { fillColor: [168, 181, 255], textColor: [0, 0, 0] };
                let footerStyle = { ...bgBlue, ...aligh, fontStyle: "bold" };
                let textBlue = { textColor: [62, 62, 180], ...aligh, fontStyle: "bold" };
                let textRed = { textColor: [245, 54, 92], ...aligh, fontStyle: "bold" };
                let footerBlue = { fillColor: [242, 243, 165], ...textBlue, fontStyle: "bold" };
                let footerRed = { fillColor: [242, 243, 165], ...textRed, fontStyle: "bold" };
                const columnData = [
                  {
                    title: "Client",
                    dataKey: "userName",
                  },
                  {
                    title: "Authority",
                    dataKey: "authorityName",
                    styles: aligh,
                  },
                  {
                    title: "Market",
                    dataKey: "segment",
                    styles: aligh,
                  },
                  {
                    title: "Script",
                    dataKey: "symbol",
                    styles: aligh,
                  },
                  {
                    title: "Rate Start",
                    dataKey: "rateStart",
                    styles: aligh,
                  },
                  {
                    title: "Rate End",
                    dataKey: "rateEnd",
                    styles: aligh,
                  },
                  {
                    title: "Position Limit",
                    dataKey: "positionLimit",
                    styles: aligh,
                  },
                  {
                    title: "Type",
                    dataKey: "settingTypeName",
                    styles: aligh,
                  },
                  {
                    title: "Max Order",
                    dataKey: "maxOrder",
                    styles: aligh,
                  },
                  {
                    title: "Min Order",
                    dataKey: "minOrder",
                    styles: aligh,
                  },
                ];
                let exportData = {
                  reportType: "3110",
                  FileName: "Max Quantity Details Report",
                  title: "Max Quantity Details",
                  arrayName: allMaxQtyDetail,
                  exportColumn: columnData,
                  rowPageBreak: true,
                  theme: "grid",
                  headStyles: bgBlue,
                  footerStyle: footerStyle,
                  footerColorBlue: footerBlue,
                  footerColorRed: footerRed,
                  showHead: "showHead",
                };
                generatePDF(exportData)
              }
            }
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const maxQuantityDetailsSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setMaxQuantityDetailsList: (state, action) => {
      state.maxQuantityDetailsList = action.payload;
    },
    setMaxQuantityDetailsSearchText: (state, action) => {
      state.maxQuantityDetailsSearchText = action.payload;
    },
    setStartPageMaxQuantityDetails: (state, action) => {
      state.startPageMaxQuantityDetails = action.payload;
    },
  },
  extraReducers: {
    //Ledger Report
    [getMaxQuantityDetailsList.pending]: (state, action) => {
      state.maxQuantityDetailsLoading = true;
    },
    [getMaxQuantityDetailsList.rejected]: (state, action) => {
      state.maxQuantityDetailsList = [];
      state.maxQuantityDetailsLoading = false;
    },
    [getMaxQuantityDetailsList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.maxQuantityDetailsList = [
          ...state.maxQuantityDetailsList,
          ...action.payload.data.records,
        ];
      } else {
        state.maxQuantityDetailsList = action.payload.data.records;
      }
      state.totalRecordMaxQuantityDetails = action.payload.data.totalRecords;
      state.maxQuantityDetailsLoading = false;
    }
  },
});

export const { setMaxQuantityDetailsList, setMaxQuantityDetailsSearchText, setStartPageMaxQuantityDetails } = maxQuantityDetailsSlice.actions;

export default maxQuantityDetailsSlice.reducer;
