import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {showMessage} from "../common.slice";
import {getSessionData} from "utils/common";
import axios from "axios";
const initialState = {
	settingSymbolMaxValueList: [],
	settingSymbolMaxValueLoading: false,
	isSettingSymbolMaxValueUpdated: false,
	startApiCallsettingSymbolMaxValueList: true,
	totalRecordSettingSymbolMaxValue: 0,
	currentPageSettingSymbolMaxValue: 1,
	pageSizeSettingSymbolMaxValue: 10,
	searchTextSettingSymbolMaxValue: "",
	selectedAccountInSettingSymbolMaxValue: "",
	startPageSettingSymbolMaxValue: 1,
	settingSymbolMaxValueDeleteId: "",
	accountSettingType: 1,
	isSettingSymbolMaxValueGet: false,
	symbolsOfMaxValueSymbolList: [],
};

export const getSymbolMaxValueList = createAsyncThunk(
	"setting/symbolmaxvalue",
	(data, {dispatch}) => {
		return new Promise((resolve, reject) => {
			let {searchText, pageNo, pageSize, appendData, userId} = data;
			axios
				.get(
					`settings/symbol-max-value/get-symbol-max-values-by-user/${userId}/${pageNo}/${pageSize}${
						searchText ? `?searchText=${searchText}` : ""
					}`
				)
				.then(({data}) => {
					let {status, data: dataArray} = data;
					if (status == 200 && dataArray && dataArray.records.length > 0) {
						resolve({data: dataArray, appendData, searchText});
					} else {
						reject(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
);

export const addSettingSymbolMaxValue = createAsyncThunk(
	"/setting/symbol-maxvalue-setting/add",
	(paramData, {dispatch}) => {
		return new Promise((resolve, reject) => {
			let {userId} = getSessionData();
			let newFinalObject = {};
			newFinalObject.maxValue = paramData.maxValue;
			newFinalObject.selectedUser = paramData.selectedUser;
			newFinalObject.id = paramData.id;
			newFinalObject.userType = paramData.userType;
			newFinalObject.accountSettingType = paramData.accountSettingType;
			newFinalObject.createdBy = userId;
			newFinalObject.userId = paramData.userId.value;
			newFinalObject.symbol = paramData.symbol.value;
			newFinalObject.segmentId = paramData.segmentId.value;
			axios
				.post(`settings/symbol-max-value/save-symbol-max-value`, newFinalObject)
				.then(({data}) => {
					if (data.status == 200) {
						dispatch(showMessage({message: data.message, varient: "success"}));
						resolve(data);
					} else {
						dispatch(showMessage({message: data.message}));
						reject(data);
					}
				})
				.catch((error) => {
					if (error.response) {
						dispatch(showMessage({message: error.response.data.message}));
					} else if (error.request) {
						dispatch(showMessage({message: error.request.message}));
					} else {
						dispatch(showMessage({message: error.message}));
					}
					reject(error);
				});
		});
	}
);

export const deleteSettingSymbolMaxValue = createAsyncThunk(
	"/setting/symbol delete setting",
	(data, {dispatch}) => {
		return new Promise((resolve, reject) => {
			let {id} = data;
			axios
				.delete(`settings/symbol-max-value/delete-symbol-max-value/${id}`)
				.then(({data}) => {
					if (data.status == 200) {
						dispatch(showMessage({message: data.message, varient: "success"}));
						resolve(data);
					} else {
						dispatch(showMessage({message: data.message}));
						reject(data);
					}
				})
				.catch((error) => {
					if (error.response) {
						dispatch(showMessage({message: error.response.data.message}));
					} else if (error.request) {
						dispatch(showMessage({message: error.request.message}));
					} else {
						dispatch(showMessage({message: error.message}));
					}
					reject(error);
				});
		});
	}
);

export const getSymbolMaxValueSymbolList = createAsyncThunk(
	"/symbollist/get",
	({id}, {dispatch}) => {
		return new Promise((resolve, reject) => {
			axios
				.get(
					`settings/symbol-max-value/get-symbol-max-value-symbols-by-segment/${id}`
				)
				.then(({data}) => {
					let {status, data: dataArray} = data;
					if (status == 200 && dataArray && dataArray.length > 0) {
						resolve(dataArray);
					} else {
						reject(data);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
);

export const settingSymbolMaxValue = createSlice({
	name: "setting",
	initialState,
	reducers: {
		setSettingSymbolMaxValueDeleteId: (state, action) => {
			state.settingSymbolMaxValueDeleteId = action.payload;
		},
		setSelectedAccountInSettingSymbolMaxValueAddEditForm: (state, action) => {
			state.selectedAccountInSettingSymbolMaxValue = action.payload;
		},
		setAccountSettingSymbolMaxValueType: (state, action) => {
			state.accountSettingType = action.payload;
		},
		setIsSettingSymbolMaxValueUpdated: (state, action) => {
			state.isSettingSymbolMaxValueUpdated = action.payload;
		},
		setStartPageSettingSymbolMaxValue: (state, action) => {
			state.currentPageSettingSymbolMaxValue = action.payload;
		},
		setSearchTextSettingSymbolMaxValue: (state, action) => {
			state.searchTextSettingSymbolMaxValue = action.payload;
		},
		setSettingSymbolMaxValueList: (state, action) => {
			state.settingSymbolMaxValueList = action.payload;
		},
		setTotalRecordSettingSymbolMaxValue: (state, action) => {
			state.totalRecordSettingSymbolMaxValue = action.payload;
		},
		setSymbolsMaxValueSymbolList: (state, action) => {
			state.symbolsOfMaxValueSymbolList = action.payload;
		},
		setIsSettingSymbolMaxValueGet: (state, action) => {
			state.isSettingSymbolMaxValueGet = action.payload;
		},
	},
	extraReducers: {
		//Quantity setting
		[getSymbolMaxValueList.pending]: (state, action) => {
			state.settingSymbolMaxValueLoading = true;
			state.startApiCallsettingSymbolMaxValueList = false;
		},
		[getSymbolMaxValueList.rejected]: (state, action) => {
			state.settingSymbolMaxValueList = [];
			state.settingSymbolMaxValueLoading = false;
			state.totalRecordSettingSymbolMaxValue = 0;
			state.startApiCallsettingSymbolMaxValueList = false;
		},
		[getSymbolMaxValueList.fulfilled]: (state, action) => {
			if (action.payload.appendData) {
				state.settingSymbolMaxValueList = [
					...state.settingSymbolMaxValueList,
					...action.payload.data.records,
				];
			} else {
				state.settingSymbolMaxValueList = action.payload.data.records;
			}
			state.settingSymbolMaxValueLoading = false;
			if (action.payload.searchText) {
				state.totalRecordSettingSymbolMaxValue =
					action.payload.data.records.length;
			} else {
				state.totalRecordSettingSymbolMaxValue =
					action.payload.data.totalRecords;
			}
			state.startApiCallsettingSymbolMaxValueList = false;
		},
		[addSettingSymbolMaxValue.pending]: (state, action) => {
			state.isSettingSymbolMaxValueUpdated = false;
		},
		[addSettingSymbolMaxValue.rejected]: (state, action) => {
			state.isSettingSymbolMaxValueUpdated = false;
		},
		[addSettingSymbolMaxValue.fulfilled]: (state, action) => {
			state.isSettingSymbolMaxValueUpdated = true;
		},
		[deleteSettingSymbolMaxValue.pending]: (state, action) => {
			state.isSettingSymbolMaxValueUpdated = false;
		},
		[deleteSettingSymbolMaxValue.rejected]: (state, action) => {
			state.isSettingSymbolMaxValueUpdated = false;
		},
		[deleteSettingSymbolMaxValue.fulfilled]: (state, action) => {
			state.isSettingSymbolMaxValueUpdated = true;
		},

		[getSymbolMaxValueSymbolList.pending]: (state, action) => {
			state.symbolsOfMaxValueSymbolList = [];
		},
		[getSymbolMaxValueSymbolList.rejected]: (state, action) => {
			state.symbolsOfMaxValueSymbolList = [];
		},
		[getSymbolMaxValueSymbolList.fulfilled]: (state, action) => {
			state.symbolsOfMaxValueSymbolList = action.payload;
		},
	},
});

export const {
	setSettingSymbolMaxValueDeleteId,
	setSelectedAccountInSettingSymbolMaxValueAddEditForm,
	setAccountSettingSymbolMaxValueType,
	setIsSettingSymbolMaxValueUpdated,
	setIsSettingSymbolMaxValueGet,
	setStartPageSettingSymbolMaxValue,
	setSearchTextSettingSymbolMaxValue,
	setSettingSymbolMaxValueList,
	setTotalRecordSettingSymbolMaxValue,
	setSymbolsMaxValueSymbolList,
} = settingSymbolMaxValue.actions;

export default settingSymbolMaxValue.reducer;
