import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showMessage } from "store/reducers/common.slice";
import { getSessionData } from "utils/common";
import axios from "axios";

const initialState = {
  selectedSegmentQtyMaxSetting: "",
  scriptSettingList: [],
  scriptSettingLoading: false,
  deleteScritMaxSettingData: {},
  scriptMaxQtySettingList: [],
  currentScriptMaxQtySettings: 1,
  totalRecordsScriptMaxQtySettings: 0,
  searchTextScriptMaxQtySettings: "",
  isScriptMaxQtySettingsUpdated: false
};

const scriptSettingData = [
    {
      col_1: "1",
      col_2: "Dummy data",
      col_3: "Dummy data",
      col_4: "ANY NUMBER",
      col_5: "Dummy data",
    },
    {
      col_1: "2",
      col_2: "Dummy data",
      col_3: "Dummy data",
      col_4: "DATE (YYYY-MM-DD)",
      col_5: "Dummy data",
    },
  ]

export const addEditSettingScriptMaxQty = createAsyncThunk(
  "/setting/setting-script-max-qty/add",
  (paramData, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.id = paramData.id;
      newFinalObject.isRange = paramData.isRange;
      newFinalObject.createdBy = userId;
      newFinalObject.symbol = paramData.symbol.value;
      newFinalObject.segmentId = paramData.segmentId.value;
      newFinalObject.rateStart = Number(paramData.rateStart);
      newFinalObject.rateEnd = Number(paramData.rateEnd);
      newFinalObject.maxOrder = Number(paramData.maxOrder);
      if (!paramData.isRange) {
        newFinalObject.rateStart = 0;
        newFinalObject.rateEnd = 0;
      }
      newFinalObject.settingType = Number(paramData.settingType);
      axios
        .post(
          `settings/script-max-quantity-setting/save-script-max-quantity-setting`,
          newFinalObject
        )
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const deleteSettingScriptMaxQty = createAsyncThunk(
  "/setting/setting-script-max-qty/delete",
  (paramData, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `settings/script-max-quantity-setting/delete-script-max-quantity-setting/${paramData.id}`
        )
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getScriptMaxQtySettingList = createAsyncThunk(
  "admin/setting/script setting",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { searchText, pageNo, pageSize, appendData, segmentId } = data;
      axios
        .get(
          `settings/script-max-quantity-setting/get-script-max-quantity-setting/${segmentId}/${pageNo}/${pageSize}${
            searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          if (data.status == 200 && data && data.data.records.length > 0) {
            resolve({ data: data.data.records, appendData, searchText, totalRecords: data.data.totalRecords });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getScriptSettingList = createAsyncThunk(
  "admin/setting/script setting",
  () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(scriptSettingData);
      }, 1000);
    });
  }
);

export const scriptSettingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setSortSettingScript: (state, action) => {
      state.sortSettingExpiryArr = action.payload;
    },
    setDeleteScritMaxSettingData: (state, action) => {
      state.deleteScritMaxSettingData = action.payload;
    },
    setCurrentScriptMaxQtySettings: (state, action) => {
      state.currentScriptMaxQtySettings = action.payload;
    },
    setIsScriptMaxQtySettingsUpdated: (state, action) => {
      state.isScriptMaxQtySettingsUpdated = action.payload;
    },
    setSelectedSegmentQtyMaxSetting: (state, action) => {
      state.selectedSegmentQtyMaxSetting = action.payload;
    },
    setSearchTextScriptMaxQtySettings: (state, action) => {
      state.searchTextScriptMaxQtySettings = action.payload;
    },
    setScriptMaxQtySettingList: (state, action) => {
      state.scriptMaxQtySettingList = action.payload;
    }
  },
  extraReducers: {
    //quantity setting
    [getScriptSettingList.pending]: (state, action) => {
      state.scriptSettingList = [];
      state.scriptSettingLoading = true;
    },
    [getScriptSettingList.rejected]: (state, action) => {
      state.scriptSettingList = [];
      state.scriptSettingLoading = false;
    },
    [getScriptSettingList.fulfilled]: (state, action) => {
      state.scriptSettingList = action.payload;
      state.scriptSettingLoading = false;
    },

    [getScriptMaxQtySettingList.pending]: (state, action) => {
      state.isScriptMaxQtySettingsLoading = true;
    },
    [getScriptMaxQtySettingList.rejected]: (state, action) => {
      state.scriptMaxQtySettingList = [];
      state.isScriptMaxQtySettingsLoading = false;
    },
    [getScriptMaxQtySettingList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.scriptMaxQtySettingList = [
          ...state.scriptMaxQtySettingList,
          ...action.payload.data,
        ];
      } else {
        state.scriptMaxQtySettingList = action.payload.data;
      }
      state.isScriptMaxQtySettingsLoading = false;
      state.totalRecordsScriptMaxQtySettings = action.payload.totalRecords
    },
    
    [addEditSettingScriptMaxQty.pending]: (state, action) => {
      state.isScriptMaxQtySettingsLoading = true;
      state.isScriptMaxQtySettingsUpdated = false;
    },
    [addEditSettingScriptMaxQty.rejected]: (state, action) => {
      state.isScriptMaxQtySettingsLoading = false;
      state.isScriptMaxQtySettingsUpdated = false;
    },
    [addEditSettingScriptMaxQty.fulfilled]: (state, action) => {
      state.isScriptMaxQtySettingsLoading = false;
      state.isScriptMaxQtySettingsUpdated = true;
    },
    
    [deleteSettingScriptMaxQty.pending]: (state, action) => {
      state.isScriptMaxQtySettingsLoading = true;
      state.isScriptMaxQtySettingsUpdated = false;
    },
    [deleteSettingScriptMaxQty.rejected]: (state, action) => {
      state.isScriptMaxQtySettingsLoading = false;
      state.isScriptMaxQtySettingsUpdated = false;
    },
    [deleteSettingScriptMaxQty.fulfilled]: (state, action) => {
      state.isScriptMaxQtySettingsLoading = false;
      state.isScriptMaxQtySettingsUpdated = true;
    },
  } 
});

export const { 
    setSortSettingScript,
    setDeleteScritMaxSettingData,
    setCurrentScriptMaxQtySettings,
    setIsScriptMaxQtySettingsUpdated,
    setSelectedSegmentQtyMaxSetting,
    setScriptMaxQtySettingList,
    setSearchTextScriptMaxQtySettings } = scriptSettingSlice.actions;

export default scriptSettingSlice.reducer;
