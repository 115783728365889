import React, { useMemo } from 'react'

export default function MarketWatchIndexShow({ expandMore, scriptIndexList }) {
  const niftyScript = useMemo(() => {
    if (scriptIndexList?.size > 0) {
      return [...scriptIndexList.values()][1];
    }
    return {};
  }, [scriptIndexList]);
  const bankNiftyScript = useMemo(() => {
    if (scriptIndexList?.size > 0) {
      return [...scriptIndexList.values()][0];
    }
    return {};
  }, [scriptIndexList]);

  const renderIndex = useMemo(() => {
    return (
      <div
        className={
          expandMore ? "toggle_btn_wrap show mb-2" : "toggle_btn_wrap"
        }
      >
        <div className="nifty_wrapper mnifty_wrapper">
          <div className="nifty_box">
            <h5>
              NIFTY <span>
                {niftyScript?.lastTradedPrice
                  ? niftyScript.lastTradedPrice
                  : 0}
              </span>
            </h5>
            <p>
              {niftyScript?.changeAmountColor ===
                "text-success" ? (
                <i
                  className={`material-icons-outlined small ${niftyScript?.changeAmountColor} align-bottom mx-1`}
                >
                  arrow_drop_up
                </i>
              ) : (
                <i
                  className={`material-icons-outlined small ${niftyScript?.changeAmountColor} align-bottom mx-1`}
                >
                  arrow_drop_down
                </i>
              )}
              {niftyScript?.changeAmount
                ? niftyScript?.changeAmount
                : 0}
              (
              {niftyScript?.changePercent
                ? niftyScript?.changePercent
                : "0.00%"}
              )
            </p>
          </div>
          <div className="nifty_box">
            <h5>
              NIFTY<span>
                {bankNiftyScript?.lastTradedPrice
                  ? bankNiftyScript.lastTradedPrice
                  : 0}
              </span>
            </h5>
            <p>
              {bankNiftyScript?.changeAmountColor ===
                "text-success" ? (
                <i
                  className={`material-icons-outlined small ${bankNiftyScript?.changeAmountColor} align-bottom mx-1`}
                >
                  arrow_drop_up
                </i>
              ) : (
                <i
                  className={`material-icons-outlined small ${bankNiftyScript?.changeAmountColor} align-bottom mx-1`}
                >
                  arrow_drop_down
                </i>
              )}
              {bankNiftyScript?.changeAmount
                ? bankNiftyScript?.changeAmount
                : 0}
              (
              {bankNiftyScript?.changePercent
                ? bankNiftyScript?.changePercent
                : "0.00%"}
              )
            </p>
          </div>
        </div>
      </div>
    )
  }, [expandMore, bankNiftyScript, niftyScript])

  return <>{renderIndex}</>
}
