import React, { useMemo } from 'react'

export default function MobileNewMarketWatchTable({ currentData, isDeleteActive, onClickScript, checkBoxHandler }) {
  const renderTable = useMemo(() => {
    return currentData?.map((item, index) => {
      return (
        <WatchListItemContainer
          {...item}
          index={index}
          row={item}
          onClickScript={onClickScript}
          isDeleteActive={isDeleteActive}
          checkBoxHandler={checkBoxHandler}
        />
      )
    })
  }
    , [currentData, onClickScript, isDeleteActive, checkBoxHandler])
  return <>{renderTable}</>;
}

const WatchListItemContainer = ({
  id,
  row,
  isCheck,
  index,
  checkBoxHandler,
  lastTradedPrice,
  low,
  high,
  onClickScript,
  symbol,
  script2,
  netQty,
  changeAmount,
  changeAmountColor,
  changePercent,
  colorBid,
  buyPrice,
  colorAsk,
  sellPrice,
  ltTime,
  isDeleteActive
}) => {
  const scriptTd = useMemo(() =>
    <div className='col-5'>
      <p className="text_mediam text_blue fw_bold pb-2 marketview_symbol_wrap">
        <span className='marketview_symbol'>
          {symbol}
        </span>
        <span className="text_small fw_bold marketview_symbol_date">{script2}</span>
      </p>
    </div>
    , [symbol, script2]);

  const IndeterminateCheckbox = React.forwardRef(
    ({ id, indeterminate, value, row, checkBoxHandler, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);
      return (
        <>
          <div className="custom-control custom-checkbox">
            <input
              {...rest}
              className="custom-control-input"
              type="checkbox"
              id={`checbox_${id}`}
              ref={resolvedRef}
              checked={value}
              onClick={() => checkBoxHandler(row)}
              readOnly
            />
            <label
              className="custom-control-label"
              htmlFor={`checbox_${id}`}
            ></label>
          </div>
        </>
      );
    }
  );
  const checkBoxTd = useMemo(() => {
    return (
      <td className="text-center">
        <IndeterminateCheckbox
          id={id}
          value={isCheck}
          row={row}
          checkBoxHandler={checkBoxHandler}
        />
      </td>
    );
  }, [checkBoxHandler, id]);

  const mtmTd = useMemo(() =>
    <div className='col-3'>
      <p className="text_small fw_500 pb-2">
        LTP:
        <span className={lastTradedPrice <= 0 ? "text-danger text_small_number pl-1 fw_bold" : "text_green text_small_number pl-1 fw_bold"}>
          {lastTradedPrice}
        </span>
      </p>
    </div>, [lastTradedPrice]);

  const qtyTd = useMemo(() =>
    <div className='col-4 text-right'>
      <p className="text_blue text_small pb-2">
        Qty:
        <span className="fw_bold pl-1">{netQty}</span>
      </p>
    </div>, [netQty]);

  const changeAmountTd = useMemo(() =>
    <div className='col-5'>
      <p className={(changeAmountColor && changeAmountColor === 'text-danger')
        ? "text_small text-danger d-flex align-items-center pb-2" : "text_small text-success d-flex align-items-center pb-2"}>
        <span className="material-symbols-outlined text_large arrow_icon">
          arrow_downward
        </span>
        {changeAmount ? changeAmount.toFixed(2) : 0} ({changePercent ? changePercent.toFixed(2) : '0.00'}%)
      </p>
    </div>, [changeAmount, changePercent, changeAmountColor])

  const sellTd = useMemo(() =>
    <div className='col-3'>
      <p className="text_mediam text_grey pb-2">
        S:<span className="text_mediam fw_bold pl-1" style={{
          color: colorBid && colorBid,
        }}>{buyPrice ? buyPrice : '0.00'}</span>
      </p></div>, [buyPrice, colorBid])

  const buyTd = useMemo(() =>
    <div className='col-4 text-right'>
      <p className="text_mediam pb-2">
        B:<span className="fw_bold pl-1 text_mediam" style={{
          color: colorAsk && colorAsk,
        }}>{sellPrice ? sellPrice : '0.00'}</span>
      </p>
    </div>, [sellPrice, colorAsk])

  const timeTd = useMemo(() =>
    <div className='col-5'>
      <p className="text_grey text_small pb-2">{ltTime ? ltTime : "00:00:00"}</p>
    </div>
    , [ltTime])

  const lowTd = useMemo(() =>
    <div className='col-3'>
      <p className="text_grey text_small pb-2">
        Low:<span className="pl-1 text_blue">{low ? low : '0.00'}</span>
      </p></div>, [low])

  const highTd = useMemo(() =>
    <div className='col-4 text-right'>
      <p className='text_small pb-2'>
        <span className="text_grey text_small">
          High:
          <span className="pl-1 text_blue">{high ? high : '0.00'}</span>
        </span>
      </p></div>, [high])

  const renderRow = useMemo(() => {
    return (
      <div
        key={`row_${index}`}
        className={(lastTradedPrice !== 0 && low !== 0 && high !== 0) ? (low >= lastTradedPrice) ? "marketview_row light_red" : (high <= lastTradedPrice) ? "marketview_row light_green" : "marketview_row" : "marketview_row"
        }
      >
        {isDeleteActive && netQty === 0 && <div className='marketview_check'>
          {checkBoxTd}
        </div>}
        <div className='marketview_row_wrap' onClick={() => onClickScript(index)}>
          <div className='row'>
            {scriptTd}
            {mtmTd}
            {qtyTd}
            {changeAmountTd}
            {sellTd}
            {buyTd}
            {timeTd}
            {lowTd}
            {highTd}
          </div>
        </div>
      </div>
    )
  }, [
    checkBoxTd,
    isDeleteActive,
    onClickScript,
    index,
    scriptTd,
    mtmTd,
    qtyTd,
    changeAmountTd,
    sellTd,
    buyTd,
    timeTd,
    lowTd,
    highTd
  ])
  return <> {renderRow} </>;
}
